import { Params, Themes } from '@/lib/types/shared/page.types';
import { Typography } from '@mui/material';
import React from 'react';

type Props = {
    addTitle?: boolean;
    title?: string;
    type?: 'normal' | 'small';
    theme?: Themes;
    params: Params;
    children?: React.ReactNode;
    className?: string;
}

const DottedCard = ({ addTitle = true, title, type = 'normal', theme = 'dark', params, children, className = '' }: Props) => {
    return (
        <div className={`h-full flex flex-col justify-center items-center gap-4 rounded-lg ${type == 'small' ? 'px-3 py-5' : 'px-5 py-10'} border ${theme === 'light' ? 'border-white' : 'border-primary-gray-950'} border-dashed ${className}`}>
            {addTitle && (
                <Typography variant={type === 'small' ? 'h6' : 'h4'} className={`${theme === 'light' ? 'text-white' : 'text-primary-gray-950'} text-center font-bold font-anaheim capitalize`}>
                    {title}
                </Typography>
            )}

            {children && children}
        </div>
    );
}

export default DottedCard;