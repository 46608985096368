import React from 'react';

import { useTranslations } from 'next-intl';

import { route } from '@/lib/navigationFunctions';
import { DefaultPageProps } from '@/lib/types/shared/page.types';

import { Container, Typography } from '@mui/material';

import { Image, ButtonNew } from '@/components/shared';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';

type Props = {
    id?: string,
} & DefaultPageProps;

export default function AboutUsSection({ id = '', params }: Props) {
    const t_shared = useTranslations('shared');

    return (
        <section id={id} className="py-16 bg-white scroll-mt-28">
            <Container className="grid grid-cols-1 md:grid-cols-5 gap-4">
                <div className="md:col-span-2">
                    {/* Developer Awards */}
                    <Image
                        src="The_Developer_Awards_ynjoyc"
                        alt="The Developer Awards"
                        width={91}
                        height={91}
                    />

                    <MoveFadeInAnimation direction='right'>
                        <Typography variant="h3" className="text-primary-gray-950 font-bold capitalize">
                            {t_shared('about_us_section.developer_awards.title')}
                        </Typography>
                    </MoveFadeInAnimation>

                    <Typography variant="body1" className="text-primary-gray-950">
                        {t_shared('about_us_section.developer_awards.text')}
                    </Typography>

                    <ButtonNew text={t_shared('about_us_section.developer_awards.button')} link={route('/contact', params.locale)} />


                    {/* ISO Certified */}
                    <MoveFadeInAnimation direction='right' delay={100}>
                        <Typography variant="h3" className="text-primary-gray-950 font-bold capitalize mt-12">
                            {t_shared('about_us_section.iso_certified.title')}
                        </Typography>
                    </MoveFadeInAnimation>

                    <Typography variant="body1" className="text-primary-gray-950 text-xl max-w-lg">
                        {t_shared('about_us_section.iso_certified.text')}
                    </Typography>
                    
                    <div className="flex items-center gap-4 flex-wrap mt-4">
                        <Image
                            src="23.136-Keurmerk-ISO-27001_2_vkytyk"
                            alt="ISO 27001"
                            width={135}
                            height={191}
                            className="w-14 h-auto"
                        />

                        <Image
                            src="23.136-Keurmerk-ISO-9001_2_zddb1w"
                            alt="ISO 9001"
                            width={135}
                            height={191}
                            className="w-14 h-auto"
                        />
                    </div>
                </div>

                <div className="md:col-span-3">
                    {/* The Grand Office */}
                    <Image
                        src="The_Grand_Office_yqlwk1"
                        alt="The Grand Office"
                        width={709}
                        height={336}
                        className="w-full h-auto aspect-16/7 object-cover rounded-lg"
                    />
                    
                    {/* Price Indication */}
                    {/* <MoveFadeInAnimation direction='right' delay={200}>
                        <Typography variant="h3" className="text-primary-gray-950 font-bold capitalize mt-12">
                            {t('about_us.price_indication.title')}
                        </Typography>
                    </MoveFadeInAnimation>

                    <Typography variant="body1" className="text-primary-gray-950 text-xl max-w-lg">
                        {t('about_us.price_indication.text')}
                    </Typography>

                    <PriceIndicationForm placeholder={t('about_us.price_indication.placeholder')} button={t('about_us.price_indication.button')} params={params} /> */}
                </div>
            </Container>
        </section>
    );
}
