'use client';

import { ComponentProps, Themes } from '@/lib/types/shared/page.types';
import { Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';

type Props = {
    text?: any,
    link?: string,
    onclick?: () => void,
    rel?: string,
    id?: string,
    type?: string,
    action?: string,
    target?: string,
    disabled?: boolean,
    noMargin?: boolean,
    children?: React.ReactNode,
    theme?: Themes
} & ComponentProps;

export default function ButtonNew({ text, link, onclick, rel, id, type, action, target, disabled, noMargin = false, className = '', children, theme = 'light' }: Props) {
    return (
        <>
            {type == 'button' && disabled ? (
                <button id={id} type={action == 'submit' ? action : undefined} onClick={ onclick ? () => onclick() : () => {/**/}} rel={rel} className={`button-new disabled select-none flex items-center group w-fit ${noMargin ? '' : 'mt-4'} ${className}`} disabled>
                    <div className="size-20 relative animate-scale-down">
                        <div className="size-20 border-2 border-primary-yellow-400 rounded-full group-hover:scale-5 transition-all duration-300" />

                        <div className="size-2 bg-primary-yellow-400 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-300 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </div>

                    {children || (
                        <Typography variant="body1" className={`cursor-default text-primary-gray-950 group-hover:text-primary-yellow-400 transition-all duration-300 ${theme === 'dark' ? 'bg-primary-gray-950 text-white' : 'bg-white text-primary-gray-950'} py-3 -ml-6 relative z-10`}>
                            {text}
                        </Typography>
                    )}
                </button>
            ) : type == 'button' && !disabled ? (
                <button id={id} type={action == 'submit' ? action : undefined} onClick={ onclick ? () => onclick() : () => {/**/}} rel={rel} className={`button-new flex items-center group w-fit ${noMargin ? '' : 'mt-4'} ${className}`}>
                    <div className="size-20 relative animate-scale-down">
                        <div className="size-20 border-2 border-primary-yellow-400 rounded-full group-hover:scale-5 transition-all duration-300" />

                        <div className="size-2 bg-primary-yellow-400 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-300 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </div>

                    {children || (
                        <Typography variant="body1" className={`cursor-pointer text-primary-gray-950 group-hover:text-primary-yellow-400 transition-all duration-300 ${theme === 'dark' ? 'bg-primary-gray-950 text-white' : 'bg-white text-primary-gray-950'} py-3 -ml-6 relative z-10`}>
                            {text}
                        </Typography>
                    )}
                </button>
            ) : (
                <Link id={id} href={link ?? ''} rel={rel} target={target ? target : ''} type={action == 'submit' ? action : ''} className={`button-new flex items-center group w-fit ${noMargin ? '' : 'mt-4'} ${className}`}>
                    <div className="size-20 relative animate-scale-down">
                        <div className="size-20 border-2 border-primary-yellow-400 rounded-full group-hover:scale-5 transition-all duration-300" />

                        <div className="size-2 bg-primary-yellow-400 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-300 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                    </div>

                    {children || (
                        <Typography variant="body1" className={`cursor-pointer group-hover:text-primary-yellow-400 transition-all duration-300 ${theme === 'dark' ? 'bg-primary-gray-950 text-white' : 'bg-white text-primary-gray-950'} py-3 -ml-6 relative z-10`}>
                            {text}
                        </Typography>
                    )}
                </Link>
            )}
        </>
    );
}
