import { Params } from '@/lib/types/shared/page.types';
import React from 'react';
import Image from '@/components/shared/Image';
import { Typography } from '@mui/material';

type Props = {
    title: string;
    image: string;
    alt: string;
    className?: string;
    params: Params;
}

const ImageHero = ({ title, image, alt, className = '', params }: Props) => {
    return (
        <section className={`w-full min-h-screen-4/5 relative flex flex-col justify-end items-center ${className}`}>
            <Image
                src={image}
                alt={alt}
                width={1920}
                height={750}
                className="absolute w-full h-full object-cover -z-1"
            />

            <Typography
                variant="h3"
                component="h2"
                className="text-center font-alumniSans capitalize font-semibold mx-4 -mb-3"
            >
                {title}
            </Typography>
        </section>
    );
}

export default ImageHero;