import { Locales, route } from "@/lib/navigationFunctions";

import { getTranslations } from "next-intl/server";
import { ArticlePage, ArticlePath } from "@/lib/types/shared/page.types";

export const articlePaths: ArticlePath[] = [
    {
        id: 'trends',
        name: 'trends',
        link: {
            en: '/articles/trends',
            nl: '/artikelen/trends'
        },
    },
    {
        id: 'responsive-design',
        name: 'responsive_design',
        link: {
            en: '/articles/responsive-design',
            nl: '/artikelen/responsive-design'
        },
    },
    {
        id: 'seo',
        name: 'seo',
        link: {
            en: '/articles/seo',
            nl: '/artikelen/seo'
        },
    },
    {
        id: 'artificial-intelligence',
        name: 'artificial_intelligence',
        link: {
            en: '/articles/artificial-intelligence',
            nl: '/artikelen/artificial-intelligence'
        },
    },
    {
        id: 'web-security',
        name: 'security',
        link: {
            en: '/articles/web-security',
            nl: '/artikelen/web-security'
        },
    },
    {
        id: 'user-experience',
        name: 'user_experience',
        link: {
            en: '/articles/user-experience',
            nl: '/artikelen/user-experience'
        },
    },
    {
        id: 'use-of-cms',
        name: 'use_of_cms',
        link: {
            en: '/articles/use-of-cms',
            nl: '/artikelen/use-of-cms'
        },
    },
    {
        id: 'seo-for-small-businesses',
        name: 'seo_for_small_businesses',
        link: {
            en: '/articles/seo-for-small-businesses',
            nl: '/artikelen/seo-voor-kleine-bedrijven'
        },
    },
];

const getArticleConstants = async (locale: Locales | 'nl') => {
    let t = (variable: string, options?: any) => {
        return variable;
    }

    if (locale) {
        t = await getTranslations({ locale: locale });
    }

    const articlePages: ArticlePage[] = [
        {
            id: 'trends',
            title: t('pages.articles.trends.hero.title', {
                year: new Date().getFullYear()
            }),
            description: t("stay_ahead_of_the_industry_with_insights_on_the_latest_trends_in_web_development_brought_to_you_by_the_experts_at_webbeukers"),
            keywords: `${t('web_development_trends')}, ${t('technological_innovations')}, ${t('future_of_webdesign')}, ${t('webbeukers_insights')}, ${t('industry_trends')}`,
            pageLayout: [
                {
                    type: "component-section",
                    subType: "main-hero",
                    title: t('pages.articles.trends.hero.title', {
                        year: new Date().getFullYear()
                    }),
                    text: t('pages.articles.trends.hero.text'),
                    certificatesText: t('pages.articles.hero.certificates'),
                    // headerImage: "",
                    // beukerImage: "",
                    disableBeuker: true,
                    buttons: [
						{
							text: t("pages.articles.hero.buttons.0"),
							link: route("/contact", locale),
						},
						{
							text: t("pages.articles.hero.buttons.1"),
							link: route("/contact", locale),
						}
					]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("progressive_web_apps"),
                        text: t("optimizing_websites_starts_with_the_user_experience_analyze_user_interactions_to_identify_and_address_bottlenecks_this_can_range_from_improving_load_times_to_redesigning_navigation_elements_for_a_more_intuitive_user_experience"),
                        children: {
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("ai_en_machine_learning"),
                                        text: t("ai_and_ml_are_expected_to_play_an_increasingly_important_role_in_the_world_of_web_development_these_technologies_can_help_personalize_user_experiences_and_automate_routine_tasks_more_efficiently_in_addition_ai_and_ml_can_help_analyze_large_amounts_of_data_to_make_better_decisions_about_website_design_and_functionality"),
                                    },
                                    {
                                        title: t("voice_user_interface"),
                                        text: t("voice_user_interface_vui_with_the_rise_of_voiceactivated_assistants_such_as_alexa_and_google_assistant_more_websites_will_integrate_vui_to_provide_a_handsfree_navigation_experience_this_integration_not_only_improves_accessibility_for_users_with_different_needs_but_also_allows_a_broader_audience_to_interact_with_websites_in_a_more_natural_and_intuitive_way"),
                                    },
                                    {
                                        title: t("rd_and_mobilefirst_approach"),
                                        text: t("responsive_design_and_mobilefirst_approach_the_focus_on_mobile_devices_remains_essential_with_design_that_adapts_to_different_screen_sizes_and_platforms_this_approach_ensures_that_websites_not_only_work_well_on_desktop_computers_but_also_function_optimally_on_smartphones_and_tablets_moreover_it_improves_the_user_experience_on_mobile_devices"),
                                    },
                                    {
                                        title: t("apifirst_development"),
                                        text: t("apifirst_development_this_emphasizes_the_importance_of_apis_in_web_application_development_where_systems_can_easily_communicate_with_each_other_this_approach_designs_applications_with_the_api_as_the_primary_interface_ensuring_better_integration_and_interoperability_between_different_services_and_platforms"),
                                    },
                                    {
                                        title: t("motion_ui_and_micro_integrations"),
                                        text: t("motion_ui_and_microinteractions_the_use_of_subtle_animations_and_interactions_to_enhance_the_user_experience_is_becoming_increasingly_popular_and_essential_in_modern_web_design_these_techniques_contribute_to_more_intuitive_and_engaging_navigation_making_websites_more_dynamic_and_attractive_to_the_user_while_improving_engagement_and_interaction"),
                                    },
                                    {
                                        title: t("cybersecurity"),
                                        text: t("cybersecurity_as_technology_evolves_the_security_of_websites_and_web_applications_becomes_increasingly_important_this_requires_continuous_adaptation_and_strengthening_of_security_protocols_to_protect_against_new_and_advanced_cyber_threats_in_addition_there_is_a_growing_emphasis_on_training_developers_and_end_users_in_cybersecurity_awareness"),
                                    },
                                    {
                                        title: t("serverless_architectures"),
                                        text: t("serverless_architectures_these_provide_a_scalable_and_costeffective_way_to_host_and_manage_web_applications_allowing_developers_to_focus_on_application_code_rather_than_server_management_using_cloudbased_infrastructure_simplifies_maintenance_and_scaling_and_users_only_pay_for_the_resources_actually_used"),
                                    },
                                    {
                                        title: t("augmented_reality"),
                                        text: t("augmented_reality_ar_and_virtual_reality_vr_these_technologies_are_likely_to_become_more_integrated_into_web_experiences_especially_in_industries_such_as_ecommerce_and_education_where_they_provide_a_more_immersive_and_interactive_user_experience_in_ecommerce_for_example_ar_can_be_used_to_let_customers_virtually_try_or_visualize_products_in_their_own_environment"),
                                    },
                                    {
                                        title: t("sustainability_in_web_development"),
                                        text: t("sustainability_in_web_development_there_will_be_an_increasing_focus_on_developing_ecofriendly_websites_that_consume_less_energy_this_includes_optimizing_website_code_and_assets_to_minimize_load_times_and_data_transfers_leading_to_a_reduction_in_data_center_carbon_emissions"),
                                    },
                                ],
                                gap: "gap-4",
                                className: "lg:grid-cols-3"
                            }
                        }
                    }
                },
            ]
        },
        {
            id: 'responsive-design',
            title: t('responsive_design'),
            description: t("discover_how_webbeukers_ensures_optimal_display_of_your_website_on_any_device_through_responsive_design"),
            keywords: `${t('responsive_design')}, ${t('mobilefriendly')}, ${t('webbeukers')}, ${t('webdevelopment')}`,
            pageLayout: [
                {
                    type: "component-section",
                    subType: "main-hero",
                    title: t('pages.articles.responsive_design.hero.title'),
                    text: t('pages.articles.responsive_design.hero.text'),
                    certificatesText: t('pages.articles.hero.certificates'),
                    disableBeuker: true,
                    buttons: [
						{
							text: t("pages.articles.hero.buttons.0"),
							link: route("/contact", locale),
						},
						{
							text: t("pages.articles.hero.buttons.1"),
							link: route("/contact", locale),
						}
					]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        children: {
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("user_experience"),
                                        text: t("a_responsive_website_automatically_adapts_to_the_screen_size_of_the_device_whether_it_is_a_smartphone_tablet_or_desktop_this_ensures_a_seamless_and_consistent_experience_for_all_users_leading_to_a_longer_stay_on_site_and_a_lower_bounce_rate"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("seo"),
                                        text: t("search_engines_like_google_prefer_mobilefriendly_websites_a_responsive_design_can_improve_a_websites_ranking_in_search_results_leading_to_higher_visibility_and_more_organic_traffic"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("cost_savings_and_maintenance"),
                                        text: t("with_responsive_design_only_one_version_of_the_website_is_needed_which_works_on_all_devices_this_reduces_the_time_and_costs_of_maintaining_multiple_versions_of_the_website"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("futureproofing"),
                                        text: t("responsive_websites_are_better_prepared_for_the_introduction_of_new_devices_and_screen_sizes_they_are_flexible_and_can_easily_adapt_to_changing_technological_trends"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("improved_conversions"),
                                        text: t("a_userfriendly_mobile_experience_can_increase_the_likelihood_of_conversions_users_are_more_likely_to_take_action_on_a_website_that_functions_well_on_their_device"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                ],
                                gap: "gap-4",
                                className: "lg:grid-cols-3"
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ProcessSection",
                    componentProps: {
                        addReviews: true,
                        reviewsOrder: "after"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("common_problems_with_responsive_design"),
                        ul: [
                            {
                                title: t("horizontal_scrolling"),
                                text: t("one_of_the_most_frustrating_experiences_for_users_is_the_need_to_scroll_horizontally_to_view_all_the_content_this_often_happens_when_a_website_is_not_properly_scaled_to_the_device_screen_size_it_forces_users_to_make_extra_efforts_to_get_the_information_they_need_leading_to_frustration_and_often_an_early_exit_from_the_site"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("unreadable_text_and_unusable_buttons"),
                                text: t("when_a_site_does_not_respond_well_text_and_buttons_can_be_displayed_so_small_that_they_are_difficult_to_read_or_use_on_a_mobile_device_this_requires_users_to_zoom_in_and_pan_to_read_or_take_action_which_significantly_degrades_the_user_experience"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("overlapping_elements"),
                                text: t("without_careful_attention_to_responsive_design_some_elements_on_the_page_can_overlap_on_smaller_screens_this_can_hide_essential_information_or_hinder_the_functionality_of_the_website_reducing_usability"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("long_loading_times"),
                                text: t("websites_that_are_not_optimized_for_mobile_use_can_load_significantly_slower_on_mobile_devices_especially_if_they_contain_images_or_elements_that_are_not_optimized_for_this_context_longer_loading_times_can_lead_to_user_frustration_and_a_higher_bounce_rate"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("nonoptimized_images"),
                                text: t("images_that_are_not_correctly_optimized_for_responsive_sites_can_cause_issues_such_as_disrupting_the_layout_or_causing_unnecessary_horizontal_scrolling_this_can_also_affect_the_page_load_speed_especially_on_mobile_networks"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("why_responsive_design_is_important"),
                        ul: [
                            {
                                title: t("user_experience_ux"),
                                text: t("at_the_heart_of_responsive_design_is_ensuring_an_optimal_user_experience_on_every_device_this_means_text_remains_readable_without_zooming_navigation_elements_have_enough_space_to_be_touchfriendly_and_content_is_effectively_organized_a_responsive_website_anticipates_the_users_needs_and_adjusts_accordingly_resulting_in_intuitive_interaction"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("technical_implementation"),
                                text: t("the_technique_behind_responsive_design_is_based_on_css_media_queries_flexible_grid_layouts_and_scalable_images_media_queries_allow_the_website_to_detect_the_devices_features_such_as_screen_size_and_adjust_the_layout_accordingly_flexible_grids_ensure_layout_elements_scale_proportionally_while_scalable_images_and_media_maintain_visual_content_without_negatively_impacting_load_times"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("seo_and_mobile_friendliness"),
                                text: t("google_and_other_search_engines_have_marked_mobile_friendliness_as_a_significant_ranking_factor_responsive_websites_are_more_likely_to_rank_higher_in_search_results_because_they_use_one_url_and_the_same_html_code_making_it_easier_for_search_engines_to_crawl_and_index_content"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("futureproof_design"),
                                text: t("with_an_eye_on_the_future_responsive_design_ensures_that_websites_remain_compatible_with_new_devices_and_screen_sizes_this_flexibility_makes_it_easier_to_adapt_to_the_everchanging_technological_landscape_without_complete_redesigns"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            }
                        ],
                        theme: "dark",
                        className: "bg-white"
                    },
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("conclusion"),
                        text: t("responsive_design_is_central_to_creating_accessible_usable_and_futureproof_web_experiences_as_the_digital_world_continues_to_evolve_the_emphasis_on_responsive_design_will_only_increase_making_it_an_indispensable_element_in_every_web_developer_and_designers_toolkit_by_embracing_the_principles_of_responsive_design_brands_and_organizations_can_ensure_their_online_presence_is_robust_flexible_and_ready_to_meet_the_demands_of_the_modern_user"),
                        children: [
                            {
                                type: "component",
                                componentKey: "Image",
                                componentProps: {
                                    src: "Articles/responsive_muv7sr",
                                    alt: t("responsive_alt_text-1"),
                                    width: 500,
                                    height: 350,
                                    className: "object-contain"
                                }
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSectionNew",
                    componentProps: {
                        title: null,
                        text: null,
                    }
                }
            ]
        },
        {
            id: 'seo',
            title: t('seo'),
            description: t('strengthen_your_online_presence_with_webbeukers_seo_strategies_discover_how_our_services_can_support_your_marketing_transformation'),
            keywords: `${t('seo')}, ${t('search_engine_optimization')}, ${t('webbeukers')}, ${t('marketing_transformation')}, ${t('online_visibility')}`,
            pageLayout: [
                {
                    type: "component-section",
                    subType: "main-hero",
                    title: t('pages.articles.seo.hero.title'),
                    text: t('pages.articles.seo.hero.text'),
                    certificatesText: t('pages.articles.hero.certificates'),
                    disableBeuker: true,
                    buttons: [
                        {
                            text: t("pages.articles.hero.buttons.0"),
                            link: route("/contact", locale),
                        },
                        {
                            text: t("pages.articles.hero.buttons.1"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("maximize_your_online_visibility_with_our_seo_services"),
                        text: t("at_web_beukers_we_understand_the_problem_that_many_companies_experience_their_beautiful_website_does_not_get_the_attention_it_deserves_invisibility_in_search_engines_leads_to_missed_opportunities_and_loss_of_potential_customers_which_is_frustrating_after_investing_in_a_website"),
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("why_is_seo_important"),
                        text: t("seo_or_search_engine_optimization_is_crucial_for_any_online_business_as_it_helps_improve_visibility_on_search_engines_like_google_by_optimizing_your_website_for_relevant_search_terms_you_increase_the_chances_of_potential_customers_finding_you_leading_to_more_organic_traffic_and_potential_sales"),
                        ul: [
                            {
                                title: t("increased_reliability"),
                                text: t("high_search_engine_rankings_are_associated_with_reliability_and_credibility"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("sustainable_results"),
                                text: t("seo_delivers_lasting_results_and_continues_to_generate_traffic"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("investment_in_future"),
                                text: t("investing_in_seo_is_investing_in_future_growth_and_success"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("costeffective"),
                                text: t("seo_is_more_costeffective_than_paid_advertising_in_the_long_run"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        image: "Articles/seo_tyxyso",
                        imagePosition: "right",
                        alt: t("an_overview_of_seo_tools_and_strategies_for_improving_a_websites_online_visibility"),
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full",
                        theme: "dark"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ProcessSection",
                    componentProps: {
                        addReviews: true,
                        reviewsOrder: "before"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("outsource_seo_let_us_help_you"),
                        text: t("outsourcing_seo_can_be_a_strategic_move_to_improve_your_online_presence_without_the_stress_of_managing_everything_yourself_at_web_beukers_we_understand_the_importance_of_search_engine_optimization_and_how_it_can_grow_your_business_heres_how_we_can_help_you"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("indepth_keyword_research"),
                                        text: t("we_not_only_identify_relevant_keywords_but_also_analyze_search_behavior_and_trends_to_strengthen_your_seo_strategy_with_popular_tools_such_as_google_analytics__search_console"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("page_seo_optimization"),
                                        text: t("optimizing_the_content_and_structure_of_the_current_website_including_titles_meta_tags_and_the_use_of_keywords_in_the_texts_and_headers"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("landing_page_creation"),
                                        text: t("developing_highquality_relevant_and_informative_content_that_matches_the_users_search_intent"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("local_seo_optimization"),
                                        text: t("optimizing_the_website_for_local_search_results_which_is_especially_important_for_businesses_that_serve_a_local_audience"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("monthly_seo_optimization"),
                                        text: t("essential_for_keeping_track_of_search_engine_trends_and_continuously_improving_your_online_visibility_and_userfriendliness"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                ]
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSectionNew",
                    componentProps: {
                        title: null,
                        text: null,
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("enjoy_full_service_everything_under_1_roof"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("website_speed_optimization"),
                                        text: t("boost_your_online_performance_with_our_professional_website_speed_optimization_increase_loading_speed_and_improve_user_experience_for_maximum_impact"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("conversion_management"),
                                        text: t("optimize_your_conversions_with_our_effective_conversion_management_we_help_you_maximize_your_returns_and_improve_your_online_conversion_rates"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                ]
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("free_seo_tips"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("linkbuilding"),
                                        text: t("obtaining_quality_backlinks_from_other_websites_which_helps_to_increase_the_authority_and_credibility_of_the_website"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("blogging"),
                                        text: t("blogging_is_an_effective_way_to_share_expertise_and_build_an_audience_it_contributes_to_seo_by_using_relevant_keywords_and_increases_the_online_visibility_of_a_website"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                ],
                                text: t("we_understand_that_building_and_maintaining_an_effective_blog_or_obtaining_external_links_can_be_timeconsuming_especially_if_you_want_to_focus_on_other_aspects_of_your_business_it_is_also_possible_to_have_these_services_provided_by_web_beukers_contact_us_for_more_information"),
                                textColor: "text-primary-gray-950",
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    },
                },
            ]
        },
        {
            id: 'artificial-intelligence',
            title: t('artificial_intelligence_in_web_design'),
            description: t("welcome_to_our_detailed_and_informative_exploration_of_how_artificial_intelligence_transforms_web_design_practices_and_innovations"),
            keywords: `${t('artificial_intelligence')}, ${t('AI')}, ${new Date().getFullYear()}, ${t('webdesign')}, ${t('web_development')}, ${t('beuken')} , ${t('websites')}`,
            pageLayout: [
                {
                    type: "component-section",
                    subType: "main-hero",
                    title: t('pages.articles.artificial_intelligence.hero.title'),
                    text: t('pages.articles.artificial_intelligence.hero.text'),
                    certificatesText: t('pages.articles.hero.certificates'),
                    disableBeuker: true,
                    buttons: [
                        {
                            text: t("pages.articles.hero.buttons.0"),
                            link: route("/contact", locale),
                        },
                        {
                            text: t("pages.articles.hero.buttons.1"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "ProcessSection",
                    componentProps: {
                        addReviews: true,
                        reviewsOrder: "after"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        children: {
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("historical_context"),
                                        text: t("ai_has_come_a_long_way_in_web_design_in_the_early_years_ai_was_mainly_used_for_basic_data_analysis_and_simple_tasks_however_with_the_advancement_of_technology_ai_has_developed_into_a_powerful_tool_that_supports_complex_design_processes_creates_innovative_user_experiences_and_even_proposes_new_design_concepts"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("current_applications_of_ai_in_web_design"),
                                        text: t("ai_is_transforming_web_design_through_automated_layouts_personalized_user_experiences_and_intelligent_content_creation_tools_like_adobe_sensei_leverage_ai_to_optimize_images_and_create_dynamic_designs_while_platforms_such_as_wix_and_squarespace_use_ai_to_assist_users_in_building_websites_through_simple_questionnaires_ai_chatbots_provide_realtime_user_assistance_enhancing_engagement_and_customer_service"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("future_trends_and_predictions"),
                                        text: t("the_future_of_ai_in_web_design_is_set_to_focus_on_hyperpersonalization_where_ai_algorithms_will_tailor_content_and_design_elements_to_individual_user_preferences_and_behaviors_voiceenabled_interfaces_and_aidriven_uxui_design_tools_will_become_more_prevalent_facilitating_more_intuitive_interactions_generative_ai_might_enable_designers_to_create_more_innovative_and_adaptive_designs_pushing_the_boundaries_of_creativity_and_functionality"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("challenges_and_considerations"),
                                        text: t("integrating_ai_into_web_design_poses_challenges_such_as_ensuring_data_privacy_and_security_maintaining_ethical_design_practices_and_addressing_the_potential_loss_of_jobs_due_to_automation_theres_also_the_risk_of_homogenization_in_design_where_aidriven_websites_might_lack_uniqueness_balancing_ai_capabilities_with_human_creativity_and_oversight_is_crucial_to_navigate_these_challenges_effectively"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("conclusion"),
                                        text: t("artificial_intelligence_is_reshaping_the_web_design_landscape_by_offering_innovative_solutions_to_traditional_design_challenges_enhancing_user_experience_and_streamlining_the_design_process_as_ai_technology_advances_its_imperative_for_designers_to_adapt_and_embrace_these_changes_while_also_considering_the_ethical_implications_and_maintaining_a_human_touch_in_their_designs"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    
                                ],
                                gap: "gap-4",
                                className: "lg:grid-cols-3"
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSectionNew",
                    componentProps: {
                        title: null,
                        text: null,
                    }
                }
            ],
        },
        {
            id: 'web-security',
            title: t('website_security'),
            description: t("understand_the_fundamentals_of_digital_security_with_webbeukers_and_implement_strong_security_practices_to_protect_your_data"),
            keywords: `${t('digital_security')}, ${t('cybersecurity')}, ${t('security_measures')}, ${t('data_protection')} ${t('webbeukers')}`,
            pageLayout: [
                {
                    type: "component-section",
                    subType: "main-hero",
                    title: t('pages.articles.security.hero.title'),
                    text: t('pages.articles.security.hero.text'),
                    certificatesText: t('pages.articles.hero.certificates'),
                    disableBeuker: true,
                    buttons: [
                        {
                            text: t("pages.articles.hero.buttons.0"),
                            link: route("/contact", locale),
                        },
                        {
                            text: t("pages.articles.hero.buttons.1"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                    ]
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("current_cyber_threats"),
                        text: t("in_year_websites_face_a_range_of_advanced_cyber_threats_that_require_constant_vigilance_some_of_the_most_prominent_threats_include", {
                            year: new Date().getFullYear()
                        }),
                        ul: [
                            {
                                title: t("phishing"),
                                text: t("this_form_of_cyber_attack_uses_deceptive_emails_and_messages_to_trick_users_into_sharing_personal_information_these_attacks_are_becoming_increasingly_sophisticated_with_attackers_often_impersonating_legitimate_organizations"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("ransomware"),
                                text: t("this_type_of_malware_encrypts_a_victims_data_and_demands_a_ransom_for_decryption_ransomware_attacks_can_have_crippling_consequences_for_both_individuals_and_organizations"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("ddos_distributed_denial_of_service_attacks"),
                                text: t("these_attacks_overwhelm_websites_with_traffic_from_multiple_sources_making_them_inaccessible_ddos_attacks_can_target_critical_online_services_leading_to_significant_disruptions"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("sql_injection"),
                                text: t("this_is_a_technique_in_which_attackers_inject_malicious_code_into_databases_through_vulnerable_web_applications_gaining_access_to_sensitive_information"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("crosssite_scripting_xss"),
                                text: t("in_xss_attacks_hackers_inject_malicious_scripts_into_trusted_websites_leading_to_the_compromise_of_user_sessions_or_theft_of_personal_data"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        image: "Articles/security-introduction_ykvwyt",
                        imagePosition: "right",
                        alt: t("an_introductory_graphic_to_security_measures_and_protocols_for_safeguarding_information"),
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full",
                        theme: "dark"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("security_measures"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("use_of_ssltls_certificates"),
                                        text: t("these_certificates_provide_a_secure_connection_between_the_website_and_its_users_protecting_the_transmission_of_sensitive_information_such_as_personal_data_and_payment_details_from_interception"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("implementation_of_firewalls"),
                                        text: t("firewalls_serve_as_a_first_line_of_defense_against_unauthorized_access_and_attacks_they_filter_unwanted_traffic_and_block_potential_threats_before_they_reach_the_website"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("regular_security_audits"),
                                        text: t("performing_regular_security_audits_helps_identify_vulnerabilities_within_a_website_this_includes_scanning_for_weaknesses_evaluating_the_risk_and_implementing_measures_to_address_these_vulnerabilities"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("use_of_antimalware_software"),
                                        text: t("deploying_antimalware_software_is_essential_for_detecting_and_removing_malicious_software_that_can_compromise_the_integrity_of_a_website"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("content_security_policy_csp"),
                                        text: t("csp_helps_prevent_crosssite_scripting_xss_and_other_code_injection_attacks_by_specifying_which_dynamic_resources_can_be_loaded"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                ],
                                gap: "gap-4",
                                className: "lg:grid-cols-3"
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_authentication_and_access_management"),
                        text: t("in_the_digital_age_of_year_user_authentication_and_access_management_plays_a_crucial_role_in_website_security_these_systems_ensure_that_only_authorized_users_have_access_to_sensitive_data_and_functionalities_here_are_some_important_aspects", {
                            year: new Date().getFullYear()
                        }),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("multifactor_authentication_mfa"),
                                        text: t("mfa_requires_users_to_provide_two_or_more_pieces_of_authentication_to_gain_access_such_as_a_password_combined_with_a_mobile_authenticator_or_biometrics_this_increases_security_by_significantly_reducing_the_chance_of_unauthorized_access"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("strong_password_policy"),
                                        text: t("enforcing_strong_password_guidelines_is_essential_this_includes_using_long_complex_passwords_that_need_to_be_changed_regularly"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("rolebased_access_control_rbac"),
                                        text: t("rbac_limits_access_to_information_and_functionalities_based_on_the_users_role_within_the_organization_this_minimizes_the_chance_of_sensitive_data_falling_into_the_wrong_hands"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("regular_access_controls"),
                                        text: t("it_is_important_to_regularly_review_and_adjust_access_rights_especially_when_changing_user_roles_or_after_a_security_incident"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("single_signon_sso"),
                                        text: t("sso_allows_users_to_access_multiple_related_but_independent_software_applications_with_a_single_set_of_credentials_this_improves_the_user_experience_while_also_helping_to_reduce_the_number_of_credentials_that_need_to_be_managed_and_secured"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                ],
                                gap: "gap-4",
                                className: "lg:grid-cols-3"
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("data_encryption_and_backups"),
                        text: t("securing_sensitive_website_data_in_year_requires_a_strong_focus_on_data_encryption_and_regular_backups_encryption_ensures_that_data_is_protected_from_unauthorized_access_both_during_transmission_and_in_storage_in_addition_regular_backups_are_essential_to_ensure_data_can_be_recovered_in_the_event_of_a_data_breach_system_failure_or_cyberattack_together_these_two_elements_provide_a_fundamental_basis_for_ensuring_the_integrity_and_availability_of_website_data", {
                            year: new Date().getFullYear()
                        }),
                        image: "Articles/security-encryption_ga4epv",
                        alt: t("visual_elements_representing_security_encryption_technologies_for_protecting_digital_data"),
                        imagePosition: "right",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full",
                        theme: "dark"
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("regular_updates_and_patch_management"),
                        text: t("to_effectively_protect_websites_against_new_vulnerabilities_and_cyber_attacks_in_year_it_is_essential_to_implement_regular_software_updates_and_patch_management_this_process_includes_timely_updating_all_website_components_such_as_the_cms_plugins_and_scripts_to_ensure_that_security_holes_are_quickly_patched_regular_updates_not_only_help_strengthen_security_but_also_ensure_that_the_website_continues_to_function_smoothly_and_efficiently", {
                            year: new Date().getFullYear()
                        }),
                        image: "Articles/security-updates_bs65s7",
                        alt: t("icons_representing_the_latest_security_updates_for_enhancing_data_protection_measures"),
                        imagePosition: "left",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full",
                        theme: "dark"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ProcessSection",
                    componentProps: {
                        addReviews: true,
                        reviewsOrder: "before"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("awareness_and_training_of_employees"),
                        text: t("an_effective_security_policy_in_year_recognizes_the_crucial_role_of_employees_in_protecting_websites_here_are_the_main_focus_points", {
                            year: new Date().getFullYear()
                        }),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("multifactor_authentication_mfa"),
                                        text: t("mfa_requires_users_to_provide_two_or_more_pieces_of_authentication_to_gain_access_such_as_a_password_combined_with_a_mobile_authenticator_or_biometrics_this_increases_security_by_significantly_reducing_the_chance_of_unauthorized_access"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("strong_password_policy"),
                                        text: t("enforcing_strong_password_guidelines_is_essential_this_includes_using_long_complex_passwords_that_need_to_be_changed_regularly"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("rolebased_access_control_rbac"),
                                        text: t("rbac_limits_access_to_information_and_functionalities_based_on_the_users_role_within_the_organization_this_minimizes_the_chance_of_sensitive_data_falling_into_the_wrong_hands"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("regular_access_controls"),
                                        text: t("it_is_important_to_regularly_review_and_adjust_access_rights_especially_when_changing_user_roles_or_after_a_security_incident"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("single_signon_sso"),
                                        text: t("sso_allows_users_to_access_multiple_related_but_independent_software_applications_with_a_single_set_of_credentials_this_improves_the_user_experience_while_also_helping_to_reduce_the_number_of_credentials_that_need_to_be_managed_and_secured"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                ],
                                gap: "gap-4",
                                className: "lg:grid-cols-3"
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("future_trends_in_website_security"),
                        text: t("as_we_look_ahead_to_the_future_of_website_security_it_is_becoming_clear_that_innovation_and_technological_advancements_play_a_key_role_the_rise_of_artificial_intelligence_and_machine_learning_offers_new_opportunities_for_automating_security_processes_and_detecting_threats_in_realtime_in_addition_developments_in_blockchain_technology_and_quantum_computing_will_provide_new_methods_for_securing_data_and_improving_cryptographic_protocols_these_advancements_along_with_increasing_awareness_of_the_importance_of_cybersecurity_will_pave_the_way_for_more_robust_and_resilient_website_security_systems"),
                        children: [
                            {
                                type: "component",
                                componentKey: "Image",
                                componentProps: {
                                    src: "Articles/web-security_ouqkcv",
                                    alt: t("security_alt_text-1"),
                                    width: 500,
                                    height: 350,
                                    className: "object-contain"
                                }
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSectionNew",
                    componentProps: {
                        title: null,
                        text: null,
                    }
                }
            ],
        },
        {
            id: 'user-experience',
            title: t('user_experience_ux_best_practices'),
            description: t("learn_all_about_the_importance_of_user_experience_ux_in_web_design_and_how_webbeukers_applies_this_to_create_interactive_websites"),
            keywords: `${t('user_experience')}, ${t('UX')}, ${t('webdesign')}, ${t('interactive_websites')}, ${t('webbeukers')}`,
            pageLayout: [
                {
                    type: "component-section",
                    subType: "main-hero",
                    title: t('pages.articles.user_experience.hero.title'),
                    text: t('pages.articles.user_experience.hero.text'),
                    certificatesText: t('pages.articles.hero.certificates'),
                    disableBeuker: true,
                    buttons: [
                        {
                            text: t("pages.articles.hero.buttons.0"),
                            link: route("/contact", locale),
                        },
                        {
                            text: t("pages.articles.hero.buttons.1"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("user_engagement"),
                                        text: t("seo_rewards_websites_with_high_user_engagement_such_as_low_bounce_rates_and_high_dwell_time"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("mobilefriendliness"),
                                        text: t("a_key_factor_in_seo_given_the_increasing_use_of_mobile_devices_for_internet_access"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("website_speed"),
                                        text: t("fast_loading_times_are_essential_for_a_positive_user_experience_and_are_favorably_viewed_by_search_engines_optimizing_images_minimizing_code_and_using_fast_hosting_services_can_significantly_improve_website_speed"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("accessibility"),
                                        text: t("welldesigned_websites_are_more_accessible_which_contributes_to_a_wider_audience_and_better_seo_results"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("perform_user_testing"),
                                        text: t("regular_testing_with_real_users_to_identify_and_resolve_user_experience_bottlenecks"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("analyze_user_behavior"),
                                        text: t("use_analytics_tools_to_understand_how_users_interact_with_the_site"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("stay_abreast_of_trends"),
                                        text: t("follow_the_latest_trends_in_ux_design_to_stay_relevant_and_competitive"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                ],
                                gap: "gap-4",
                                className: "lg:grid-cols-3"
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ux_best_practices"),
                        ul: [
                            {
                                title: t("increasing_user_engagement"),
                                text: t("websites_with_high_user_engagement_such_as_low_bounce_rates_and_high_dwell_time_are_rewarded_by_search_engines_creating_engaging_content_interactive_elements_and_an_intuitive_navigation_structure_can_encourage_users_to_stay_longer_and_explore_more"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("mobile_friendliness"),
                                text: t("given_the_increasing_use_of_mobile_devices_for_internet_access_mobile_friendliness_is_a_crucial_factor_for_seo_a_responsive_design_that_adapts_to_different_screen_sizes_ensures_a_smooth_user_experience_across_all_devices"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("website_speed"),
                                text: t("fast_loading_times_are_essential_for_a_positive_user_experience_and_are_favorably_viewed_by_search_engines_optimizing_images_minimizing_code_and_using_fast_hosting_services_can_significantly_improve_website_speed"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("accessibility"),
                                text: t("welldesigned_websites_are_more_accessible_which_contributes_to_a_wider_audience_and_better_seo_results_ensure_that_the_website_complies_with_wcag_guidelines_with_features_such_as_keyboard_navigation_texttospeech_compatibility_and_adequate_color_contrast"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("user_testing"),
                                text: t("regular_testing_with_real_users_is_crucial_to_identify_and_resolve_bottlenecks_in_the_user_experience_this_can_range_from_structured_usability_tests_to_informal_feedback_sessions"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("analyzing_user_behavior"),
                                text: t("using_analytical_tools_to_understand_how_users_interact_with_the_site_can_provide_valuable_insights_for_further_improvements_look_for_patterns_in_navigation_conversion_paths_and_dropoff_points"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("extra_ux_topics_and_insights"),
                        ul: [
                            {
                                title: t("emotional_design"),
                                text: t("go_beyond_functionality_and_focus_on_creating_emotional_connections_with_users_through_design_this_may_include_the_use_of_color_psychology_microinteractions_and_narrative_elements_that_evoke_a_positive_emotional_response"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("inclusive_design"),
                                text: t("ensure_your_design_is_inclusive_and_takes_into_account_a_diverse_user_group_with_different_needs_and_backgrounds_this_involves_designing_for_various_ages_cultures_languages_and_physical_abilities"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("consistency_and_brand_identity"),
                                text: t("maintain_consistency_in_design_navigation_and_branding_across_the_website_to_build_trust_and_recognition_this_ensures_a_cohesive_experience_that_strengthens_brand_identity"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("innovation_and_trends"),
                                text: t("stay_uptodate_with_the_latest_ux_trends_and_technological_innovations_but_apply_them_with_consideration_for_the_specific_needs_and_preferences_of_your_target_audience_not_every_trend_will_be_relevant_for_every_project"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("integrating_feedback_loops"),
                                text: t("make_user_feedback_an_integral_part_of_your_ux_strategy_this_can_range_from_direct_feedback_mechanisms_on_the_site_to_regular_user_surveys_and_interviews"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ProcessSection",
                    componentProps: {
                        addReviews: true,
                        reviewsOrder: "after"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("conclusion"),
                        text: t("implementing_ux_best_practices_is_not_only_essential_for_improving_customer_satisfaction_but_it_is_also_crucial_to_a_websites_success_in_todays_competitive_digital_landscape_by_focusing_on_mobilefriendliness_speed_accessibility_and_a_deep_understanding_of_user_behavior_companies_can_create_an_environment_where_users_feel_valued_and_understood_leading_to_higher_engagement_better_conversions_and_ultimately_business_growth"),
                        theme: "dark",
                        className: "bg-white"
                    },
                },
                {
                    type: "component-section",
                    componentKey: "ContactSectionNew",
                    componentProps: {
                        title: null,
                        text: null,
                    }
                }
            ],
        },
        {
            id: 'use-of-cms',
            title: t('introduction_content_management_systems_cms'),
            description: t("explore_the_benefits_of_using_a_cms_for_website_management_with_webbeukers_and_learn_how_to_organize_content_efficiently"),
            keywords: `${t('content_management_systems')}, ${t('CMS')}, ${t('website_management')}, ${t('webbeukers')}, ${t('content_management')}`,
            pageLayout: [
                {
                    type: "component-section",
                    subType: "main-hero",
                    title: t('pages.articles.cms.hero.title'),
                    text: t('pages.articles.cms.hero.text'),
                    certificatesText: t('pages.articles.hero.certificates'),
                    disableBeuker: true,
                    buttons: [
                        {
                            text: t("pages.articles.hero.buttons.0"),
                            link: route("/contact", locale),
                        },
                        {
                            text: t("pages.articles.hero.buttons.1"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("userfriendliness"),
                                        text: t("seo_rewards_websites_with_high_user_engagement_such_as_low_bounce_rates_and_high_dwell_time"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("flexibility_and_adaptability"),
                                        text: t("ability_to_adapt_the_site_to_specific_needs"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("safety"),
                                        text: t("strong_security_measures_to_protect_the_website_and_user_data"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                    {
                                        title: t("seo_friendliness"),
                                        text: t("builtin_seo_tools_and_features_for_optimizing_the_website_for_search_engines"),
                                        theme: "transparent",
                                        textColor: "text-primary-gray-950",
                                    },
                                ],
                                gap: "gap-4",
                                className: "lg:grid-cols-3"
                            }
                        },
                        theme: "dark",
                        className: "bg-white"
                    },
                },
                {
                    type: "component-section",
                    componentKey: "ProcessSection",
                    componentProps: {
                        addReviews: true,
                        reviewsOrder: "after"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("popular_cms_platforms_a_comparison"),
                        ul: [
                            {
                                title: t("wordpress"),
                                text: t("the_most_used_cms_in_the_world_known_for_its_customizability_and_large_library_of_plugins_including_seo_tools"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("joomla"),
                                text: t("a_powerful_cms_with_advanced_functionalities_suitable_for_complex_websites"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("drupal"),
                                text: t("provides_robust_performance_and_security_often_used_for_large_complex_sites"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("squarespace"),
                                text: t("ideal_for_beginners_with_visually_appealing_templates_and_easy_draganddrop_functionality"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("tips_for_choosing_the_right_cms_for_seo"),
                        ul: [
                            {
                                title: t("identify_business_needs"),
                                text: t("choose_a_cms_that_suits_the_specific_requirements_of_the_website"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("research_seo_features"),
                                text: t("look_for_cms_platforms_with_strong_seo_functionalities"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("consider_community_and_support"),
                                text: t("an_active_community_and_good_support_can_help_solve_problems_and_implement_seo_strategies"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        children: [
                            {
                                type: "component",
                                componentKey: "Image",
                                componentProps: {
                                    src: "Articles/use-of-cms_gvhx2s",
                                    alt: t("cms_alt_text-1"),
                                    width: 500,
                                    height: 350,
                                    className: "object-contain"
                                }
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSectionNew",
                    componentProps: {
                        title: null,
                        text: null,
                    }
                }
            ],
        },
        {
            id: 'seo-for-small-businesses',
            title: t('local_seo_for_small_businesses'),
            description: t("discover_on_webbeukers_how_seo_can_help_small_businesses_grow_and_compete_in_the_market_with_effective_online_strategies"),
            keywords: `${t('seo')}, ${t('small_businesses')}, ${t('online_marketing')}, ${t('searchmachine_optimalisation')}, ${t('webbeukers')}`,
            pageLayout: [
                {
                    type: "component-section",
                    subType: "main-hero",
                    title: t('pages.articles.local_seo.hero.title'),
                    convertTitleToLink: true,
                    text: t('pages.articles.local_seo.hero.text'),
                    certificatesText: t('pages.articles.hero.certificates'),
                    disableBeuker: true,
                    buttons: [
                        {
                            text: t("pages.articles.hero.buttons.0"),
                            link: route("/contact", locale),
                        },
                        {
                            text: t("pages.articles.hero.buttons.1"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("local_keywords_and_content_strategy"),
                        ul: [
                            {
                                title: t("keyword_research"),
                                text: t("identify_local_keywords_relevant_to_your_business_and_region__use_tools_like_google_keyword_planner_to_find_local_search_terms"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("use_of_keywords"),
                                text: t("naturally_integrate_local_keywords_into_website_content_including_titles_meta_tags_and_descriptions__make_sure_keywords_match_the_way_local_customers_search"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("local_content_creation"),
                                text: t("develop_content_relevant_to_the_local_community_such_as_blog_posts_event_guides_or_news_updates__create_unique_landing_pages_for_different_locations_if_your_company_has_multiple_locations"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("use_of_local_events_and_news"),
                                text: t("include_local_events_news_and_activities_in_your_content_strategy__promote_and_share_local_engagement_via_social_media_and_on_the_website"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("local_landing_page_optimization"),
                                text: t("provide_each_location_with_a_specific_landing_page_with_unique_content_contact_information_and_local_keywords__ensure_consistency_in_nap_data_name_address_phone_number_across_the_entire_website"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_my_business_optimization"),
                        text: t("your_google_my_business_gmb_profile_is_your_showcase_for_local_searchers_its_important_to_claim_and_verify_your_gmb_profile_and_ensure_all_information_such_as_your_address_opening_hours_and_services_is_accurate_and_up_to_date"),
                        theme: "dark",
                        className: "bg-white"
                    },
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("local_link_building"),
                        text: t("local_link_building_is_an_essential_part_of_any_local_seo_strategy_it_involves_gaining_backlinks_from_reputable_and_relevant_local_sources_which_can_improve_local_search_engine_rankings_and_increase_visibility_in_the_community"),
                        ul: [
                            {
                                title: t("networking_with_local_businesses_and_organizations"),
                                text: t("build_relationships_with_other_local_businesses_associations_and_organizations_consider_partnerships_or_joint_promotions_that_can_promote_link_exchanges"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("participation_in_local_events_and_sponsorship"),
                                text: t("participate_in_or_sponsor_local_events_fairs_and_charities_such_activities_can_generate_natural_backlinks_from_event_sites_and_local_news_sources"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("local_media_and_press_releases"),
                                text: t("use_local_media_to_spread_news_or_updates_about_your_business_publish_press_releases_to_local_news_outlets_for_major_announcements_or_events"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("registration_with_local_directories_and_business_directories"),
                                text: t("make_sure_your_business_is_listed_in_local_business_directories_such_as_yelp_the_local_chamber_of_commerce_and_industryspecific_directories_provide_consistent_and_accurate_company_information_across_all_directories"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("using_social_media_to_strengthen_local_connections"),
                                text: t("be_active_on_social_media_and_involve_the_local_community_in_your_activities_promote_local_events_and_collaborations_and_encourage_sharing_and_interaction"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("guest_blogs_and_local_influencers"),
                                text: t("write_guest_blogs_for_local_websites_or_invite_local_influencers_to_write_about_your_business_this_can_not_only_generate_backlinks_but_also_increase_your_visibility_and_reputation_in_the_local_community"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("online_reviews_and_reputation_management"),
                        text: t("the_focus_is_on_the_critical_importance_of_customer_reviews_and_how_they_impact_local_seo_and_a_companys_overall_image_actively_collecting_customer_feedback_especially_on_platforms_like_google_my_business_and_yelp_plays_a_key_role_in_building_a_trusted_online_presence_it_is_essential_to_respond_to_both_positive_and_negative_reviews_professionally_and_constructively_as_this_increases_engagement_and_trust_with_potential_customers_in_addition_continuously_monitoring_online_reputation_and_responding_to_feedback_and_mentions_on_social_media_and_other_online_platforms_is_fundamental_to_maintaining_a_positive_corporate_image_and_strengthening_relationships_with_the_local_community"),
                        theme: "dark",
                        className: "bg-white"
                    },
                },
                {
                    type: "component-section",
                    componentKey: "ProcessSection",
                    componentProps: {
                        addReviews: true,
                        reviewsOrder: "before"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("mobile_optimization"),
                        text: t("mobile_optimization_is_a_crucial_part_of_local_seo_especially_since_a_significant_portion_of_local_searches_occur_via_mobile_devices_it_ensures_that_your_website_performs_well_not_only_on_desktops_but_also_on_smartphones_and_tablets"),
                        ul: [
                            {
                                title: t("responsive_web_design"),
                                text: t("make_sure_your_website_has_a_responsive_design_this_means_that_the_layout_and_content_automatically_adapt_to_different_screen_sizes_a_responsive_website_provides_a_seamless_user_experience_regardless_of_the_device_used"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("speed_optimization"),
                                text: t("mobile_users_expect_fast_loading_times_optimize_images_minimize_code_and_use_caching_to_improve_loading_speed_tools_like_googles_pagespeed_insights_can_help_identify_areas_for_improvement"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("userfriendly_navigation"),
                                text: t("make_sure_website_navigation_is_simple_and_intuitive_on_mobile_devices_consider_features_like_a_click_to_call_button_which_are_very_useful_for_mobile_users"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("local_information_and_functionalities"),
                                text: t("integrate_local_information_such_as_maps_and_directions_into_your_website_this_is_especially_useful_for_users_on_the_go_make_sure_contact_information_such_as_phone_numbers_and_addresses_are_easy_to_find_and_use_on_mobile_devices__make_sure_contact_information_such_as_phone_numbers_and_addresses_are_easy_to_find_and_use_on_mobile_devices"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("testing_and_feedback"),
                                text: t("use_mobile_testing_tools_to_check_how_your_site_performs_on_different_devices_and_browsers_collect_feedback_from_mobile_users_and_make_improvements_based_on_it__collect_feedback_from_mobile_users_and_make_improvements_based_on_it"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("local_seo_analytics_and_monitoring"),
                        text: t("its_important_to_continuously_monitor_and_analyze_the_performance_of_your_local_seo_efforts_this_includes_regularly_checking_your_rankings_in_local_search_results_monitoring_traffic_to_your_website_and_observing_visitor_behavior_tools_like_google_analytics_and_google_search_console_are_essential_for_gathering_valuable_insights_into_how_users_find_and_interact_with_your_website_this_data_allows_you_to_recognize_patterns_assess_the_effectiveness_of_your_local_seo_strategies_and_make_adjustments_as_necessary_its_also_important_to_pay_attention_to_conversions_and_how_they_tie_to_your_local_seo_activities_by_carefully_monitoring_these_aspects_you_can_make_targeted_improvements_that_will_strengthen_your_companys_local_discoverability_and_overall_online_presence"),
                        children: [
                            {
                                type: "component",
                                componentKey: "Image",
                                componentProps: {
                                    src: "Articles/seo_tyxyso",
                                    alt: t("seo_alt_text-1"),
                                    width: 400,
                                    height: 350,
                                    className: "object-contain"
                                }
                            },
                            {
                                type: "component",
                                componentKey: "Image",
                                componentProps: {
                                    src: "Articles/google-seo_f8uiw5",
                                    alt: t("seo_alt_text-2"),
                                    width: 450,
                                    height: 350,
                                    className: "object-contain ml-0 md:ml-5"
                                }
                            },
                            {
                                type: "component-section",
                                componentKey: "ContactSectionNew",
                                componentProps: {
                                    title: null,
                                    text: null,
                                }
                            }
                        ],
                        theme: "dark",
                        className: "bg-white"
                    },
                },
            ],
        },
    ];

    for (const page of articlePages) {
        const path = articlePaths.find(path => path.id === page.id);

        if (path) {
            page.link = path.link;
        }
    }

    return {
        articlePages
    };
}

export default getArticleConstants;