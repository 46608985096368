'use client';

import React from 'react';

import { ButtonOneProps } from '@/components/shared/ButtonOne';
import { ComponentProps, DefaultPageProps } from '@/lib/types/shared/page.types';
import { Container, Link, Typography, useMediaQuery } from '@mui/material';
import { Image, HeadingText, ButtonNew, InfoBlocks, InfoBlocksSlider, GoogleMaps, Head } from '@/components/shared';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import { capitalize, convertMarkdownLinks } from '@/lib/utils';
import { useTranslations } from 'next-intl';

type Props = {
    title: string,
    convertTitleToLink?: boolean,
    subTitle?: string,
    text?: string,
    texts?: string[],
    convertTextToLink?: boolean,
    showReviews?: boolean,
    buttons?: ButtonOneProps[],
    certificatesText?: string,
    headerImage?: string,
    useMapsInsteadOfImage?: boolean,
    beukerImage?: string | {
        src: string,
        alt: string,
        width: number,
        height: number,
    },
    disableBeuker?: boolean,
    infoBlockItems?: any[],
    children?: React.ReactNode,
} & DefaultPageProps & ComponentProps;

export default function MainHero({ title, convertTitleToLink = false, subTitle, text, texts, convertTextToLink = false, showReviews = true, buttons, certificatesText, infoBlockItems, headerImage = '/images/home-hero.webp', useMapsInsteadOfImage = false, beukerImage = 'Confident_Beuker_wce3qb', disableBeuker = false, params, children }: Props) {
    const t = useTranslations();

    const isMobile = useMediaQuery('(max-width: 768px)');

    const scaleHeight = (originalWidth: number, originalHeight: number, standardWidth: number) => {
        let scaleFactor = standardWidth / originalWidth;
        let scaledHeight = originalHeight * scaleFactor;

        return Math.round(scaledHeight);
    }

    return (
        <>
            <Head tag="link" rel="preload" href={headerImage} as="image" />

            <Container className='relative rounded-lg' style={{
                minHeight: !disableBeuker && !isMobile ? 500 : 300,
            }}>
                {useMapsInsteadOfImage ? (
                    <GoogleMaps
                        apiKey={process.env.NEXT_APP_MAPS_API_KEY ?? ""}
                        lat={52.12825817974103}
                        lng={5.56556857018485}
                        mapId="74ce6df1eb3b0fa0"
                        markers={[
                            {
                                lat: 52.12825817974103,
                                lng: 5.56556857018485,
                                icon: process.env.NEXT_CLOUDINARY_URL + "Icons/maps-marker_oalttt.webp",
                                width: 46,
                                height: 71,
                            }
                        ]}
                        height="h-full"
                        className='absolute top-0 left-0 w-full z-0 rounded-lg bg-primary-gray-950'
                        style={{
                            maskImage: 'url(/images/home-hero-substract.svg)',
                            maskSize: 'cover',
                            maskPosition: 'bottom center',
                            maskRepeat: 'no-repeat',
                        }}
                    />
                ) : (
                    <>
                        <div className="absolute top-0 left-0 w-full h-full z-0 rounded-lg" style={{
                            backgroundImage: `url(${headerImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: '50% 70%',
                            backgroundRepeat: 'no-repeat',

                            maskImage: 'url(/images/home-hero-substract.svg)',
                            maskSize: 'cover',
                            maskPosition: 'bottom center',
                            maskRepeat: 'no-repeat',
                        }} />

                        <div className="absolute top-0 left-0 w-full h-full z-0 rounded-lg bg-gradient-to-r from-primary-gray-950 to-transparent" style={{
                            maskImage: 'url(/images/home-hero-substract.svg)',
                            maskSize: 'cover',
                            maskPosition: 'bottom center',
                            maskRepeat: 'no-repeat',
                        }} />
                    </>
                )}

                <div className={`grid grid-cols-1 ${infoBlockItems ? 'md:grid-cols-2' : 'md:grid-cols-5'} py-12 relative z-1`}>
                    <div className={`flex flex-col justify-between ${infoBlockItems ? '' : 'md:col-span-3'}`}>
                        <div>
                            {showReviews &&
                                <>
                                    <Image
                                        src="Icons/Rating-star_aybkcq"
                                        alt="Google Reviews"
                                        width={200}
                                        height={97.45}
                                        className="w-16 md:w-28 h-auto"
                                    />
                                    <Link target='_blank' href={'https://www.google.com/search?sa=X&sca_esv=7d935f78db81bbbe&rlz=1C5CHFA_enNL1023NL1026&tbm=lcl&sxsrf=AHTn8zqhvHN-kPVh-Bs0PsNXHI5OAG7t5w:1739277676950&q=Web+Beukers+Reviews&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2MTQ0NzEwMLUwt7AwMDc1MTYz38DI-IpRODw1ScEptTQ7tahYISi1LDO1vHgRKzZRAF8f_L1GAAAA&rldimm=4117400587880754367&hl=nl-NL&ved=2ahUKEwjIt-ag0ruLAxV1hP0HHaaWDYUQ9fQKegQITBAF&biw=1411&bih=958&dpr=1#lkt=LocalPoiReviews'} className='flex items-center gap-2 -mt-2 mb-2 w-fit text-white'>{t('read_reviews')}</Link>
                                </>
                            }

                            {title !== undefined && title !== null &&
                                <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                                    <HeadingText variant='h1' enableMaxWidth={false} className='md:text-5xl lg:text-6xl md:leading-extra-tight'>
                                        {convertTitleToLink ?
                                            <span className='md:text-5xl lg:text-6xl md:leading-extra-tight' dangerouslySetInnerHTML={{
                                                __html: capitalize(convertMarkdownLinks(title, params.locale))
                                            }}></span>
                                        : capitalize(title)}
                                    </HeadingText>
                                </MoveFadeInAnimation>
                            }

                            {subTitle !== undefined && subTitle !== null &&
                                <MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
                                    <HeadingText variant='h2' className='md:text-3xl lg:text-4xl mt-2 lg:mt-1'>
                                        {subTitle}
                                    </HeadingText>
                                </MoveFadeInAnimation>
                            }

                            {(text !== undefined && text !== null && text !== '') ? (
                                <Typography variant='body1' className={`mt-3 lg:mt-2 hidden md:block max-w-lg ${disableBeuker && !infoBlockItems ? 'mb-32' : 'mb-0'}`}>
                                    {convertTextToLink ?
                                        <span className='text-white text-start normal-case max-w-screen-md' dangerouslySetInnerHTML={{
                                            __html: convertMarkdownLinks(text, params.locale)
                                        }}></span>
                                    : text}
                                </Typography>
                            ) : (texts !== undefined && texts !== null && texts.length > 0) ? (
                                <div className={`hidden md:flex flex-col gap-y-4 ${disableBeuker && !infoBlockItems ? 'mb-32' : 'mb-0'}`}>
                                    {texts.map((text, index) => (
                                        <Typography key={index} variant='body1' className='mt-3 lg:mt-2 max-w-lg'>
                                            {convertTextToLink ?
                                                <span className='text-white text-start normal-case max-w-screen-md' dangerouslySetInnerHTML={{
                                                    __html: convertMarkdownLinks(text, params.locale)
                                                }}></span>
                                            : text}
                                        </Typography>
                                    ))}
                                </div>
                            ) : null}
                        </div>

                        {certificatesText !== undefined && certificatesText !== null &&
                            <div className='hidden md:flex items-center gap-x-2'>
                                <Image
                                    src='Icons/Badge_azf2tk'
                                    alt='Certificates'
                                    width={24}
                                    height={24}
                                />

                                <Typography variant='body1' className='max-w-lg'>{certificatesText}</Typography>
                            </div>
                        }
                    </div>

                    <div className={`relative flex gap-x-6 ${infoBlockItems ? 'justify-end' : 'justify-center md:col-span-2'} ${disableBeuker ? 'hidden' : ''}`}>
                        <Image
                            src={typeof beukerImage === 'string' ? beukerImage : beukerImage.src}
                            alt={typeof beukerImage === 'string' ? 'Confident Beuker' : beukerImage.alt}
                            width={typeof beukerImage === 'string' ? 235 : beukerImage.width}
                            height={typeof beukerImage === 'string' ? 513 : beukerImage.height}
                            className='animate-scale -ml-8 mt-8 hidden md:block lg:w-auto lg:h-96'
                            style={{
                                width: '230px',
                                height: typeof beukerImage === 'string' ? `${scaleHeight(235, 513, 230)}` : `${scaleHeight(beukerImage.width, beukerImage.height, 230)}px`
                            }}
                        />

                        {infoBlockItems && (
                            <InfoBlocks
                                items={infoBlockItems}
                                hideOnMobile={true}
                                alignment='vertical'
                                className='-mr-14'
                                animationDuration={3000}
                                params={params}
                            />
                        )}
                    </div>
                </div>
            </Container>

            <div className="block md:hidden">
                {infoBlockItems && (
                    <InfoBlocksSlider
                        items={infoBlockItems}
                        className='relative z-10 -translate-y-full'
                        animationDuration={3000}
                        params={params}
                    />
                )}
            </div>

            <Container className='block md:hidden'>
                {(text !== undefined && text !== null && text !== '') ? (
                    <Typography variant='body1' className='text-primary-gray-950 mt-6'>{text}</Typography>
                ) : (texts !== undefined && texts !== null && texts.length > 0) ? (
                    <div className='flex flex-col gap-y-4'>
                        {texts.map((text, index) => (
                            <Typography key={index} variant='body1' className='text-primary-gray-950 mt-6'>{text}</Typography>
                        ))}
                    </div>
                ) : null}
            </Container>

            <Container className='mt-6 flex flex-wrap justify-between items-center gap-4'>
                <div className="flex flex-wrap items-center gap-4">
                    {buttons && buttons.map((button, index) => (
                        <MoveFadeInAnimation key={index} direction='right' delay={100 + (index * 100)}>
                            <ButtonNew type={button.type ?? ''} disabled={button.disabled ?? false} text={button.text} link={button.link ?? undefined} onclick={button.onclick ?? undefined} />
                        </MoveFadeInAnimation>
                    ))}
                </div>

                {children && children}
            </Container>
        </>
    )
}
