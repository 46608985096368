'use client'

import { route } from '@/lib/navigationFunctions';
import { ComponentProps, Params } from '@/lib/types/shared/page.types';

type Props = {
    params: Params;
    pathname: any;
} & ComponentProps;

export default function LanguageSwitcher({ params, pathname, className = '' }: Props) {
    function switchLanguage(e: any) {
        const locale = e.target.value;
        
        const newUrl = route(pathname.key, locale);
        window.location.href = newUrl;
    }

    return (
      <>
        <select id='language' onChange={switchLanguage} style={{
            boxShadow: 'none!important'
        }} className={`cursor-pointer select-no-arrow rounded-md bg-transparent text-white border-white ${className}`} defaultValue={params.locale}>
            <option value="en" className='text-black'>EN</option>
            <option value="nl" className='text-black'>NL</option>
        </select>
        <label htmlFor="language" className='flex flex-col gap-2 justify-center items-end opacity-0 absolute -z-50'>
            Language
        </label>
      </>
  )
}
