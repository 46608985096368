'use client';

import React from 'react';

import { Params, DefaultPageProps, ComponentProps, Tag, Themes } from "@/lib/types/shared/page.types";

import { Container, Typography } from '@mui/material';

import { route } from '@/lib/navigationFunctions';
import { useTranslations } from 'next-intl';

import Image from '@/components/shared/Image';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import ButtonOne, { ButtonOneProps } from '@/components/shared/ButtonOne';
import { convertMarkdownLinks } from '@/lib/utils';
import HeadingText from "@/components/shared/HeadingText";
import ButtonNew from './ButtonNew';

type Props = {
    children?: React.ReactNode | null,
    convertTextToLink?: boolean,
    params: Params;
    title: string;
    description?: string;
    text?: string | Array<string>;
    tags?: Tag[];
    wrapperStyle?: {
        [key: string]: string;
    };
    image?: string;
    images?: Array<any>;
    alt?: string;
    imagePosition: string;
    imageClassName?: string;
    imageWidth?: string;
    isExternal?: boolean;
    textColorClassname?: string;
    link?: string;
    cols?: {
        left: string;
        right: string;
    };
    titleVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    ul?: Array<any>,
    buttons?: ButtonOneProps[],
    mobileReverse?: boolean;
    theme?: Themes;
} & DefaultPageProps & ComponentProps;

export default function ImageBlock({children = null, convertTextToLink = false, textColorClassname = 'text-primary-gray-950', params, title, titleVariant = 'h3', description = '', text = '', tags, wrapperStyle, image, images, imagePosition, imageClassName = '', imageWidth, isExternal, link, cols, alt = "", ul, buttons, mobileReverse = false, className = '', theme = 'dark' }: Props) {
    
    const t = useTranslations();

    let leftImageClasses = '';
    if (!imageClassName.includes('w-') && !imageWidth) leftImageClasses += 'w-full md:w-3/5 ';
    if (!imageClassName.includes('h-')) leftImageClasses += 'h-auto ';
    if (!imageClassName.includes('rounded-')) leftImageClasses += 'rounded-2xl ';
    if (!imageClassName.includes('mx-')) leftImageClasses += 'mx-auto ';
    if (!imageClassName.includes('md:mx-')) leftImageClasses += 'md:mx-0 ';

    let rightImageClasses = '';
    // if (!imageClassName.includes('w-')) rightImageClasses += 'w-full ';
    if (!imageClassName.includes('w-') && !imageWidth) rightImageClasses += 'w-full md:w-3/5 ';
    if (!imageClassName.includes('h-')) rightImageClasses += 'h-fit ';
    if (!imageClassName.includes('rounded-')) rightImageClasses += 'rounded-2xl ';
    if (!imageClassName.includes('mx-')) rightImageClasses += 'mx-auto ';
    if (!imageClassName.includes('md:mx-')) rightImageClasses += 'md:mr-0 ';

    return (
        <Container style={{
            display: 'flex',
            ...wrapperStyle
        }} className={`${imagePosition === 'left' ? mobileReverse ? 'flex-col' : 'flex-col-reverse' : mobileReverse ? 'flex-col-reverse' : 'flex-col' } gap-8 md:flex-row justify-between items-center ${className}`}>
            {(imagePosition === "left" && image) &&
                <div className={`${cols ? cols.left : 'w-full md:w-2/3'}`}>
                    
                    {(typeof link !== "undefined" && link !== '') ? (
                        <a href={link} target="_blank" rel="noopener noreferrer" className={`block overflow-hidden ${leftImageClasses} ${imageClassName} ${imageWidth ?? ''}`}>
                            <Image
                                width={712}
                                height={333}
                                loading="lazy"
                                src={image}
                                className={`w-full h-auto block`}
                                alt={alt}
                            />
                        </a>
                    ): (
                        <div className={`block overflow-hidden ${leftImageClasses} ${imageClassName} ${imageWidth ?? ''}`}>
                            <Image
                                width={712}
                                height={333}
                                loading="lazy"
                                src={image}
                                className={`w-full h-auto block`}
                                alt={alt}
                            />
                        </div>
                    )}
                </div>
            }

            {(imagePosition === 'left' && images) &&
                <div className={`${cols ? cols.left : 'w-full md:w-2/3 flex flex-col'}`}>
                    {images.map((image, index) => (
                        <Image
                            key={index}
                            loading='eager'
                            src={image.image}
                            alt={image.alt}
                            width={491}
                            height={457}
                            className={`mr-0 md:mr-auto ${image.maxWidth ? image.maxWidth : 'max-w-3/4 md:max-w-none'} h-auto rounded-2xl md:rounded-l-none ${image.className ? image.className : ''}`}
                        />
                    ))}
                </div>
            }

            <div className={`${cols ? imagePosition === "left" ? cols.right : cols.left : 'w-full md:w-1/3'}`}>
                <HeadingText className={`${!description || description == '' ? 'mb-4' : ''} ${textColorClassname} ${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`} variant={titleVariant}>
                    <span
						dangerouslySetInnerHTML={{
						__html: convertMarkdownLinks(title, params.locale, false)
						}}
                        className={`${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`}
					></span>
                </HeadingText>
                {description && description !== '' &&
                    <HeadingText className={`mt-2 mb-3 text-wrap ${textColorClassname ? textColorClassname : `${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`}`} variant="h6">
                        {description}
                    </HeadingText>
                }
                {typeof text === 'object' ? (
                    text.map((singleText, index) => (
                        <Typography key={index} className={`text-start normal-case max-w-screen-md mb-4 ${textColorClassname ? textColorClassname : `${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`}`} variant='body1'>
                            {convertTextToLink ?
                                <span className={`text-start normal-case max-w-screen-md ${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`} dangerouslySetInnerHTML={{
                                    __html: convertMarkdownLinks(singleText!, params.locale)
                                }}></span>
                            : singleText}
                        </Typography>
                    ))
                ) : (
                    <Typography className={`text-start normal-case max-w-screen-md ${textColorClassname ? textColorClassname : `${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`}`} variant='body1'>                        
                        {convertTextToLink ?
                            <span className={`text-start normal-case max-w-screen-md ${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`} dangerouslySetInnerHTML={{
                                __html: convertMarkdownLinks(text!, params.locale)
                            }}></span>
                        : text}
                    </Typography>
                )}

                {ul &&
                    <ul className="list-disc list-inside mt-5 max-w-full md:max-w-17/20">
                        {ul.map((item, index) => (
                            <li key={index} className="flex gap-3 my-2">
                                {item.icon && item.icon !== '' && (
                                    <Image
                                        loading='lazy'
                                        src={item.icon}
                                        alt={item.alt ?? 'List icon'}
                                        width={20}
                                        height={19.05}
                                        className="w-5 h-fit mt-1"
                                    />
                                )}
                                <Typography className={`text-start normal-case max-w-screen-md ${textColorClassname} ${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`} variant='body1'>
                                    <span className="font-bold">{item.title}</span> {item.text}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                }

                {buttons &&
                    <div className="flex flex-row flex-wrap justify-start items-start gap-4 mt-5">
                        {buttons.map((button, index) => (
                            <MoveFadeInAnimation key={index} delay={300 * index} direction="right" distance="xl" amount={0.5} extraclasses={button.width === 'full' ? 'flex-1' : ''}>
                                <div key={index} className={button.width ? button.width : 'w-fit'}>
                                    {(button.onclick) ? (
                                        // <ButtonOne variant={button.variant} text={button.text} type="button" onclick={button.onclick} hoverEffect={true} />
                                        <ButtonNew text={button.text} type="button" onclick={button.onclick} />
                                    ) : (
                                        // <ButtonOne variant={button.variant} text={button.text} link={button.link} hoverEffect={true} />
                                        <ButtonNew text={button.text} link={button.link} />
                                    )}
                                </div>
                            </MoveFadeInAnimation>
                        ))}
                    </div>
                }

                { /* Eerder gemaakte stuk door Martien, ik heb bij de bovenste variant een flex-wrap op de div gezet, zodat de buttons naast elkaar komen te staan op mobiel. */ }
                {/* {buttons &&
                    <div className="flex flex-row flex-wrap justify-start items-start gap-2 mt-5">
                        {buttons.map((button, index) => (
                            <MoveFadeInAnimation key={index} delay={300 * index} direction="right" distance="xl" amount={0.5}>
                                <div key={index} className="w-fit">
                                    {(button.onclick) ? (
                                        <ButtonOne variant={button.variant} text={button.text} type="button" onclick={button.onclick} hoverEffect={true} />
                                    ) : (
                                        <ButtonOne variant={button.variant} text={button.text} link={button.link} hoverEffect={true} />
                                    )}
                                </div>
                            </MoveFadeInAnimation>
                        ))}
                    </div>
                } */}

                {tags && tags.length > 0 &&
                    <div className='mt-3'>
                        {tags.map((tag, index) => (
                            <div key={index} className="flex flex-wrap items-center gap-2 my-2">
                                <div className="bg-black p-2 grid place-items-center w-10 h-10">
                                    <Image
                                        width={24}
                                        height={24}
                                        loading="lazy"
                                        src={tag.icon}
                                        className="w-6 h-auto"
                                        alt={alt}
                                    />
                                </div>
                                {tag.items.map((item, index) => (
                                    <div key={index} className='h-fit bg-white grid place-items-center px-2 py-1 rounded-md'>
                                        <Typography key={index} className={`text-start normal-case font-semibold leading-6 ${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`} variant='body1'>
                                            {item}
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                }

                {typeof link !== "undefined" ?
                    !isExternal ? (
                        // <ButtonOne link={route(link, params.locale)} className="w-fit ml-0 mt-5 block text-white text-start">
                        //     {t("view_this_case")}
                        // </ButtonOne>
                        <ButtonNew text={t("view_this_case")} link={route(link, params.locale)} />
                    ) : (
                        // <ButtonOne link={link} target="_blank" className="w-fit ml-0 mt-5 block text-white text-start">
                        //     {t("view_this_case")}
                        // </ButtonOne>
                        <ButtonNew text={t("view_this_case")} link={link} target="_blank" />
                    )
                : null}
                
                {children}
            </div>

            {(imagePosition === "right" && image) &&
                <div className={`${cols ? cols.right : 'w-full md:w-2/3'}`}>
                    
                    {(typeof link !== "undefined" && link !== '') ? (
                        <a href={link} target="_blank" rel="noopener noreferrer" className={`block overflow-hidden ${rightImageClasses} ${imageClassName} ${imageWidth ?? ''}`}>
                            <Image
                                width={712}
                                height={333}
                                loading="lazy"
                                src={image}
                                className={`w-full h-auto block`}
                                alt={alt}
                            />
                        </a>
                    ) : (
                        <div className={`block overflow-hidden ${leftImageClasses} ${imageClassName} ${imageWidth ?? ''}`}>
                            <Image
                                width={712}
                                height={333}
                                loading="lazy"
                                src={image}
                                className={`w-full h-auto block`}
                                alt={alt}
                            />
                        </div>
                    )}
                </div>
            }

            {(imagePosition === 'right' && images) &&
                <div className={`${cols ? cols.right : 'w-full md:w-2/3 flex flex-col justify-center'}`}>
                    {images.map((image, index) => (
                        <Image
                            key={index}
                            loading='eager'
                            src={image.image}
                            alt={image.alt}
                            width={491}
                            height={457}
                            className={`ml-0 md:ml-auto ${image.maxWidth ? image.maxWidth : 'max-w-3/4 md:max-w-none'} h-auto rounded-2xl md:rounded-r-none ${image.className ? image.className : ''}`}
                        />
                    ))}
                </div>
            }
        </Container>
    )
}