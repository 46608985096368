import { DefaultPageProps } from '@/lib/types/shared/page.types';
import React, { useEffect, useRef, useState } from 'react';

import Image from '@/components/shared/Image';
import { Typography } from '@mui/material';

type Props = {
    items: {
        title: string,
        text: string,
        image: {
            src: string,
            alt: string,
            width: number,
            height: number,
        },
        label: string
    }[],
    animationDuration?: number,
    className?: string
} & DefaultPageProps;

export default function ExpandableAnimatedBlocks({ items, animationDuration = 3000, className = '', params }: Props) {
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const [intervalPaused, setIntervalPaused] = useState<boolean>(false);
    const [active, setActive] = useState<number>(0);

    function handleInterval() {
        setActive(getNextIndex(active));
    }

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(handleInterval, animationDuration);

        if (intervalPaused) {
            pauseInterval();
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [active]);

    const pauseInterval = () => {
        if (intervalRef.current) {
            setIntervalPaused(true);
            clearInterval(intervalRef.current);
        }
    };

    const resumeInterval = () => {
        if (intervalRef.current) {
            setIntervalPaused(false);
            intervalRef.current = setInterval(handleInterval, animationDuration);
        }
    };

    function needsProgress(index: number) {
        return index === getNextIndex(active);

    }

    function getNextIndex(index: number) {
        if (index + 1 < items.length) {
            return index + 1;
        } else {
            return 0;
        }
    }

    return (
        <div data-interval-paused={intervalPaused} className={`expandable-animated-blocks flex flex-col md:flex-row gap-4 ${className}`} onMouseEnter={pauseInterval} onMouseLeave={resumeInterval}>
            {items.map((item, index) => (
                <div key={index} onClick={() => setActive(index)} data-animate-progress={needsProgress(index)} data-item-active={index === active} className={`${active == index ? 'md:max-w-full' : 'md:max-w-1/5'} w-full min-h-72 rounded-lg transition-all duration-300 relative px-5 py-4 overflow-hidden`}>
                    <div className='absolute top-0 left-0 w-full h-full rounded-lg overflow-hidden z-0'>
                        <Image
                            src={item.image.src}
                            alt={item.image.alt}
                            width={item.image.width}
                            height={item.image.height}
                            className='w-full h-full object-cover'
                        />

                        <div className={`${active == index ? 'opacity-0' : 'opacity-100'} absolute top-0 left-0 w-full h-full bg-primary-gray-950/75 transition-all duration-300`} />
                    </div>

                    <div className={`${active == index ? 'opacity-100' : 'opacity-0 h-0'} relative z-1 transition-all duration-300 overflow-hidden`}>
                        <Typography variant='h2' className='font-semibold capitalize md:text-5xl'>{item.title}</Typography>
                        <Typography variant='body1'>{item.text}</Typography>
                    </div>

                    <div className='absolute bottom-0 left-0 z-1 bg-black h-8 rounded-lg' data-item-progress style={{
                        animationDuration: `${animationDuration}ms!important`
                    }}></div>

                    <div className={`${active == index ? 'w-full' : ''} min-h-8 px-3 py-1 absolute bottom-0 left-0 z-1 bg-black transition-all duration-300 rounded-lg`}>
                        <Typography variant='body1' className='text-primary-yellow-400'>{item.label}</Typography>
                    </div>
                </div>
            ))}
        </div>
    );
}
