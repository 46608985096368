import React from 'react';

import { Params, DefaultPageProps, Variants } from "@/lib/types/shared/page.types";

import Hero from '@/components/shared/Hero';
import ContactSection from '@/components/shared/ContactSection';
import ContactSectionNew from '@/components/shared/ContactSectionNew';
import ImageBlock from '@/components/shared/ImageBlock';
import SliderBlock from '@/components/shared/SliderBlock';
import CompanyBanner from '@/components/shared/CompanyBanner';
import { Container } from '@mui/material';
import ImageSection from '@/components/shared/ImageSection';
import NormalSection from '@/components/shared/NormalSection';
import ArrowCardGrid from '@/components/shared/ArrowCardGrid';
import ApplySection from '@/components/shared/ApplySection';
import Review from '@/components/shared/Review';
import Image from '@/components/shared/Image';
import ButtonOne from '@/components/shared/ButtonOne';
import MainHero from '@/components/shared/MainHero';
import GoogleReviews from '@/components/shared/GoogleReviews';
import ProcessSection from '@/components/shared/ProcessSection';
import ButtonNew from './ButtonNew';

interface Props extends DefaultPageProps {
    params: Params;
    pageLayout: Array<any>;
    old?: boolean;
}

type SharedComponent = {
    [key: string]: React.FC<any>;
}

export type Button = {
    text: string;
    link: string;
    variant: Variants;
}

export default async function DynamicPageContent({ params, pageLayout, old = false }: Props) {
    const sharedComponents: SharedComponent = {
        'ContactSection': ContactSection,
        'ContactSectionNew': ContactSectionNew,
        'ImageBlock': ImageBlock,
        'SliderBlock': SliderBlock,
        'ArrowCardGrid': ArrowCardGrid,
        'CompanyBanner': CompanyBanner,
        'ImageSection': ImageSection,
        'NormalSection': NormalSection,
        'Review': Review,
        'ApplySection': ApplySection,
        'Image': Image,
        'GoogleReviews': GoogleReviews,
        'ProcessSection': ProcessSection,
    };

    return (
        <>
            {pageLayout && pageLayout.map((item, index) => (
                item.type === 'component-section' ? (
                    <React.Fragment key={index}>
                        {(item.subType && item.subType === 'main-hero') ? (
                            <section className="pt-32 pb-8 px-5 bg-white">
                                <MainHero
                                    params={params}
                                    title={item.title}
                                    text={item.text}
                                    certificatesText={item.certificatesText}
                                    headerImage={item.headerImage}
                                    beukerImage={item.beukerImage}
                                    disableBeuker={item.disableBeuker}
                                    convertTitleToLink={item.convertTitleToLink ?? false}
                                    buttons={item.buttons}
                                />
                            </section>
                        ) : (
                            sharedComponents[item.componentKey!] ? (
                                item.componentProps && item.componentProps.children && (typeof item.componentProps.children === 'object' && !Array.isArray(item.componentProps.children)) ? (
                                    React.createElement(sharedComponents[item.componentKey!], {
                                        ...item.componentProps,
                                        params: params,
                                    }, React.createElement(sharedComponents[item.componentProps.children.componentKey!], {
                                        ...item.componentProps.children.componentProps,
                                        params: params
                                    }))
                                ) : item.componentProps && item.componentProps.children && Array.isArray(item.componentProps.children) ? (
                                    React.createElement(sharedComponents[item.componentKey!], {
                                        ...item.componentProps,
                                        params: params,
                                    }, item.componentProps.children.map((child: any, index: number) => (
                                        React.createElement(sharedComponents[child.componentKey!], {
                                            ...child.componentProps,
                                            params: params,
                                            key: index
                                        })
                                    )))
                                ) : (
                                    React.createElement(sharedComponents[item.componentKey!], {
                                        ...item.componentProps,
                                        params: params
                                    })
                                )
                            ) : (
                                <Container>
                                    <h1 className='text-red-600'>Component not supported yet</h1>
                                </Container>
                            )
                        )}
                    </React.Fragment>
                ) : (
                    <section key={index} className={`${old ? `${index === 0 ? 'bg-gradient-to-b from-green-600 to-transparent pb-8 pt-40 lg:pb-40' : 'py-16'}` : 'py-16 bg-white'}`}>
                        {item.type === 'hero' && (
                            <Hero
                                params={params}
                                title={item.title}
                                subTitle={item.subTitle}
                                text={item.text ? item.text : ''}
                                buttons={item.buttons}
                            />
                        )}

                        {item.type === 'component' && (
                            <>
                                {sharedComponents[item.componentKey!] ? (
                                    <>
                                        {React.createElement(sharedComponents[item.componentKey!], {
                                            ...item.componentProps,
                                            params: params
                                        })}

                                        {item.buttons &&
                                            <Container>
                                                <div className="flex flex-col md:flex-row items-start gap-4 mt-16 mx-auto">
                                                    {item.buttons.map((button: Button, index: number) => (
                                                        <>
                                                            {old ? (
                                                                <ButtonOne
                                                                    key={index}
                                                                    variant={button.variant}
                                                                    text={button.text}
                                                                    link={button.link}
                                                                    hoverEffect={true}
                                                                    className='mx-0'
                                                                />
                                                            ) : (
                                                                <ButtonNew
                                                                    key={index}
                                                                    text={button.text}
                                                                    link={button.link}
                                                                />
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </Container>
                                        }
                                    </>
                                ) : (
                                    <Container>
                                        <h1 className='text-red-600'>Component not supported yet</h1>
                                    </Container>
                                )}
                            </>
                        )}
                    </section>
                    // </section>
                )
			))}
        </>
    );
}