import { createLocalizedPathnamesNavigation, Pathnames } from 'next-intl/navigation';
import { ExtendedPathnames } from '@/lib/navigationFunctions';

import { developmentPaths } from '@/lib/constants/development';
import { marketingTransformationPaths } from '@/lib/constants/marketing-transformation';
import { articlePaths } from '@/lib/constants/articles';
import { vacancyPaths } from '@/lib/constants/vacancies';
import { conceptPaths } from '@/lib/constants/concepts';
import { capitalize } from '@/lib/utils';
import { dynamicWebsiteLocationsPathnames, dynamicWebsiteTypesLocationsPathnames, dynamicWebsiteTypesPathnames } from '@/lib/constants/creation';
import { dynamicWebappLocationsPathnames, dynamicWebappTypesLocationsPathnames, dynamicWebappTypesPathnames } from '@/lib/constants/creation';

export const locales = ['en', 'nl'] as const;
export type Locales = "en" | "nl";
export const localePrefix = 'as-needed';
// export const localePrefix = 'always';
export const defaultLocale = 'nl';

const dynamicSolutionsPathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of developmentPaths) {
    const newPage = {
        ...page.link,
        name: page.name,
        parentKey: "/development",
        breadcrumbParentKey: "/development",
        includeInMenu: false,
    }
    dynamicSolutionsPathnames[`${page.link.en}`] = newPage
}

const dynamicMarketingTransformationsPathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of marketingTransformationPaths) {
    const newPage = {
        ...page.link,
        name: page.name,
        parentKey: "/marketing-transformation",
        breadcrumbParentKey: "/marketing-transformation",
        includeInMenu: false,
    }
    dynamicMarketingTransformationsPathnames[`${page.link.en}`] = newPage
}

const dynamicArticlePathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of articlePaths) {
    const newPage = {
        ...page.link,
        name: page.name,
        parentKey: "/articles",
        breadcrumbParentKey: "/articles",
    }
    dynamicArticlePathnames[`${page.link.en}`] = newPage
}

export const dynamicConceptPathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of conceptPaths) {
    const newPage = {
        ...page.link,
        name: capitalize(page.name),
        parentKey: "/jargon",
        breadcrumbParentKey: "/jargon",
    }
    dynamicConceptPathnames[`${page.link.en}`] = newPage
}

const dynamicVacancyPathnames = {} as ExtendedPathnames<typeof locales>;
for (const page of vacancyPaths) {
    const newPage = {
        ...page.link,
        name: page.name,
        parentKey: "/vacancies",
        breadcrumbParentKey: "/about#vacancies",
    }
    dynamicVacancyPathnames[`${page.link.en}`] = newPage
}






export const loopExtendedPathnames = (): ExtendedPathnames<typeof locales> => {
    return {
        "/": {
            en: "/",
            nl: "/",
            name: "home",
            includeInMenu: true,
        },
        "/algemene-voorwaarden-webbeukers": {
            nl: "/algemene-voorwaarden-webbeukers",
            en: "/terms-and-conditions-webbeukers",
            name: "terms_and_conditions_webbeukers",
            showInMenu: false,
        },
        "/documents/Algemene-voorwaarden-Webbeukers.pdf": {
            nl: "/documents/Algemene-voorwaarden-Webbeukers.pdf",
            en: "/documents/Algemene-voorwaarden-Webbeukers.pdf",
            name: "terms_and_conditions_webbeukers",
            showInMenu: false,
        },
        "/cookie-policy": {
            nl: "/cookie-statement",
            en: "/cookie-policy",
            name: "cookie_policy",
            showInMenu: false,
        },
        "/privacy-policy": {
            nl: "/privacy-beleid",
            en: "/privacy-policy",
            name: "privacy_policy",
            showInMenu: false
        },
        "/local-offer": {
            nl: "/lokale-aanbieding",
            en: "/local-offer",
            name: "local_offer",
            showInMenu: false
        },
        // '/websites': {
        //     en: '/websites',
        //     nl: '/websites',
        //     name: 'websites',
        //     showInMenu: true,
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/",
        // },
        '/website-creation': {
            en: '/website-creation',
            nl: '/website-laten-maken',
            name: 'websites',
            showInMenu: true,
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/website-creation#price': {
            en: '/website-creation#price',
            nl: '/website-laten-maken#price',
            name: 'websites',
            parentKey: "/",
            includeInMenu: true,
            breadcrumbParentKey: "/",
        },
        '/website-creation#faq': {
            en: '/website-creation#faq',
            nl: '/website-laten-maken#faq',
            name: 'websites',
            parentKey: "/",
            includeInMenu: true,
            breadcrumbParentKey: "/",
        },
        '/articles': {
            en: '/articles',
            nl: '/artikelen',
            name: 'articles',
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/cases': {
            en: '/cases',
            nl: '/projecten',
            name: 'website_cases',
            // parentKey: "/website-creation",
            includeInMenu: true,
            breadcrumbParentKey: "/",
        },
        '/about#approach': {
            en: '/about#approach',
            nl: '/over#approach',
            name: 'our_approach',
            parentKey: "/website-creation",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/development': {
            en: '/development',
            nl: '/ontwikkeling',
            name: 'development',
            showInMenu: true,
            parentKey: "/website-creation",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/optimizations': {
            en: '/optimizations',
            nl: '/optimalisaties',
            name: 'optimizations',
            showInMenu: true,
            parentKey: "/website-creation",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/maintenance': {
            en: '/maintenance',
            nl: '/onderhoud',
            name: 'maintenance',
            showInMenu: true,
            parentKey: "/website-creation",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        // 'price-indications': {
        //     en: '/price-indications',
        //     nl: '/prijsindicaties',
        //     name: 'price_indications',
        //     parentKey: "/website-creation",
        //     includeInMenu: true,
        //     breadcrumbParentKey: "/",
        // },
        '/jargon': {
            en: '/jargon',
            nl: '/vaktaal',
            name: 'concept',
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },

        '/website-creation#reviews': {
            en: '/website-creation#reviews',
            nl: '/website-creation#reviews',
            name: 'customer_reviews',
            parentKey: "/",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/marketing-transformation': {
            en: '/marketing-transformation',
            nl: '/marketing-transformatie',
            name: 'marketing',
            showInMenu: true,
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        "/about": {
            en: "/about",
            nl: "/over",
            name: "about_us",
            showInMenu: true,
            includeInMenu: true,
            breadcrumbParentKey: "/",
        },
        '/about#team': {
            en: '/about#team',
            nl: '/over#team',
            name: 'the_team',
            parentKey: "/about",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/about#vacancies': {
            en: '/about#vacancies',
            nl: '/over#vacancies',
            name: 'vacancies',
            parentKey: "/about",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/what-does-a-website-cost': {
            en: '/what-does-a-website-cost',
            nl: '/wat-kost-een-website',
            name: 'what_does_a_website_cost',
            parentKey: "/website-creation",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/contact': {
            en: '/contact',
            nl: '/contact',
            name: 'contact',
            showInMenu: true,
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        // '/mkb-website-creation': {
        //     en: '/mkb-website-creation',
        //     nl: '/mkb-website-laten-maken',
        //     name: 'mkb',
        //     showInMenu: false,
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/website-creation",
        // },
        '/corporate-website-creation': {
            en: '/corporate-website-creation',
            nl: '/zakelijke-website-laten-maken',
            name: 'corporate',
            showInMenu: false,
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        '/public-settings-website-creation': {
            en: '/public-settings-website-creation',
            nl: '/publieke-instellingen-website-laten-maken',
            name: 'public_settings',
            showInMenu: false,
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        // '/mkb-website-creation/company-website': {
        //     en: '/mkb-website-creation/company-website',
        //     nl: '/mkb-website-laten-maken/bedrijfswebsite',
        //     name: 'company_website',
        //     parentKey: "/mkb-website-creation",
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/mkb-website-creation",
        // },
        // '/mkb-website-creation/landingspages': {
        //     en: '/mkb-website-creation/landingspages',
        //     nl: '/mkb-website-laten-maken/landingspagina',
        //     name: 'landingpages',
        //     parentKey: "/mkb-website-creation",
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/mkb-website-creation",
        // },
        // '/mkb-website-creation/webshop': {
        //     en: '/mkb-website-creation/webshop',
        //     nl: '/mkb-website-laten-maken/webshop',
        //     name: 'webshop',
        //     parentKey: "/mkb-website-creation",
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/mkb-website-creation",
        // },
        // '/mkb-website-creation/blog': {
        //     en: '/mkb-website-creation/blog',
        //     nl: '/mkb-website-laten-maken/blog',
        //     name: 'blog',
        //     parentKey: "/mkb-website-creation",
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/mkb-website-creation",
        // },
        // '/mkb-website-creation/community': {
        //     en: '/mkb-website-creation/community',
        //     nl: '/mkb-website-laten-maken/community',
        //     name: 'community',
        //     parentKey: "/mkb-website-creation",
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/mkb-website-creation",
        // },
        // '/mkb-website-creation/portfolio': {
        //     en: '/mkb-website-creation/portfolio',
        //     nl: '/mkb-website-laten-maken/portfolio',
        //     name: 'portfolio',
        //     parentKey: "/mkb-website-creation",
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/mkb-website-creation",
        // },
        // '/test': {
        //     en: '/test',
        //     nl: '/test',
        //     name: 'test',
        // },

        '/development-variants': {
            en: '/development-variants',
            nl: '/ontwikkel-varianten',
            name: 'development_variants',
            parentKey: "/website-creation",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        // '/website-creation': {
        //     en: '/website-creation',
        //     nl: '/website-laten-maken',
        //     name: 'have_a_website_created',
        //     parentKey: "/website-creation",
        //     includeInMenu: false,
        //     breadcrumbParentKey: "/",
        // },
        ...dynamicSolutionsPathnames,
        '/web-app-creation': {
            en: '/web-app-creation',
            nl: '/web-app-laten-maken',
            name: 'have_a_webapp_created',
            parentKey: "/development",
            includeInMenu: false,
            breadcrumbParentKey: "/",
        },
        // '/platform-creation': {
        //     en: '/platform-creation',
        //     nl: '/platform-laten-maken',
        //     name: 'have_a_platform_created',
        //     parentKey: "/",
        //     breadcrumbParentKey: "/",
        // },
        // '/portal-creation': {
        //     en: '/portal-creation',
        //     nl: '/portaal-laten-maken',
        //     name: 'have_a_portal_created',
        //     parentKey: "/",
        //     breadcrumbParentKey: "/",
        // },
        // '/plugin-creation': {
        //     en: '/plugin-creation',
        //     nl: '/plugin-laten-maken',
        //     name: 'have_a_plugin_created',
        //     parentKey: "/",
        //     breadcrumbParentKey: "/",
        // },

        ...dynamicWebsiteTypesPathnames,
        ...dynamicWebsiteLocationsPathnames,
        ...dynamicWebsiteTypesLocationsPathnames,

        ...dynamicWebappLocationsPathnames,
        ...dynamicWebappTypesLocationsPathnames,
        ...dynamicWebappTypesPathnames,

        // ...dynamicPlatformLocationsPathnames,
        // ...dynamicPlatformTypesLocationsPathnames,
        // ...dynamicPlatformTypesPathnames,

        // ...dynamicPortalLocationsPathnames,
        // ...dynamicPortalTypesLocationsPathnames,
        // ...dynamicPortalTypesPathnames,

        // ...dynamicPluginLocationsPathnames,
        // ...dynamicPluginTypesLocationsPathnames,
        // ...dynamicPluginTypesPathnames,


        ...dynamicMarketingTransformationsPathnames,
        ...dynamicArticlePathnames,
        ...dynamicConceptPathnames,
        ...dynamicVacancyPathnames,
    }
};
export const extendedPathnames = loopExtendedPathnames();

export const extendedPathnamesArray = Object.keys(extendedPathnames).map(key => {
    return {
        key,
        ...extendedPathnames[key]
    }
});


export const menuExtendedPathnames = Object.keys(extendedPathnames).reduce((acc, key) => {
    if (extendedPathnames[key].includeInMenu) {
        acc[key] = extendedPathnames[key];
    }
    return acc;
}, {} as ExtendedPathnames<typeof locales>);

const mapBackToPathnames = (pathnames: ExtendedPathnames<typeof locales>) => {    
    return Object.keys(pathnames).reduce((acc, key) => {
        acc[key] = {
            en: pathnames[key].en,
            nl: pathnames[key].nl
        }
        return acc;
    }, {} as Pathnames<typeof locales>);
}

export const pathnames = mapBackToPathnames(extendedPathnames);

export const {Link, redirect, usePathname, useRouter, getPathname} = createLocalizedPathnamesNavigation({locales, localePrefix, pathnames});