import { Locales, route } from "@/lib/navigationFunctions";

import { getTranslations } from "next-intl/server";
import { Path, VacanciesValues, VacancyPage, VacancyPath } from "@/lib/types/shared/page.types";

export const VacancyBasePath: Path  = {
    en: "/vacancies",
    nl: "/vacatures"
}

export const vacancyPaths: VacancyPath[] = [
    {
        id: 'sales-consultant',
        name: 'sales_consultant',
        link: {
            en: `${VacancyBasePath.en}/sales-consultant`,
            nl: `${VacancyBasePath.nl}/sales-consultant`,
        },
    },
]; 

const getVacancyConstants = async (locale: Locales | 'nl') => {
    let t = (variable: string) => {
        return variable;
    }

    if (locale) {
        t = await getTranslations({ locale: locale });
    }

    const vacancies: VacanciesValues[] = [
        {
            id: "sales-consultant",
            title: t("sales_consultant"),
            image: "TeamMembers/null_rnkkvz",
            role: "Medior",
        },
    ];
    for (const vacancy of vacancies) {
        const path = vacancyPaths.find(path => path.id === vacancy.id);
        if (path) {
            vacancy.link = path.link.en;
        }
    }

    const vacancyPages: VacancyPage[] = [
        {
            id: 'sales-consultant',
            title: t('sales_consultant'),
            description: t('as_a_sales_consultant_i_act_as_the_bridge_between_your_company_and_customers_i_am_committed_to_identifying_new_business_opportunities_and_successfully_managing_and_delivering_projects_according_to_client_requirements'),
            keywords: `${t('sales_consultant')}, ${t('leads')}, ${t('assignments')}, ${t('webbeukers')}, ${t('business_opportunities')}`,
            pageLayout: [
                {
                    type: "component-section",
                    subType: "main-hero",
                    title: t('pages.vacancies.sales_consultant.hero.title'),
                    text: t('pages.vacancies.sales_consultant.hero.text'),
                    certificatesText: t('pages.vacancies.sales_consultant.hero.certificates'),
                    // headerImage: "",
                    // beukerImage: "",
                    disableBeuker: true,
                    buttons: [
						{
							text: t("pages.vacancies.sales_consultant.hero.buttons.0"),
							link: route("/contact", locale),
						},
						{
							text: t("pages.vacancies.sales_consultant.hero.buttons.1"),
							link: route("/contact", locale),
						}
					]
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("what_does_the_sales_consultant_role_entail"),
                        text: t("as_a_sales_consultant_you_are_the_bridge_between_our_company_and_our_customers_you_are_responsible_for_identifying_new_business_opportunities_converting_cold_leads_into_assignments_and_managing_and_successfully_delivering_these_assignments_according_to_the_customers_wishes"),
                        image: locale === "nl" ? "TeamMembers/search-for-beuker-avatar_s17psm" : "TeamMembers/search-for-beuker-avatar-en_wdqt2u",
                        alt: t("presentation_avatar_svg"),
                        imagePosition: "right",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full",
                        theme: "dark"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("what_do_we_offer"),
                        ul: [
                            {
                                text: t("a_unique_role_in_which_you_can_use_both_your_sales_and_management_skills_collaboration_with_a_driven_and_passionate_team_the_opportunity_to_have_a_direct_impact_on_our_growth_and_customer_satisfaction_flexible_working_hours_for_a_healthy_worklife_balance_a_competitive_salary_with_bonus_structure_travel_allowance_225_vacation_days_and_8_holiday_pay_ongoing_opportunities_for_personal_and_professional_growth"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("what_do_you_bring"),
                        ul: [
                            {
                                text: t("demonstrated_experience_in_sales_and_generating_new_business_opportunities_excellent_communication_skills_and_the_ability_to_build_and_maintain_customer_relationships_experience_with_project_management_including_planning_budgeting_and_execution_strong_problemsolving_skills_and_the_ability_to_set_priorities_a_proactive_mindset_and_the_ability_to_work_independently_as_well_as_in_a_team"),
                                icon: "Icons/plus_kefs9b",
                                alt: t("plus_icon")
                            },
                        ],
                        theme: "dark",
                        className: "bg-white"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSectionNew",
                    componentProps: {
                        title: null,
                        text: null
                    }
                },
            ]
        },
    ];
    for (const page of vacancyPages) {
        const path = vacancyPaths.find(path => path.id === page.id);
        if (path) {
            page.link = path.link;
            page.title = page.title;
        }
    }

    return {
        vacancyPages,
        vacancies,
    };
}

export default getVacancyConstants;