'use client';

import React, { useState } from "react";

import { useTranslations } from 'next-intl';
import { useFormik } from 'formik';
import { sendEmail } from "@/lib/api/EmailApi";
import { submitMailchimp } from "@/lib/api/MailchimpApi";
import { ContactFormErrors, ContactFormValues, MailchimpFormErrors, MailchimpFormValues } from "@/lib/types/shared/page.types";
import { emailTemplateConfigContact } from "@/lib/constants/mailTemplates/index";
import { MoveFadeInAnimation } from "@/lib/hooks/use-animations";

import { Input, Typography, TextareaAutosize } from "@mui/material";
import ErrorMessage from "@/components/shared/forms/ErrorMessage";
import NPM_ButtonOne from "@/components/shared/_NPM_ButtonOne";
import ButtonOne from "@/components/shared/ButtonOne";
import Image from "@/components/shared/Image";

import HeadingText from "@/components/shared/HeadingText";

const ValidateContactForm = (values: ContactFormValues) => {
    const errors: ContactFormErrors = {};
    
    // Company Validation
    if (!values.company) {
        errors.company = 'required';
    } else if (values.company.length < 2) {
        errors.company = 'company_must_be_at_least_2_characters';
    } else {
        if (errors.company) delete errors.company;
    }
    
    // Name Validation
    if (!values.name) {
        errors.name = 'required';
    } else if (values.name.length < 2) {
        errors.name = 'name_must_be_at_least_2_characters';
    } else {
        if (errors.name) delete errors.name;
    }

    // Phone Validation
    if (!values.phone) {
        errors.phone = 'required';
    } else if (values.phone.length < 10) {
        errors.phone = 'phone_must_be_at_least_10_characters';
    } else {
        if (errors.phone) delete errors.phone;
    }

    // Email Validation
    if (!values.email) {
        errors.email = 'required';
    } else if (!/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i.test(values.email)) {
        errors.email = 'invalid_email_address';
    } else {
        if (errors.email) delete errors.email;
    }

    // Message Validation
    if (!values.message) {
        errors.message = 'required';
    } else if (values.message.length < 10) {
        errors.message = 'message_must_be_at_least_10_characters';
    } else {
        if (errors.message) delete errors.message;
    }

    return errors;
}

const ValidateMailchimpForm = (values: MailchimpFormValues) => {
    const errors: MailchimpFormErrors = {};

    if (!values.email) {
        errors.email = 'required';
    } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
        errors.email = 'invalid_email_address';
    }

    return errors;
}

const pushConversion = () => {
    if ((window as any).gtag !== undefined) {
        const callback = () => {
            console.log('Conversion callback');
        }

        (window as any).gtag('event', 'SuccessfullContactSubmit', {
            send_to: 'G-MQ0CBKR7NL',
            event_callback: callback
        });
    }
}

type Props = {
    title?: string,
    text?: string,
    onButtonClicked?: (buttonId: string) => void,
}

const CombinedContactForm = ({ title, text, onButtonClicked = (buttonId: string) => {} }: Props) => {
    const t = useTranslations();

    const [contactFormSubmitting, setContactFormSubmitting] = useState(false);
    const [contactFormSubmitted, setContactFormSubmitted] = useState(false);
    const [contactFormHasErrors, setContactFormHasErrors] = useState(false);
    const [contactFormErrorMessage, setContactFormErrorMessage] = useState('');
    
    const [mailchimpFormSubmitting, setMailchimpFormSubmitting] = useState(false);
    const [mailchimpFormSubmitted, setMailchimpFormSubmitted] = useState(false);
    const [mailchimpFormHasErrors, setMailchimpFormHasErrors] = useState(false);
    const [mailchimpFormErrorMessage, setMailchimpFormErrorMessage] = useState('');

    const contactFormik = useFormik({
        initialValues: {
            company: '',
            name: '',
            phone: '',
            email: '',
            message: '',
        },
          
        validate: ValidateContactForm,
        
        onSubmit: async (values) => {
            if (onButtonClicked) {
                onButtonClicked('combinedContactFormSubmit');
            }

            setContactFormSubmitting(true);

            // setTimeout(() => {
            //     setContactFormSubmitting(false);
            //     setContactFormSubmitted(true);
            //     pushConversion();
            // }, 4000);

            const emailTemplateConfig = emailTemplateConfigContact(values.name, values.email, values.phone, values.message);
            sendEmail(emailTemplateConfig.subject, emailTemplateConfig.html).then((response: any) => {
                setContactFormSubmitting(false);
                setContactFormSubmitted(true);
                pushConversion();
            }).catch((error: any) => {
                console.error('error', error);
                setContactFormHasErrors(true);
                setContactFormErrorMessage(t('something_went_wrong_sending_the_message_please_try_again_later'));
                setContactFormSubmitting(false);
            });
        }
    });

    const mailchimpFormik = useFormik({
        initialValues: {
            email: '',
        },
          
        validate: ValidateMailchimpForm,
        
        onSubmit: async (values) => {
            setMailchimpFormSubmitting(true);

            submitMailchimp(mailchimpValues.email, contactValues.name, contactValues.phone).then(() => {
                mailchimpFormik.resetForm();
                setMailchimpFormSubmitting(false);
                setMailchimpFormSubmitted(true);
            }).catch((error: any) => {
                console.error('error', error);
                setMailchimpFormHasErrors(true);
                setMailchimpFormErrorMessage(t('Something went wrong subscribing the newsletter, please try again later.'));
                setMailchimpFormSubmitting(false);
            });
        }
    });

    type Event = {
        target: {
            name: string;
            value: string;
        };
    };

    const handleContactFormChange = (e: Event) => {
        contactFormik.handleChange(e);
        setContactFormHasErrors(false);
    }

    const handleContactFormBlur = (e: Event) => {
        contactFormik.handleBlur(e);
    }

    const handleMailchimpFormChange = (e: Event) => {
        mailchimpFormik.handleChange(e);
        setMailchimpFormHasErrors(false);
    }

    const handleMailchimpFormBlur = (e: Event) => {
        mailchimpFormik.handleBlur(e);
    }

    const contactErrors = contactFormik.errors;
    const contactTouched = contactFormik.touched;
    const contactValues = contactFormik.values;

    const mailchimpErrors = mailchimpFormik.errors;
    const mailchimpTouched = mailchimpFormik.touched;
    const mailchimpValues = mailchimpFormik.values;


    const GetForm = () => {
        return (
            <form className='flex flex-col w-full gap-2' onSubmit={contactFormik.handleSubmit}>
                <div className="flex gap-2">
                    <div className="field flex-grow">
                        <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                            <Input className='w-full rounded-xl text-primary-gray-950 px-4 py-1' type="text" name="company" placeholder={t('company')} disableUnderline={true} onChange={handleContactFormChange} onBlur={handleContactFormBlur} value={contactValues.company} required  />
                        </div>
                        <ErrorMessage>{contactErrors.company && contactTouched.company && t(contactErrors.company)}</ErrorMessage>
                    </div>
                    <div className="field flex-grow">
                        <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                            <Input className='w-full rounded-xl text-primary-gray-950 px-4 py-1' type="text" name="name" placeholder={t('name')} disableUnderline={true} onChange={handleContactFormChange} onBlur={handleContactFormBlur} value={contactValues.name} required  />
                        </div>
                        <ErrorMessage>{contactErrors.name && contactTouched.name && t(contactErrors.name)}</ErrorMessage>
                    </div>
                </div>
                
                <div className="flex gap-2">
                    <div className="field flex-grow">
                        <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                            <Input className='w-full rounded-xl text-primary-gray-950 px-4 py-1' type="text" name="phone" placeholder={t('phone')} disableUnderline={true} onChange={handleContactFormChange} onBlur={handleContactFormBlur} value={contactValues.phone} required  />
                        </div>
                        <ErrorMessage>{contactErrors.phone && contactTouched.phone && t(contactErrors.phone)}</ErrorMessage>
                    </div>
    
                    <div className="field flex-grow">
                        <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                            <Input className='w-full rounded-xl text-primary-gray-950 px-4 py-1' type="email" name="email" placeholder={t('email')} disableUnderline={true} onChange={(event) => { handleContactFormChange(event); handleMailchimpFormChange(event); }} onBlur={handleContactFormBlur} value={contactValues.email} required  />
                        </div>
                        <ErrorMessage>{contactErrors.email && contactTouched.email && t(contactErrors.email)}</ErrorMessage>
                    </div>
                </div>
    
                <div className="field">
                    <div className="transition rounded-xl">
                        <TextareaAutosize className='w-full block rounded-xl px-4 py-2 textwrap border-none' name="message" placeholder={t('what_would_you_like_to_discuss')} onChange={handleContactFormChange} onBlur={handleContactFormBlur} minRows={2} value={contactValues.message} required  />
                    </div>
                    <ErrorMessage>{contactErrors.message && contactTouched.message && t(contactErrors.message)}</ErrorMessage>
                </div>
            
                <div className="flex justify-start">
                    <MoveFadeInAnimation delay={0} direction="right" distance="xl" amount={0.5}>
                        <ButtonOne variant="blakka" text={contactFormSubmitting ? t('sending') : t('send')} type="button" disabled={contactFormSubmitting} hoverEffect={true} className="ml-0" />
                    </MoveFadeInAnimation>
                </div>
            </form>
        );
    }
    
    const GetMailchimpForm = () => {
        return (
            <form className='flex flex-col w-full gap-2' onSubmit={mailchimpFormik.handleSubmit}>
                <div className="field flex-grow">
                    <div className="transition rounded-xl bg-white mt-5">
                        <Input className='w-full rounded-xl text-primary-gray-950 px-4 py-1' type="email" name="email" placeholder={t('email')} disableUnderline={true} onChange={handleMailchimpFormChange} onBlur={handleMailchimpFormBlur} value={mailchimpValues.email} required  />
                    </div>
                    <ErrorMessage>{mailchimpErrors.email && mailchimpTouched.email && t(mailchimpErrors.email)}</ErrorMessage>
                </div>
                <div className="flex justify-start">
                    <MoveFadeInAnimation delay={0} direction="right" distance="xl" amount={0.5}>
                        <ButtonOne variant="blakka" text={mailchimpFormSubmitting ? t('sending') : t('send')} type="button" disabled={mailchimpFormSubmitting} hoverEffect={true} className="mr-0" />
                    </MoveFadeInAnimation>
                </div>
            </form>
        );
    }

    const SubscribedToNewsletter = () => {
        return (
            <div className='grid grid-cols-1 md:grid-cols-2'>
                <Image
                    loading="lazy"
                    width={231}
                    height={462}
                    src="TeamMembers/avatar-heart_rteqiq"
                    alt="Afbeelding"
                    className='w-1/3 md:w-7/20 h-auto max-h-9/10 my-auto md:m-auto object-contain'
                />
                <div className="p-6">
                    <Typography className="font-bold capitalize leading-10 text-start mb-3" variant="h3">
                        {t("thanks")}!
                    </Typography>
                    <Typography className="normal-case text-start" variant="h6">
                        {t("thank_you_for_subscribing_to_our_newsletter")}
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        !contactFormSubmitted ? (
            <div className='grid grid-cols-1 md:grid-cols-2 combined-contact-form'>
                <Image
                    loading="lazy"
                    src="TeamMembers/stephan-calling_qtebus"
                    alt='Stephan Striekwold'
                    width={549}
                    height={523}
                    className='w-full h-full object-cover hidden md:block'
                />
                <div className="p-6">
                    {title ? (
                        <HeadingText variant="h3" className="text-start mb-3 text-primary-gray-950"> {title} </HeadingText>
                    ) : (
                        <HeadingText variant="h3" className="text-start mb-3 text-primary-gray-950"> {t("discuss_possibilities")} </HeadingText>
                    )}

                    {text ? (
                        <Typography className='text-start mb-4 text-primary-gray-950' variant='body1'>
                            {text}
                        </Typography>
                    ) : (
                        <Typography className='text-start mb-4 max-w-350px text-primary-gray-950' variant='body1'>
                            {t("leave_your_details_and_we_will_contact_you")}
                        </Typography>
                    )}

                    {contactFormHasErrors ? (
                        <>
                            <HeadingText variant="h5" className="text-start my-3 text-primary-gray-950">{contactFormErrorMessage}</HeadingText>
                            <div className='w-fit'>
                                <ButtonOne text={t("refresh")} variant="blakka" onclick={() => window.location.reload()} hoverEffect={true} />
                            </div>
                        </>
                    ) : (
                        GetForm()
                    )}
                </div>
            </div>
        ) : (
            !mailchimpFormSubmitted ? (
                <div className='grid grid-cols-1 md:grid-cols-2 combined-contact-form'>
                    <Image
                        loading="lazy"
                        width={238}
                        height={400}
                        src="TeamMembers/avatar-mail_j7ai7m"
                        alt="Afbeelding"
                        className='w-1/3 md:w-7/20 h-auto max-h-9/10 my-auto md:m-auto object-contain'
                    />
                    <div className="p-6">
                        <HeadingText variant="h3" className="text-center lg:text-start text-primary-gray-950">
                            {`${t("thanks")} ${contactValues.name}!`}
                        </HeadingText>
                        <HeadingText variant="h6" className="text-center lg:text-start text-primary-gray-950">
                            {t("we_aim_to_answer_your_message_within_1_working_day")}
                        </HeadingText>
                        <HeadingText variant="h6" className="text-center lg:text-start mt-10 text-primary-gray-950">
                            {t("would_you_like_to_stay_informed_of_the_latest_news_then_sign_up_for_our_newsletter")}
                        </HeadingText>

                        {mailchimpFormHasErrors ? (
                            <>
                                <HeadingText variant="h5" className="text-start my-3 text-primary-gray-950">{mailchimpFormErrorMessage}</HeadingText>
                                <div className='w-fit'>
                                    <ButtonOne text={t("refresh")} variant="blakka" onclick={() => window.location.reload()} hoverEffect={true} />
                                </div>
                            </>
                        ) : (
                            GetMailchimpForm()
                        )}
                    </div>
                </div>
            ) : (
                SubscribedToNewsletter()
            )
        )
    );
}

export default CombinedContactForm;