import React from 'react';

import { useTranslations } from 'next-intl';

import { DefaultPageProps } from '@/lib/types/shared/page.types';

import { Container } from '@mui/material';

import { ExpandableAnimatedBlocks, GoogleReviews } from '@/components/shared';

type Props = {
    id?: string;
    addReviews?: boolean;
    reviewsOrder?: 'before' | 'after';
} & DefaultPageProps

export default function ProcessSection({ id = '', addReviews = false, reviewsOrder = 'before', params }: Props) {
    const t_shared = useTranslations('shared');

    return (
        <section id={id} className="py-16 bg-white">
            <Container>
                {addReviews && reviewsOrder === 'before' && <GoogleReviews params={params} />}

                <ExpandableAnimatedBlocks
                    items={[
                        {
                            title: t_shared('process_section.items.0.title'),
                            text: t_shared('process_section.items.0.text'),
                            image: {
                                src: 'Process_Background_figsow',
                                alt: 'Step 1: Think',
                                width: 927,
                                height: 193,
                            },
                            label: t_shared('process_section.items.0.label')
                        },
                        {
                            title: t_shared('process_section.items.1.title'),
                            text: t_shared('process_section.items.1.text'),
                            image: {
                                src: 'Process_Background_figsow',
                                alt: 'Step 2: Create',
                                width: 927,
                                height: 193,
                            },
                            label: t_shared('process_section.items.1.label')
                        },
                        {
                            title: t_shared('process_section.items.2.title'),
                            text: t_shared('process_section.items.2.text'),
                            image: {
                                src: 'Process_Background_figsow',
                                alt: 'Step 3: Execute',
                                width: 927,
                                height: 193,
                            },
                            label: t_shared('process_section.items.2.label')
                        },
                    ]}
                    className={addReviews && reviewsOrder === 'before' ? 'mt-4' : ''}
                    params={params}
                />

                {addReviews && reviewsOrder === 'after' && <GoogleReviews params={params} />}
            </Container>
        </section>
    );
}
