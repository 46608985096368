// import client from "@/lib/api/ApiClient";
import { EmailProps } from "./NodeEmailApi";

interface AttachmentObject {
    name: string;
    content: string | ArrayBuffer | null;
}

export const sendEmail = (Subject: string, Body: string, Attachment: AttachmentObject | null = null) => {
    // return client.post("/email/send", { Subject, Body, Attachment }, { headers: { 'wb-email-token': process.env.NEXT_API_HEADER_TOKEN ?? '' } });

    return new Promise((resolve, reject) => {
        const emailConfig: EmailProps = {
            to: `stephan@webbeukers.nl`,
            bcc: [],
            subject: Subject,
            html: Body
        };

        fetch('/api/mailer', {
            method: 'POST',
            headers: {
                'Custom-WB-Token': process.env.NEXT_APP_API_BEARER ?? '',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emailConfig)
        }).then(data => {
            resolve(true);
        }).catch(error => {
            console.error(error);
            reject(false);
        });
    });
}

const emailApi = {
    sendEmail
};

export default emailApi;