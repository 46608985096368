import { route } from "@/lib/navigationFunctions";
import { Themes } from "@/lib/types/shared/page.types";
import React from "react";

import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { BounceAnimation } from "@/lib/hooks/use-animations";

import Link from "next/link";

import { ComponentProps, Params } from "@/lib/types/shared/page.types";
import Image from "@/components/shared/Image";

type Props = {
    name: string;
    image: string;
    role: string;
    itemnr: number;
    link?: string;
    theme?: Themes;
    params: Params;
} & ComponentProps;

const TeamCard = ({ name, titleVariant, role, image, itemnr, link = "", theme = "dark", params }: Props) => {
    return (
        <>
            <Card className={`team-card transition rounded-xl overflow-visible items-center mx-8  p-1 animating-border ${theme === "light" ? "bg-transparent border border-white" : (theme === "transparent") ? "bg-transparent border-none shadow-none" : "custom-gradient-border"}`}>
                <div className={`relative flex flex-col aspect-square h-full w-full text-white rounded-xl justify-end items-center ${theme === "light" ? "" : (theme === "transparent") ? "bg-transparent" : "bg-primary-gray-950"}`}>
                    {image !== undefined && image !== null && image !== '' &&
                        <BounceAnimation delay={itemnr * 50} extraclasses="team-card-img-wrapper absolute bottom-0 flex items-end">
                            <Image
                                src={image}
                                loading='lazy'
                                alt={name}
                                width={279}
                                height={279}
                                className="w-full h-auto"
                            />
                        </BounceAnimation>
                    }
                    <CardContent className="w-full card-title-box flex items-end">
                        <Typography variant={titleVariant} className="text-center text-xl lg:text-2xl backdrop-blur-xl font-bold bg-white bg-opacity-10 rounded-xl -mx-8 p-3 flex-1 normal-case card-title">
                            {name}
                        </Typography>
                    </CardContent>
                </div>
            </Card>
            
            {link !== "" && link !== "#" ? (
                <Link href={route(`${link ?? ''}`, params.locale)} className={`link-element px-6 text-center text-white text-lg font-bold m-auto ${theme === "transparent" ? "bg-transparent" : "bg-primary-gray-950"} rounded-full border-none disabled:opacity-10 inline-flex uppercase -mt-3 mb-16`}>
                    <Typography className="bg-white-accent-50 text-center text-primary-gray-950 drop-shadow-md w-fit mx-auto px-8 py-2 rounded-xl capitalize">
                        {role}
                    </Typography>
                </Link>
            ) : (
                <Typography className="bg-white-accent-50 text-center text-primary-gray-950 drop-shadow-md w-fit mx-auto px-8 py-2 rounded-xl -mt-3 mb-16">
                    {role}
                </Typography>
            )}
        </>
    );
}

export default TeamCard;