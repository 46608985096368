'use client'
import React from 'react';

import { Container, Typography } from '@mui/material';
import { ComponentProps, DefaultPageProps } from '@/lib/types/shared/page.types';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import Image from '@/components/shared/Image';
import ButtonOne, { ButtonOneProps } from '@/components/shared/ButtonOne';
import HeadingText from '@/components/shared/HeadingText';
import { capitalize } from '@/lib/utils';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

type Props = {
    title: string,
    showRevies?: boolean,
    subTitle?: string,
    certificateImages?: boolean,
    buttons?: ButtonOneProps[],
    children?: React.ReactNode,
} & DefaultPageProps & ComponentProps;

export default function HeroNew({ title = '', showRevies = true, subTitle = '', className = '', buttons, children, params, certificateImages = false }: Props) {
    const t = useTranslations();
    return (
        <div className={`relative px-5 ${className}`}>
            <Container>
                <div className="hidden md:flex w-full absolute bottom-4 top-1/2 -translate-y-1/2 z-10 flex-col justify-center">
                    {showRevies &&
                        <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                            <Image
                                src="Icons/Rating-star_aybkcq"
                                alt="Google Reviews"
                                width={200}
                                height={97.45}
                                className="w-28 h-auto"
                            />
                            <Link target='_blank' href={'https://www.google.com/search?sa=X&sca_esv=7d935f78db81bbbe&rlz=1C5CHFA_enNL1023NL1026&tbm=lcl&sxsrf=AHTn8zqhvHN-kPVh-Bs0PsNXHI5OAG7t5w:1739277676950&q=Web+Beukers+Reviews&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2MTQ0NzEwMLUwt7AwMDc1MTYz38DI-IpRODw1ScEptTQ7tahYISi1LDO1vHgRKzZRAF8f_L1GAAAA&rldimm=4117400587880754367&hl=nl-NL&ved=2ahUKEwjIt-ag0ruLAxV1hP0HHaaWDYUQ9fQKegQITBAF&biw=1411&bih=958&dpr=1#lkt=LocalPoiReviews'} className='flex items-center gap-2 -mt-2 mb-2 w-fit'>{t('read_reviews')}</Link>
                        </MoveFadeInAnimation>
                    }
                    {title !== undefined && title !== null &&
                        <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                            <HeadingText variant='h1' enableMaxWidth={false} className='md:text-6xl lg:text-7xl md:leading-extra-tight'>
                                {capitalize(title)}
                            </HeadingText>
                        </MoveFadeInAnimation>
                    }
                    {subTitle !== undefined && subTitle !== null &&
                        <MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
                            <HeadingText variant='h2' className='md:text-4xl lg:text-5xl mt-3 lg:mt-1'>
                                {subTitle}
                            </HeadingText>
                        </MoveFadeInAnimation>
                    }
                    {buttons && (
                        <div className="flex gap-5 mt-5">
                            {buttons.map((button, index) => (
                                <MoveFadeInAnimation key={index} delay={index * 200} direction="right" distance="xl" amount={0.5}>
                                    {(button.onclick) ? (
                                        <ButtonOne variant={button.variant} text={button.text} type="button" onclick={button.onclick} hoverEffect={true} />
                                    ) : (
                                        <ButtonOne {...button} key={index} hoverEffect={true}/>
                                    )}
                                </MoveFadeInAnimation>
                            ))}
                        </div>
                    )}
                    {children && children}
                </div>
            </Container>

            <Container>
                <div className='relative'>
                    {/* <Image
                        width={1100}
                        height={619}
                        src={headerImage.src}
                        alt={headerImage.alt}
                        className='w-full h-72 sm:h-auto lg:aspect-16/7 object-cover rounded-3xl filter brightness-75 sm:filter-none hidden md:block'
                    /> */}
                    <div className='w-full h-72 lg:aspect-16/7 object-cover rounded-3xl filter brightness-75 sm:filter-none hidden md:block'/>

                    <div className="flex md:hidden flex-col h-full w-full justify-center">
                        {showRevies &&
                            <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                                <Image
                                    src="Icons/Rating-star_aybkcq"
                                    alt="Google Reviews"
                                    width={150}
                                    height={97.45}
                                    className="w-24 h-auto"
                                />
                                <Link target='_blank' href={'https://www.google.com/search?sa=X&sca_esv=7d935f78db81bbbe&rlz=1C5CHFA_enNL1023NL1026&tbm=lcl&sxsrf=AHTn8zqhvHN-kPVh-Bs0PsNXHI5OAG7t5w:1739277676950&q=Web+Beukers+Reviews&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2MTQ0NzEwMLUwt7AwMDc1MTYz38DI-IpRODw1ScEptTQ7tahYISi1LDO1vHgRKzZRAF8f_L1GAAAA&rldimm=4117400587880754367&hl=nl-NL&ved=2ahUKEwjIt-ag0ruLAxV1hP0HHaaWDYUQ9fQKegQITBAF&biw=1411&bih=958&dpr=1#lkt=LocalPoiReviews'} className='flex items-center gap-2 -mt-2 mb-2 w-fit'>{t('read_reviews')}</Link>
                            </MoveFadeInAnimation>
                        }
                        {title !== undefined && title !== null && title !== '' &&
                            <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                                <HeadingText variant='h1' className='mb-6'>
                                    {capitalize(title)}
                                </HeadingText>
                            </MoveFadeInAnimation>
                        }
                        {subTitle !== undefined && subTitle !== null && subTitle !== '' &&
                            <MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
                                <HeadingText variant='h2' className='sm:mt-2'>
                                    {subTitle}
                                </HeadingText>
                            </MoveFadeInAnimation>
                        }
                        <div className="flex flex-col gap-2 mb-6">
                            {children && children}
                        </div>
                        <div className='w-full h-fit relative'>
                            {/* <Image
                                width={1100}
                                height={619}
                                src={headerImage.src}
                                alt={headerImage.alt}
                                className='w-full h-64 sm:h-auto object-cover rounded-3xl filter brightness-75 sm:filter-none'
                            /> */}

                            {certificateImages && (
                                <div className="my-4 flex justify-start gap-4 absolute bottom-2 right-4">
                                    <Image
                                        loading='lazy'
                                        src="iso-27001_tfrdns"
                                        alt="ISO 27001"
                                        width={68.53}
                                        height={97.45}
                                        className="w-12 md:w-20 h-auto"
                                    />
                                    <Image
                                        loading='lazy'
                                        src="iso-9001_os04xg"
                                        alt="ISO 9001"
                                        width={68.53}
                                        height={97.45}
                                        className="w-12 md:w-20 h-auto"
                                    />
                                </div>
                            )}
                        </div>
                        {buttons && buttons.length > 0 && (
                            <div className="flex flex-col gap-2 mt-5">
                                {buttons.map((button, index) => (
                                    <MoveFadeInAnimation key={index} delay={index * 200} direction="right" distance="xl" amount={0.5}>
                                        <ButtonOne
                                            {...button}
                                            key={index}
                                            hoverEffect={true}
                                            className='text-base'
                                        />
                                    </MoveFadeInAnimation>
                                
                                ))}
                            </div>
                        )}
                    </div>

                    {certificateImages && (
                        <div className="hidden md:flex absolute bottom-0 right-0 my-4 mx-6 justify-center gap-4">
                            <Image
                                loading='lazy'
                                src="iso-27001_tfrdns"
                                alt="ISO 27001"
                                width={68.53}
                                height={97.45}
                                className="w-12 md:w-20 h-auto"
                            />
                            <Image
                                loading='lazy'
                                src="iso-9001_os04xg"
                                alt="ISO 9001"
                                width={68.53}
                                height={97.45}
                                className="w-12 md:w-20 h-auto"
                            />
                        </div>
                    )}
                </div>
            </Container>
        </div>
    )
}
