import React, {useState} from 'react';
import { useFormik } from 'formik';
import { TextareaAutosize } from '@mui/material';

// Unique form Components
import Input from '@mui/material/Input';
import ErrorMessage from '@/components/shared/forms/ErrorMessage';

import ActionButton from '@/components/shared/ActionButton';

import emailTemplateConfigQuote from '@/lib/constants/mailTemplates/emailTemplateConfigQuote';

import emailApi from '@/lib/api/EmailApi';
import { useTranslations } from 'next-intl';

import FormControlLabel from '@mui/material/FormControlLabel';

import { ButtonOne, Switch } from '@webbeukers/wb-library.package';
import { route } from '@/lib/navigationFunctions';
import { Params } from '@/lib/types/shared/page.types';

const capitalize = (str: string): string => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

type Props = {
  name: string;
  type: string;
  params: Params;
};

// define types of values
type Values = {
  company: string;
  function: string;
  name: string;
  email: string;
  phone: string;
  pages: string;
  wishes: string;
  links: string;
  multiLanguage: boolean;
  domain: boolean;
  webhosting: boolean;
};

// define types of errors
type Errors = {
  company?: string;
  function?: string;
  name?: string;
  email?: string;
  phone?: string;
  pages?: string;
  wishes?: string;
  links?: string;
  multiLanguage?: string;
  domain?: string;
  webhosting?: string;
};

const validate = (values: Values) => {
  const errors:Errors={};
  
  // Company Validation
  if (!values.company) {
    errors.company = 'required';
  } else if (values.company.length < 2) {
    errors.company = 'company_must_be_at_least_2_characters';
  } else {
    if(errors.company) delete errors.company;
  }

  // Function Validation
  if (!values.function) {
    errors.function = 'required';
  } else if (values.function.length < 2) {
    errors.function = 'function_must_be_at_least_2_characters';
  } else {
    if(errors.function) delete errors.function;
  }
  
  // Name Validation
  if (!values.name) {
    errors.name = 'required';
  } else if (values.name.length < 2) {
    errors.name = 'name_must_be_at_least_2_characters';
  } else {
    if(errors.name) delete errors.name;
  }

  // Email Validation
  if (!values.email) {
    errors.email = 'required';
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
  } else if (!/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i.test(values.email)) {
    errors.email = 'invalid_email_address';
  } else {
    if (errors.email) delete errors.email;
  }

  // Phone Validation
  if (!values.phone) {
    errors.phone = 'required';
  } else if (values.phone.length < 10) {
    errors.phone = 'phone_must_be_at_least_10_characters';
  } else {
    if (errors.phone) delete errors.phone;
  }

  // Pages Validation
  if (!values.pages) {
    errors.pages = 'required';
  } else {
    if (errors.pages) delete errors.pages;
  }

  // Wishes Validation
  if (!values.wishes) {
    errors.wishes = 'required';
  } else if (values.wishes.length < 5) {
    errors.wishes = 'wishes_must_be_at_least_5_characters';
  } else {
    if (errors.wishes) delete errors.wishes;
  }

  // Links Validation
  if (!values.links) {
    errors.links = 'required';
  } else if (values.links.length < 5) {
    errors.links = 'links_must_be_at_least_5_characters';
  } else {
    if (errors.links) delete errors.links;
  }

  return errors;
};

const QuoteForm = ({ name, type, params }: Props) => {

  // const { GTAG_quoteConversion } = useGtagConversions();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const t  = useTranslations();

  const formik = useFormik({
    initialValues: {
      company: '',
      function: '',
      name: '',
      email: '',
      phone: '',
      pages: '',
      wishes: '',
      links: '',
      multiLanguage: false,
      domain: false,
      webhosting: false,
    },
    
    validate,
    
    onSubmit: async (values) => {
      setSubmitting(true);
      
      const emailTemplateConfig = emailTemplateConfigQuote(values.company, values.function, values.name, values.email, values.phone, values.pages, values.wishes, values.links, values.multiLanguage, values.domain, values.webhosting, name, type);
      emailApi.sendEmail(emailTemplateConfig.subject, emailTemplateConfig.html).then((response: any) => {
        formik.resetForm();

        setSubmitting(false);
        setSubmitted(true);
      }).catch((error: any) => {
        console.error('error', error);
        setHasErrors(true);
        setErrorMessage('something_went_wrong_sending_the_message_please_try_again_later');
        setSubmitting(false);
      });
    }
  });

  // define types for event
  type Event = {
    target: {
      name: string;
      value: string;
      checked?: boolean;
    };
  }

  const handleChange = (event: Event) => {
    if (event.target.name === 'multiLanguage' || event.target.name === 'domain' || event.target.name === 'webhosting') {
      formik.setFieldValue(event.target.name, event.target.checked);
      return;
    }
    
    formik.setFieldValue(event.target.name, event.target.value);
  };
  const handleBlur = (event: Event) => {
    formik.setFieldTouched(event.target.name);
  };
  const { errors, touched, values } = formik;

  return (
    <div>
      {isSubmitted ? (
        <div className='text-center'>
          <h4>{t('thanks_for_sending_your_message')} 👏</h4>
        </div>
      ) : (
        <div>
          {hasErrors ? (
            <div>
              <h1>{ errorMessage}</h1>
              <div className='w-fit'>
                <ActionButton buttonText="Refresh" buttonLink={route("/contact", params.locale)} />
              </div>
            </div>
          ) : (
            <form className='flex flex-col w-full gap-2' onSubmit={formik.handleSubmit}>
              <div className="flex gap-2">
                <div className="field w-1/2">
                  <div className="field">
                    <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                      <Input 
                        className='w-full rounded-xl text-white px-4 py-1'
                        type="text"
                        name="company"
                        placeholder={t('company')}
                        disableUnderline={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company}
                        required
                        
                      />
                    </div>
                    <ErrorMessage>
                      {errors.company && touched.company && t(errors.company)}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="field w-1/2">
                  <div className="field">
                    <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                      <Input 
                        className='w-full rounded-xl text-white px-4 py-1'
                        type="text"
                        name="function"
                        placeholder={t('function')}
                        disableUnderline={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.function}
                        required
                        
                      />
                    </div>
                    <ErrorMessage>
                      {errors.function && touched.function && t(errors.function)}
                    </ErrorMessage>
                  </div>
                </div>
              </div>

              <div className="flex gap-2">
                <div className="field w-1/2">
                  <div className="field">
                    <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                      <Input 
                        className='w-full rounded-xl text-white px-4 py-1'
                        type="text"
                        name="name"
                        placeholder={t('name')}
                        disableUnderline={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        required
                        
                      />
                    </div>
                    <ErrorMessage>
                      {errors.name && touched.name && t(errors.name)}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="field w-1/2">
                  <div className="field">
                    <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                      <Input 
                        className='w-full rounded-xl text-white px-4 py-1'
                        type="text"
                        name="email"
                        placeholder={t('email')}
                        disableUnderline={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        required
                        
                      />
                    </div>
                    <ErrorMessage>
                      {errors.email && touched.email && t(errors.email)}
                    </ErrorMessage>
                  </div>
                </div>
              </div>

              <div className="flex gap-2">
                <div className="field w-1/2">
                  <div className="field">
                    <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                      <Input 
                        className='w-full rounded-xl text-white px-4 py-1'
                        type="text"
                        name="phone"
                        placeholder={t('phonenumber')}
                        disableUnderline={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        required
                        
                      />
                    </div>
                    <ErrorMessage>
                      {errors.phone && touched.phone && t(errors.phone)}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="field w-1/2">
                  <div className="field">
                    <div className="transition rounded-xl flex justify-between items-center">
                      <label className='text-white text-lg' htmlFor='pages'>{t('page_s')}</label>
                      <Input
                        className='w-7/20 rounded-xl bg-white text-white px-4 py-1'
                        id='pages'
                        type="text"
                        name="pages"
                        placeholder={'0'}
                        disableUnderline={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pages}
                        required
                        
                      />
                    </div>
                    <ErrorMessage>
                      {errors.pages && touched.pages && t(errors.pages)}
                    </ErrorMessage>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                  <TextareaAutosize
                    className='w-full block rounded-xl text-black px-4 py-1'
                    name="wishes"
                    placeholder={t('specific_wishes')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minRows={1}
                    value={values.wishes}
                    required
                  />
                </div>
                <ErrorMessage>
                  {errors.wishes && touched.wishes && t(errors.wishes)}
                </ErrorMessage>
              </div>

              <div className="field">
                <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                  <TextareaAutosize
                    className='w-full block rounded-xl text-black px-4 py-1'
                    name="links"
                    placeholder={t('are_links_required_if_yes_which_one')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minRows={1}
                    value={values.links}
                    required
                  />
                </div>
                <ErrorMessage>
                  {errors.links && touched.links && t(errors.links)}
                </ErrorMessage>
              </div>

              <div className="flex gap-2">
                <div className="field w-1/2">
                  <div className="field">
                    <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                      <Input 
                        className='w-full rounded-xl text-white px-4 py-1'
                        type="text"
                        readOnly
                        placeholder={t('package_name')}
                        disableUnderline={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={t(name)}
                        required
                        
                      />
                    </div>
                  </div>
                </div>
                <div className="field w-1/2">
                  <div className="field">
                    <div className="transition rounded-xl bg-real-white border border-primary-gray-300">
                      <Input 
                        className='w-full rounded-xl text-white px-4 py-1'
                        type="text"
                        readOnly
                        placeholder={t('package_type')}
                        disableUnderline={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={capitalize(type)}
                        required
                        
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="transition">
                  <FormControlLabel
                    control={<Switch name='multiLanguage' onChange={handleChange} value={values.multiLanguage} sx={{ m: 1 }} />}
                    label={t("i_want_a_multilingual_website")}
                  />
                </div>
                <ErrorMessage>
                  {errors.multiLanguage && touched.multiLanguage && t(errors.multiLanguage)}
                </ErrorMessage>
              </div>

              <div className="field">
                <div className="transition">
                  <FormControlLabel
                    control={<Switch name='domain' onChange={handleChange} value={values.domain} sx={{ m: 1 }} />}
                    label={t("i_have_already_been_provided_with_a_domain_name")}
                    value={values.domain}
                  />
                </div>
                <ErrorMessage>
                  {errors.domain && touched.domain && t(errors.domain)}
                </ErrorMessage>
              </div>

              <div className="field">
                <div className="transition">
                  <FormControlLabel
                    control={<Switch name='webhosting' onChange={handleChange} value={values.webhosting} sx={{ m: 1 }} />}
                    label={t("i_already_have_web_hosting")}
                    value={values.webhosting}
                  />
                </div>
                <ErrorMessage>
                  {errors.webhosting && touched.webhosting && t(errors.webhosting)}
                </ErrorMessage>
              </div>

              <ButtonOne type="button" variant="primary" text={isSubmitting ? t('sending') : t('send')} className='mr-0' hoverEffect={true} />
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default QuoteForm;