'use client';

import { Params } from '@/lib/types/shared/page.types';
import React, { useState, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import Head from '@/components/shared/Head';
import Image from '@/components/shared/Image';
import NextImage from 'next/image';
import { Typography } from '@mui/material';

type Props = {
    params: Params;
};

const GoogleReviews = ({ params }: Props) => {
    const t = useTranslations();

    const [expanded, setExpanded] = useState<number[]>([]);
    const [data, setData] = useState<any>(null);
    const [reviews, setReviews] = useState<any[]>([]);

    const toggleExpand = (index: number) => {
        if (expanded.includes(index)) {
            setExpanded(expanded.filter(i => i !== index));
        } else {
            setExpanded([...expanded, index]);
        }
    };

    useEffect(() => {
        fetch('/api/reviews', {
            method: 'POST',
            body: JSON.stringify({ locale: params.locale }),
        }).then(async (data) => {
            const res = await data.json();

            if (res.data && res.data.reviews) {
                res.data.reviews.forEach((review: any) => {
                    review.pretty_time = new Date(review.time * 1000).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'Europe/Amsterdam' }) + ' GMT+1';
                });

                setReviews(res.data.reviews);
                setData({
                    rating: res.data.rating,
                    user_ratings_total: res.data.user_ratings_total,
                });
            }
        });
    }, []);

    const getFullSchema = (reviews: Array<{ author_name: string, author_url: string, profile_photo_url: string, relative_time_description: string, text: string, rating: number }>) => {
        const allReviewsSchema = () => {
            let returnString = '';

            reviews.map((review, index) => {
                returnString += `
                    {
                        "@type": "Review",
                        "author": "${review.author_name}",
                        "name": "${review.author_name}",
                        "reviewBody": "${review.text}",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "${review.rating}"
                        }
                    }${index < reviews.length - 1 ? ',' : ''}
                `;
            });
            return returnString;
        }

        const schema = `
            {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "mainEntity":{
                    "@type": "Product",
                    "author": "Webbeukers B.V.",
                    "name": "Website laten maken",
                    "publisher": "Webbeukers B.V.",
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "${data.rating}",
                        "reviewCount": "${data.user_ratings_total}"
                    },
                    "review": [
                        ${allReviewsSchema()}
                    ]
                }
            }
        `

        return schema;
    }

    return (
        <>
            {reviews && reviews.length > 0 && <Head tag="script" type='application/ld+json' content={getFullSchema(reviews)} />}

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-3">
                {reviews && reviews.length > 0 && reviews.map((review, index) => (
                    <div key={index} className="h-fit p-6 rounded-3xl border border-gray-t-10">
                        <div className="flex items-center gap-x-3">
                            <NextImage
                                referrerPolicy='no-referrer'
                                src={review.profile_photo_url}
                                alt="Reviewer Image"
                                width={40}
                                height={40}
                                className="w-10 h-10 rounded-full object-contain"
                            />

                            <div className="flex flex-col">
                                <Typography variant='h6' component='h2' className="line-clamp-1 leading-normal text-base">
                                    <a href={review.author_url} target="_blank" rel='noopener noreferrer' className="text-black hover:underline font-roboto capitalize">
                                        {review.author_name}
                                    </a>
                                </Typography>

                                <p title={review.pretty_time} className="text-sm cursor-default text-gray-t-90 font-roboto">{review.relative_time_description}</p>
                            </div>
                        </div>

                        <div className="flex items-center gap-x-0.5 mt-4">
                            {Array.from({ length: review.rating }).map((_, i) => (
                                <Image
                                    key={i}
                                    src="star_lc1nez"
                                    alt="Star"
                                    width={20}
                                    height={20}
                                    className='w-5 h-5'
                                />
                            ))}
                        </div>

                        <p className={`mt-2 ${expanded.includes(index) ? 'line-clamp-none' : 'line-clamp-3'} font-roboto text-black`}>
                            {review.text}
                        </p>
                        <span onClick={() => toggleExpand(index)} className="text-blue-600 underline cursor-pointer font-roboto">
                            {expanded.includes(index) ? t('shared.read_less') : t('shared.read_more')}
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
}

export default GoogleReviews;