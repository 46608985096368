'use client';

import React, { useEffect, useRef } from "react";

import { Container, Typography } from "@mui/material";

import { route } from "@/lib/navigationFunctions";
import Arrow from "@/components/shared/Arrow";
import { useTranslations } from "next-intl";
import { DefaultPageProps, Params } from "@/lib/types/shared/page.types";
import { SolutionsSliderItems } from "@/lib/constants/development";
import Link from "next/link";

import Image from "@/components/shared/Image";
import HeadingText from "@/components/shared/HeadingText";

interface Props extends DefaultPageProps {
    params: Params;
    arrow? : boolean,
}

const SolutionsSlider = ({params, arrow= true}: Props) => {
    const t  = useTranslations();
    const [active, setActive] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        ref.current?.addEventListener('animationend', () => {
            if (ref.current !== null && ref.current !== undefined) {
                ref.current?.removeEventListener('animationend', () => {
                    if (ref.current !== null && ref.current !== undefined) {
                        updateActive();
                    }
                });

                updateActive();
            }
        });
    }, [active]);

    const updateActive = (index = -1) => {
        if (index !== -1) {
            setActive(index);
            return;
        }

        if (active + 1 >= SolutionsSliderItems.length) {
            setActive(0);
            return;
        }

        setActive(active + 1);
    };

    const pauseAnimation = (index: number) => {
        if (index === active) {
            if (ref.current !== null && ref.current !== undefined) {
                ref.current.style.animationPlayState = 'paused';
            }
        }
    };

    const playAnimation = (index: number) => {
        if (index === active) {
            if (ref.current !== null && ref.current !== undefined) {
                ref.current.style.animationPlayState = 'running';
            }
        }
    };

    const arrowsRef = useRef<HTMLDivElement[] | null[]>([]);

    useEffect(() => {
        arrowsRef.current = arrowsRef.current.slice(0, SolutionsSliderItems.length);
    }, [SolutionsSliderItems]);

    const setArrowAnimation = (index: number) => {
        if (arrow) {
            arrowsRef.current[index]?.classList.add('animate-arrow');
        }
    };

    const removeArrowAnimation = (index: number) => {
        if (arrow) {
            arrowsRef.current[index]?.classList.remove('animate-arrow');
        }
    };

    return (
        <React.Fragment>
            <section className="py-16 md:py-24 lg:py-14 hidden lg:block">
                <Container className="flex flex-col items-center justify-center relative">

                    {SolutionsSliderItems.map((item, index) => (
                        <div key={index} className={`lg:absolute item ${item.classes} w-fit p-4 rounded-2xl overflow-hidden cursor-pointer my-4 lg:my-0`} onMouseOver={() => pauseAnimation(index)} onMouseOut={() => playAnimation(index)} onClick={() => updateActive(index)} style={{
                            background: "linear-gradient(70deg, rgba(255, 255, 255, 0.10) 24.42%, rgba(255, 255, 255, 0.00) 102.47%)",
                            boxShadow: "1.5px 3px 22px 0px rgba(0, 0, 0, 0.10)",
                            backdropFilter: "blur(25px)"
                        }}>
                            <div onClick={(e) => e.stopPropagation()}>
                                <Link href={route(item.link, params.locale)} onMouseEnter={() => setArrowAnimation(index)} onMouseLeave={() => removeArrowAnimation(index)}>
                                    <HeadingText variant="h5">{t(item.title)}</HeadingText>
                                    <Typography className='normal-case' variant='body1'>
                                        {t(item.description)}
                                    </Typography>
                            
                                    {index === active && 
                                        <div className="absolute progress-animation left-0 bottom-0 bg-primary-yellow-400 h-5px" ref={index === active ? ref : null} />
                                    }
                            
                                    {arrow &&
                                        <div className="absolute right-5 bottom-2" ref={el => arrowsRef.current[index] = el}>
                                            <Arrow className="ml-auto mt-auto mb-3" />
                                        </div>
                                    }
                                </Link>
                            </div>
                        </div>
                    ))}
                    <Image
                        loading="lazy"
                        src={SolutionsSliderItems[active].image}
                        alt={SolutionsSliderItems[active].title}
                        width={880}
                        height={589.11}
                        multiply={true}
                        className={`w-4/5 h-auto m-auto ${SolutionsSliderItems[active].imageClasses}`}
                    />
                </Container>
            </section>      
            <section className="py-16 md:py-24 block lg:hidden">
                <Container className="block">
                    {SolutionsSliderItems.map((item, index) => (
                        <div key={index} className="flex flex-col items-center mb-10">
                            <div onClick={() => updateActive(index)} className="cursor-pointer w-full flex flex-col">
                                <Link href={route(item.link, params.locale)} rel="noopener noreferrer block w-full h-full">
                                    <div className="flex w-full h-full">
                                        <Image
                                            loading="lazy"
                                            src={item.image}
                                            alt={item.title}
                                            width={880}
                                            height={562.33}
                                            multiply={true}
                                            className={`w-full max-w-dvw-1/2 mx-auto h-auto ${item.imageClasses} mb-min-20px z-10`}
                                        />
                                    </div>
                                </Link>
                                <Link href={route(item.link, params.locale)} rel="noopener noreferrer" onMouseEnter={() => setArrowAnimation(index)} onMouseLeave={() => removeArrowAnimation(index)}>
                                    <div className="w-5/6 sm:w-3/4 text-center mt-2 rounded-2xl overflow-hidden p-4 z-20 relative mx-auto" style={{
                                        background: "linear-gradient(70deg, rgba(255, 255, 255, 0.10) 24.42%, rgba(255, 255, 255, 0.00) 102.47%)",
                                        boxShadow: "1.5px 3px 22px 0px rgba(0, 0, 0, 0.10)",
                                        backdropFilter: "blur(25px)"
                                    }}>
                                        <h5 className="text-lg font-bold">{t(item.title)}</h5>
                                        <p className="text-base md:text-base">{t(item.description)}</p>

                                        <div ref={el => arrowsRef.current[index] = el}>
                                            <Arrow className="ml-auto mt-auto mb-auto" />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </Container>
            </section>
        </React.Fragment>
    )
  };

export default SolutionsSlider;