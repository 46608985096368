import { Variants } from '@/lib/types/shared/page.types';
import React, { FC, HTMLAttributes } from 'react';

export interface ButtonOneProps extends HTMLAttributes<HTMLButtonElement | HTMLLinkElement | HTMLAnchorElement> {
    variant?: Variants,
    hoverEffect?: boolean,
    text?: any,
    paddingY?: 'small' | 'normal' | 'big' | 'none',
    paddingX?: 'small' | 'normal' | 'big' | 'none',
    rounded?:  'small' | 'normal' | 'big' | 'none',
    link?: string,
    onclick?: () => void,
    rel?: string,
    id?: string,
    type?: string,
    action?: string,
    target?: string,
    disabled?: boolean,
    width?: 'full' | 'auto',
    marginClassName?: string,
    className?: string,
    children?: React.ReactNode,
}

const ButtonOne: FC<ButtonOneProps> = ({ variant = 'primary', hoverEffect = false, text, paddingY = 'normal', paddingX = 'normal', rounded = 'normal', link, onclick, rel, id, type, action, target, disabled, marginClassName = '', className, children }: ButtonOneProps) => {
    let buttonClassName = 'button-primary';

    switch (variant) {
        case 'primary':
            buttonClassName = 'button-primary';
            break;
        case 'blakka':
            buttonClassName = 'button-blakka';
            break;
        case 'secondary':
            buttonClassName = 'button-secondary';
            break;
        case 'transparent':
            buttonClassName = 'button-transparent';
            break;
        case 'border-transparent':
            buttonClassName = 'button-border-transparent';
            break;
    }

    return (
        type == 'button' && disabled ?
            <button id={id} type='submit' onClick={ onclick ? () => onclick() : () => {/**/}} className={`${paddingX === 'normal' ? 'px-5' : paddingX === 'small' ? 'px-3' : ''} ${paddingY === 'normal' ? 'py-2' : paddingY === 'small' ? 'py-1' : ''} ${rounded === 'normal' ? 'rounded-lg' : rounded === 'big' ? 'rounded-3xl' : 'rounded-lg'} text-center text-5 font-bold border-none block normal-case disabled:opacity-30 ${marginClassName ? marginClassName : 'm-auto'} ${buttonClassName} ${hoverEffect ? 'hover-effect' : ''} ${className}`} disabled>
                {children || text}
            </button>
        : type == 'button' && !disabled ?
            <button id={id} type='submit' onClick={ onclick ? () => onclick() : () => {/**/}} className={`${paddingX === 'normal' ? 'px-5' : paddingX === 'small' ? 'px-3' : ''} ${paddingY === 'normal' ? 'py-2' : paddingY === 'small' ? 'py-1' : ''} ${rounded === 'normal' ? 'rounded-lg' : rounded === 'big' ? 'rounded-3xl' : 'rounded-lg'} text-center text-5 font-bold border-none block normal-case disabled:opacity-30 ${marginClassName ? marginClassName : 'm-auto'} ${buttonClassName} ${hoverEffect ? 'hover-effect' : ''} ${className}`}>
                {children || text}
            </button>
        :
            <a id={id} href={link ?? ''} rel={rel} target={target ? target : ''} type={action == 'submit' ? action : ''} className={`${buttonClassName} ${hoverEffect ? 'hover-effect' : ''} ${paddingX === 'normal' ? 'px-5' : paddingX === 'small' ? 'px-3' : ''} ${paddingY === 'normal' ? 'py-2' : paddingY === 'small' ? 'py-1' : ''} ${rounded === 'normal' ? 'rounded-lg' : rounded === 'big' ? 'rounded-3xl' : 'rounded-lg'} text-center text-5 font-bold border-none block no-underline disabled:opacity-10 ${marginClassName ? marginClassName : 'm-auto'} ${className}`}>
                {children || text}
            </a>
    )
}

export default ButtonOne;