'use client'

import React from 'react';

import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { Params } from "@/lib/types/shared/page.types";
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';

import { Container, Typography } from '@mui/material';

import { HeadingText } from "@/components/shared";
import CombinedContactFormNew from '@/components/shared/forms/CombinedContactFormNew';

type Props = {
    title?: string | null,
    text?: string | null,
    onButtonClick?: (buttonId: string) => void,
    params?: Params,
}

const ContactSectionNew = ({ params, title, text, onButtonClick = (buttonId: string) => {} }: Props) => {
    const t = useTranslations();

    return (
        <section className='relative py-16 bg-white'>
            <Container className='flex gap-x-16 flex-col md:flex-row items-center'>
                <div className='w-full md:w-1/3'>
                    {title ? (
                        <MoveFadeInAnimation direction='right'>
                            <HeadingText variant="h4" component='h2' className="text-start mb-3 text-primary-gray-950"> {title} </HeadingText>
                        </MoveFadeInAnimation>
                    ) : title === null ? (
                        <></>
                    ) : (
                        <HeadingText variant="h4" component='h2' className="text-start mb-3 text-primary-gray-950"> {t("discuss_possibilities")} </HeadingText>
                    )}

                    {text ? (
                        <Typography className='text-start mb-4 text-primary-gray-950' variant='body1'>
                            {text}
                        </Typography>
                    ) : text === null ? (
                        <></>
                    ) : (
                        <Typography className='text-start mb-4 max-w-350px text-primary-gray-950' variant='body1'>
                            {t("leave_your_details_and_we_will_contact_you")}
                        </Typography>
                    )}

                    <MoveFadeInAnimation direction='right' delay={100}>
                        <HeadingText variant="h4" component='h2' className="text-start mb-3 text-primary-gray-950"> Webbeukers B.V. </HeadingText>
                    </MoveFadeInAnimation>

                    <div className="flex flex-col gap-y-2 gap-x-16 md:gap-0">

                        <div className="flex gap-4 mt-3 mb-4 items-center">
                            <div className="icon w-10 h-10 flex justify-center items-start">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_264_8075)">
                                    <path d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z" fill="#323232"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_264_8075">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div>
                                <Link href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47c64f7510436b39:0x3923f2050e9decbf?sa=X&ved=1t:8290&ictx=111" className="text-xl underline text-primary-gray-950 block">Anthonie Fokkerstraat 39-G <br/>3772MP, Barneveld (GLD)</Link>
                            </div>
                        </div>

                        <div className="flex gap-4 mt-3 mb-4 items-center">
                            <div className="icon w-10 h-10 flex justify-center items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_264_8076)">
                                    <path d="M13 9H11V11H13V9ZM17 9H15V11H17V9ZM20 15.5C18.75 15.5 17.55 15.3 16.43 14.93C16.08 14.82 15.69 14.9 15.41 15.17L13.21 17.37C10.38 15.93 8.06 13.62 6.62 10.79L8.82 8.58C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.5C21 15.95 20.55 15.5 20 15.5ZM19 9V11H21V9H19Z" fill="#323232"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_264_8076">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div>
                                <Link href="tel:0342226486" className="text-xl underline text-primary-gray-950 block">+31(0)342 226486</Link>
                            </div>
                        </div>

                        <div className="flex gap-4 mt-3 mb-4 items-center">
                            <div className="icon w-10 h-10 flex justify-center items-start">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_264_8077)">
                                    <path d="M18.83 7H16.23L10.5 4L4 7.4V17C2.9 17 2 16.1 2 15V7.17C2 6.64 2.32 6.08 2.8 5.83L10.5 2L18.04 5.83C18.47 6.06 18.77 6.53 18.83 7ZM20 8H7C5.9 8 5 8.9 5 10V19C5 20.1 5.9 21 7 21H20C21.1 21 22 20.1 22 19V10C22 8.9 21.1 8 20 8ZM20 11.67L13.5 15L7 11.67V10L13.5 13.33L20 10V11.67Z" fill="#323232"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_264_8077">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div>
                                <Link href="mailto:hello@webbeukers.nl" className="text-xl underline text-primary-gray-950 block">hello@webbeukers.nl</Link>
                                <Link href="mailto:helpdesk@webbeukers.nl" className="text-xl underline text-primary-gray-950 block">helpdesk@webbeukers.nl</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='w-full md:w-2/3'>
                    <div className='bg-transparent overflow-hidden small-spacing'>
                        <CombinedContactFormNew title={title} text={text} onButtonClicked={onButtonClick} />
                    </div>
                </div>
            </Container>

        </section>
    );
}

export default ContactSectionNew;