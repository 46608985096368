import { Params } from '@/lib/types/shared/page.types';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import DottedCard from './DottedCard';

type Props = {
    title: string;
    type?: 'horizontal' | 'vertical';
    steps: {
        title: string;
        description: string;
    }[];
    params: Params;
}

const Steps = ({ title, type = 'horizontal', steps, params }: Props) => {
    return (
        <div>
            <Typography variant="h4" className='text-primary-gray-950 capitalize font-bold'> {title} </Typography>

            <div className="flex flex-col md:flex-row flex-wrap justify-between gap-y-6">
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <div className='flex-1'>
                            {index == 0 ? (
                                <div className='flex w-full gap-x-2'>
                                    <div className='bg-primary-gray-950 w-10 h-10 aspect-square rounded-full flex justify-center items-center'>
                                        <Typography variant="h6" className='text-white capitalize font-bold'> {index + 1} </Typography>
                                    </div>

                                    <div className="bg-primary-gray-950 w-full h-1px my-auto mr-2"></div>
                                </div>
                            ) : (
                                <div className='flex w-full gap-x-2'>
                                    <div className='bg-white w-10 h-10 aspect-square rounded-full flex justify-center items-center'>
                                        <Typography variant="h6" className='text-primary-gray-950 capitalize font-bold'> {index + 1} </Typography>
                                    </div>

                                    {index != steps.length - 1 ? (
                                        <div className="bg-white w-full h-1px my-auto mr-2"></div>
                                    ) : (
                                        <div className="bg-white w-full h-1px my-auto mr-2 md:hidden"></div>
                                    )}
                                </div>
                            )}

                            <div className='pr-10 mt-2'>
                                <Typography variant="h5" className='text-primary-gray-950 capitalize'> {step.title} </Typography>
                                <Typography variant="body1" className='text-primary-gray-950'> {step.description} </Typography>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default Steps;