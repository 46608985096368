'use client';

import React, { useEffect } from "react";

type Props = {
    tag: 'script' | 'style' | 'link';
    rel?: string;
    href?: string;
    as?: string;
    type?: string;
    content?: string;
};

const Head = ({ tag, rel, href, as, type, content }: Props) => {
    useEffect(() => {
        if (tag === 'link') {
            const tagElem = document.createElement(tag);

            if (rel) {
                tagElem.rel = rel;
            }

            if (href) {
                tagElem.href = href;
            }

            if (as) {
                tagElem.as = as;
            }

            document.head.appendChild(tagElem);
        } else {
            const tagElem = document.createElement(tag);

            if (type) {
                tagElem.type = type;
            }

            if (content) {
                tagElem.innerHTML = content;
            }
    
    
            document.head.appendChild(tagElem);
        }
    }, []);

    return (
        null
    )
};

export default Head;