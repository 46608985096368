import React from 'react';

type SharedProps = {
    className?: string;
    children: React.ReactNode;
};

type TableProps = SharedProps & {
    className?: string;
    children: React.ReactNode;
};

type HeaderProps = SharedProps & {
    titleAlignment?: 'left' | 'right';
};

type BodyProps = SharedProps & {

};

type RowProps = SharedProps & {

};

type CellProps = {
    className?: string;
    children: React.ReactNode;
};



const Table = ({ className = '', children }: TableProps) => {
    return (
        <div className={`custom-table ${className}`}>
            {children}
        </div>
    );
};

const Header = ({ titleAlignment = 'left', className = '', children }: HeaderProps) => {
    return (
        <div className={`header ${titleAlignment === 'right' ? 'title-right' : 'title-left'} bg-primary-gray-950 ${className}`}>
            {children}
        </div>
    );
};

const Body = ({ className = '', children }: BodyProps) => {
    return (
        <div className={`body ${className}`}>
            {children}
        </div>
    );
};

const Row = ({ className = '', children }: RowProps) => {
    const cells = React.Children.count(children);

    return (
        <div className={`row ${className}`} style={{
            gridTemplateColumns: `repeat(${cells}, 1fr)`
        }}>
            {children}
        </div>
    );
};

const Cell = ({ className = '', children }: CellProps) => {
    return (
        <div className={`cell ${className}`}>
            {children}
        </div>
    );
};


Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;

export default Table;
