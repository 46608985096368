'use client';

import { DefaultPageProps } from '@/lib/types/shared/page.types';
import { Container, Typography } from '@mui/material';
import React from 'react';
import { route } from '@/lib/navigationFunctions';
import { dispatchPackagesModalOpen } from '@/lib/helpers/modals/PackagesModalHelper';
import Link from 'next/link';
import { Image, ButtonNew } from '@/components/shared';
import { Table } from '@/components/shared';
import { useTranslations } from 'next-intl';
import { convertMarkdownBolds } from '@/lib/utils';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';

const packages = [
    {
        id: 'sidekick',
        contentCount: 7,
    },
    {
        id: 'hero',
        contentCount: 9,
    },
    {
        id: 'supernova',
        contentCount: 7,
    },
];

const PricingSectionNew = ({ id = '', isAlreadyWebsitePage = false, params }: { id?: string, isAlreadyWebsitePage?: boolean } & DefaultPageProps) => {
    const t = useTranslations('pages.websites');

    return (
        <section id={id} className="py-16 bg-white scroll-mt-24">
            <Container className='bg-primary-gray-950 rounded-lg py-10 grid grid-cols-1 md:grid-cols-2 gap-3'>
                <div>
                    <MoveFadeInAnimation direction='right'>
                        <Typography variant="h3" className='text-white capitalize font-bold mb-3'>
                            {t("packages.texts.0.title")}
                        </Typography>
                    </MoveFadeInAnimation>

                    <Typography variant="body1" className='text-white'>
                        {t("packages.texts.0.text")}
                    </Typography>
                </div>

                <div>
                    <MoveFadeInAnimation direction='right' delay={100}>
                        <Typography variant="h3" className='text-white capitalize font-bold mb-3'>
                            {t("packages.texts.1.title")}
                        </Typography>
                    </MoveFadeInAnimation>

                    <Typography variant="body1" className='text-white'>
                        {t("packages.texts.1.text")}
                    </Typography>
                </div>
            </Container>

            <Container className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
                <MoveFadeInAnimation direction='right' delay={100}>
                    <ButtonNew text={t("packages.buttons.0")} link={route("/contact", params.locale)} className='md:ml-auto mt-4' />
                </MoveFadeInAnimation>

                <MoveFadeInAnimation direction='right' delay={200}>
                    <ButtonNew text={t("packages.buttons.1")} link={isAlreadyWebsitePage ? '#price' : route("/website-creation#price", params.locale)} className='md:mr-auto mt-4' />
                </MoveFadeInAnimation>
            </Container>

            <Container className="mt-12">
                <MoveFadeInAnimation direction='right' delay={200}>
                    <Typography variant="h3" className='text-primary-gray-950 capitalize font-bold'>
                        {t("packages.texts.2.title")}
                    </Typography>
                </MoveFadeInAnimation>

                <Typography variant="body1" className='text-primary-gray-950 max-w-2xl'>
                    {t("packages.texts.2.text")}
                </Typography>
            </Container>

            <Container id='price' className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-12 scroll-mt-24">
                {packages.map((pkg, index) => (
                    <div key={pkg.id}>
                        <Typography variant="h4" className='text-primary-gray-950 capitalize font-bold'>
                            {t(`packages.items.${index}.title`)}
                        </Typography>

                        <Typography variant="body1" className='text-primary-gray-950' dangerouslySetInnerHTML={{
                            __html: convertMarkdownBolds(t(`packages.items.${index}.description`))
                        }}></Typography>

                        <Table className='mt-8'>
                            <Table.Header titleAlignment='right'>
                                <Typography variant="h5" className='text-primary-yellow-400 lowercase'>
                                    {t(`packages.items.${index}.price`)}
                                </Typography>
                            </Table.Header>
                            <Table.Body>
                                {Array.from({ length: pkg.contentCount }).map((_, contentIndex) => (
                                    <Table.Row key={contentIndex}>
                                        <Table.Cell>
                                            <Typography variant="body1" className='text-primary-gray-950'>
                                                {t(`packages.items.${index}.contents.${contentIndex}.name`)}
                                            </Typography>
                                        </Table.Cell>
                                        <Table.Cell className='text-right'>
                                            <Typography variant="body1" className='text-primary-gray-950'>
                                                {t(`packages.items.${index}.contents.${contentIndex}.value`)}
                                            </Typography>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}

                                <Table.Row>
                                    <Table.Cell>
                                        <Typography variant="body1" className='text-transparent opacity-0'>
                                            a
                                        </Typography>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                        <MoveFadeInAnimation direction='right' delay={index * 100}>
                            <ButtonNew
                                text={t(`packages.items.${index}.button`)}
                                type='button'
                                onclick={() => dispatchPackagesModalOpen(pkg.id)}
                                noMargin={true}
                                className='-translate-y-1/4 ml-4 mt-1'
                            />
                        </MoveFadeInAnimation>
                    </div>
                ))}
            </Container>

            <Container className='bg-primary-gray-950 rounded-lg py-10 grid grid-cols-1 md:grid-cols-3 gap-6 mt-12'>
                <div>
                    <div className='flex flex-wrap gap-x-4'>
                        <Typography variant="h4" className='text-primary-yellow-400 capitalize font-bold mb-3'>
                            {t("packages.items.3.title")}
                        </Typography>

                        <Typography variant="h4" className='text-white mr-6'>
                            {t("packages.items.3.price")}
                        </Typography>
                    </div>

                    <Typography variant="body1" className='text-white'>
                        {t("packages.items.3.description")}
                    </Typography>
                </div>

                <div className='flex flex-col gap-3'>
                    <div className='flex items-center w-full border-b border-white gap-2'>
                        <Image
                            src="Icons/ArrowRight_a9rppd"
                            width={16}
                            height={16}
                            alt="Arrow right"
                        />

                        <Link href={route("/web-app-creation", params.locale)}>
                            <Typography variant="body1" className='text-white'>{t(`packages.items.3.items.0.name`)}</Typography>
                        </Link>
                    </div>
                    <div className='flex items-center w-full border-b border-white gap-2'>
                        <Image
                            src="Icons/ArrowRight_a9rppd"
                            width={16}
                            height={16}
                            alt="Arrow right"
                        />

                        <Link href={route("/development/customer-portal", params.locale)}>
                            <Typography variant="body1" className='text-white'>{t(`packages.items.3.items.1.name`)}</Typography>
                        </Link>
                    </div>
                    <div className='flex items-center w-full border-b border-white gap-2 md:mb-6'>
                        <Image
                            src="Icons/ArrowRight_a9rppd"
                            width={16}
                            height={16}
                            alt="Arrow right"
                        />

                        <Link href={route("/development/crm-integrations", params.locale)}>
                            <Typography variant="body1" className='text-white'>{t(`packages.items.3.items.2.name`)}</Typography>
                        </Link>
                    </div>

                    {/* Hide on desktop */}
                    <div className='flex items-center w-full border-b border-white gap-2 md:hidden'>
                        <Image
                            src="Icons/ArrowRight_a9rppd"
                            width={16}
                            height={16}
                            alt="Arrow right"
                        />

                        <Link href={route("/cases", params.locale)}>
                            <Typography variant="body1" className='text-white'>{t(`packages.items.3.items.3.name`)}</Typography>
                        </Link>
                    </div>
                    <div className='flex items-center w-full border-b border-white gap-2 mb-6 md:hidden'>
                        <Image
                            src="Icons/ArrowRight_a9rppd"
                            width={16}
                            height={16}
                            alt="Arrow right"
                        />

                        <Link href={route("/contact", params.locale, "?calendly=true")}>
                            <Typography variant="body1" className='text-white'>{t(`packages.items.3.items.4.name`)}</Typography>
                        </Link>
                    </div>


                    <MoveFadeInAnimation direction='right'>
                        <ButtonNew text={t("packages.buttons.2")} link={route("/contact", params.locale)} theme='dark' className='mt-auto' />
                    </MoveFadeInAnimation>


                    {/* Hide on desktop */}
                    <MoveFadeInAnimation direction='right' delay={100}>
                        <ButtonNew
                            text={t("packages.items.3.button")}
                            type='button'
                            theme='dark'
                            onclick={() => dispatchPackagesModalOpen('supreme')}
                            className='md:hidden mt-6'
                        />
                    </MoveFadeInAnimation>
                </div>

                {/* Hide on mobile */}
                <div className='flex-col gap-3 hidden md:flex'>
                    <div className='flex items-center w-full border-b border-white gap-2'>
                        <Image
                            src="Icons/ArrowRight_a9rppd"
                            width={16}
                            height={16}
                            alt="Arrow right"
                        />

                        <Link href={route("/cases", params.locale)}>
                            <Typography variant="body1" className='text-white'>{t(`packages.items.3.items.3.name`)}</Typography>
                        </Link>
                    </div>
                    <div className='flex items-center w-full border-b border-white gap-2 mb-6'>
                        <Image
                            src="Icons/ArrowRight_a9rppd"
                            width={16}
                            height={16}
                            alt="Arrow right"
                        />

                        <Link href={route("/contact", params.locale, "?calendly=true")}>
                            <Typography variant="body1" className='text-white'>{t(`packages.items.3.items.4.name`)}</Typography>
                        </Link>
                    </div>

                    <MoveFadeInAnimation direction='right' delay={100} className='mt-auto'>
                        <ButtonNew
                            text={t("packages.items.3.button")}
                            type='button'
                            theme='dark'
                            onclick={() => dispatchPackagesModalOpen('supreme')}
                        />
                    </MoveFadeInAnimation>
                </div>
            </Container>
        </section>
    );
}

export default PricingSectionNew;