'use client'

import { useTranslations } from 'next-intl';
import { Link } from '@/lib/navigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useRef, useEffect } from 'react';

export default function NavServicesDropdown() {
    const t = useTranslations();

    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    function handleMouseEnter() {
        setIsOpen(true);
    }

    function handleMouseLeave() {
        setIsOpen(false);
    }

    function handleClick() {
        console.log('handleClick', `isOpen ${isOpen}`, `new state ${!isOpen}`);
        
        setIsOpen(!isOpen);
    }

    function handleOutsideClick(event: MouseEvent) {
        if (!wrapperRef.current?.contains(event.target as Node) && !buttonRef.current?.contains(event.target as Node)) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        const isTouchDevice = 'ontouchstart' in window;
        console.log('isTouchDevice', isTouchDevice);
        

        if(isTouchDevice) {
            if (wrapperRef.current) {
                wrapperRef.current.addEventListener('click', handleClick);
            }

            document.addEventListener('click', handleOutsideClick);
        } else {
            if (wrapperRef.current) {
                wrapperRef.current.addEventListener('mouseenter', handleMouseEnter);
                wrapperRef.current.addEventListener('mouseleave', handleMouseLeave);
            }
        }

        return () => {
            try {
                wrapperRef.current?.removeEventListener('click', handleClick);
                
                wrapperRef.current?.removeEventListener('mouseenter', handleMouseEnter);
                wrapperRef.current?.removeEventListener('mouseleave', handleMouseLeave);

                document.removeEventListener('click', handleOutsideClick);
            } catch (error) {
                
            }
        }
    }, [
        wrapperRef,
        isOpen,
    ]);

    return (
      <>
        <div className='flex items-center gap-2'>
            <div className='flex items-center gap-2'>
                <div 
                    ref={wrapperRef}
                    className="c-secondary c-h-secondary relative"
                >
                    <div ref={buttonRef} className='w-fit h-fit cursor-default'>
                        {t('services')}
                        <ExpandMoreIcon className={`font-bold ${isOpen ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`} />
                    </div>
                    {isOpen && (
                        <div className='absolute top-full z-50 origin-top-right bg-primary-gray-950 rounded-lg p-4 border border-white flex flex-col gap-2 w-fit'>
                            <Link href={"/website-creation"}>{t('websites')}</Link>
                            <Link href={"/development"}>{t('development')}</Link>
                            <Link href={"/optimizations"}>{t('optimizations')}</Link>
                            <Link href={"/maintenance"}>{t('maintenance')}</Link>
                            <Link href={"/marketing-transformation"}>{t('marketing')}</Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
      </>
    )
}
