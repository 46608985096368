'use client';

import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslations } from "next-intl";
import { useSearchParams } from 'next/navigation';

import { Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { ButtonOne, NPM_ButtonOne } from "@/components/shared/index"

import { Buttons, Checkboxes, Input, Range, Select, Textarea } from "@/components/shared/forms/fields/index"
import { Params } from "@/lib/types/shared/page.types"
import { QuoteFormQuestion, FormValue, FormValues, QuoteFormSchema, LocalStorageObject } from "@/lib/types/shared/form.types"
import { sendQuoteFormEmail, formQuestions, recursiveFind } from "@/lib/helpers/forms/QuoteFormHelper";

import useEncryptedLocalStorage from "@/lib/hooks/use-encrypted-local-storage";

import { submitMailchimp } from "@/lib/api/MailchimpApi";
import { convertMarkdownLinks } from "@/lib/utils";
import Image from "@/components/shared/Image";
import HeadingText from "@/components/shared/HeadingText";
import Head from "next/head";

type Props = {
    params: Params
}

export default function QuoteFormModal({ params }: Props) {
    const global_t = useTranslations();
    const t = useTranslations("shared.components.QuoteFormModal");

    const defaultLocalStorageObject: LocalStorageObject = {
        action_log: [],
        form_values: {},
        isSubmitted: false
    }

    const [localStorageObject, setLocalStorageObject, isFirstLoad, usingInitialValue, reset] = useEncryptedLocalStorage('quoteForm', defaultLocalStorageObject);
    const [localStorageObjectLoaded, setLocalStorageObjectLoaded] = useState(false);

    const [selectedABVariant, setSelectedABVariant] = useState(localStorageObject.selectedABVariant ?? 'A');

    const [renderQuestions, setRenderQuestions] = useState<ReactNode | null>(null);

    const [loading, setLoading] = useState(true);
    // function setLoading(value: boolean) {
    //     // ...
    // }


    const [bigError, setBigError] = useState('');

    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState<CustomError[]>([]);
    const [showErrors, setShowErrors] = useState<boolean>(true);
    const [clickedSubmit, setClickedSubmit] = useState<boolean>(false);
    const [progress, setProgress] = useState(0);
    const [formValues, setFormValues] = useState<FormValues>({});

    if (process.env.NEXT_APP_ENVIRONMENT === "production") {
        console.log = function () { }
        console.error = function () { }
        console.warn = function () { }
    }

    const generateABVariant = () => {
        // const abTesting = Math.random() < 0.5 ? 'A' : 'B';
        const abTesting = 'A';

        return abTesting;
    }

    useEffect(() => {
        setTimeout(() => {
            setLocalStorageObjectLoaded(true);
        }, 50);
    }, [isFirstLoad]);

    const searchParams = useSearchParams()
    useEffect(() => {
        const openQuoteModal = searchParams.get('openQuoteModal') ?? false;
        if (openQuoteModal) {
            setOpen(true);
            
            if (typeof window !== 'undefined') {
                if (window) {
                    if (window.history) {
                        window.history.pushState({}, "", window.location.pathname);
                    }
                }
            }
        }
    }, [searchParams]);

    useEffect(() => {
        if (localStorageObjectLoaded) {
            const initLocalStorageObject = (withResetLog = false) => {
                const loadedObject = defaultLocalStorageObject;
                loadedObject.uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                loadedObject.loaded_at = new Date().toISOString();

                loadedObject.selectedABVariant = generateABVariant();

                if (withResetLog) {
                    loadedObject.action_log.push({
                        timestamp: new Date().toISOString(),
                        action_name: 'reset_after_expired',
                        element_id: '*'
                    });
                }

                setLocalStorageObject(loadedObject, 'initLocalStorageObject');
            }

            if (usingInitialValue) {

                initLocalStorageObject();

            } else {
                // check if loaded_at is another day
                if (!localStorageObject.loaded_at) {
                    initLocalStorageObject();
                } else {
                    const loadedAt = new Date(localStorageObject.loaded_at);
                    const now = new Date();
                    if (loadedAt.getDate() !== now.getDate()) {
                        initLocalStorageObject(true);
                    }
                }
            }
        }
    }, [localStorageObjectLoaded]);

    useEffect(() => {
        if (isFirstLoad) return;

        setFormValues(localStorageObject.form_values);
            
        const size = Buffer.byteLength(JSON.stringify(localStorageObject));
        const kiloBytes = size / 1024;
        const megaBytes = (kiloBytes / 1024).toFixed(3);
        console.log(`Size of localStorageObject: ${megaBytes} MB`, localStorageObject);

        if (!localStorageObject.selectedABVariant) {
            setLocalStorageObject({
                ...localStorageObject,
                selectedABVariant: generateABVariant()
            }, 'setABVariant');
        } else {
            setSelectedABVariant((localStorageObject.selectedABVariant !== 'A' && localStorageObject.selectedABVariant !== 'B') ? 'A' : localStorageObject.selectedABVariant);
        }
    }, [localStorageObject]);

    useEffect(() => {
        handleChanges();
    }, [formValues]);

    const modalBodyRef = useRef<HTMLDivElement>(null);
    const modalBodyInnerRef = useRef<HTMLDivElement>(null);

    function writeToLog (action_name: string, element_id: string, extra = '') {
        const clone = JSON.parse(JSON.stringify(localStorageObject));
        const now = new Date().toISOString();
        clone.action_log.push({
            timestamp: now,
            action_name,
            element_id,
            extra
        });
        setLocalStorageObject(clone, 'writeToLog');
    }

    function handleOpen (elementName = 'unknown') {
        setOpen(true);
    }
    function handleClose (elementName = 'unknown') {
        const clone = JSON.parse(JSON.stringify(localStorageObject));
        if (clone.isSubmittedMailchimp) {
            resetAll();
            setOpen(false);
            setTimeout(() => {
                setFormValues({});
            }, 500);
        } else {
            writeToLog('close_modal', elementName);
            setOpen(false);
        }
    }

    function resetAll() {
        setLoading(true);

        setClickedSubmit(false);
        setLocalStorageObjectLoaded(false)
        reset();
    }

    async function changeCallBack (name: string, value: FormValue) {
        const clone = JSON.parse(JSON.stringify(localStorageObject));
        clone.form_values[name] = value;
        
        if (clone.isSubmittedMailchimp) {
            return;
        }

        if (name == 'email') {
            setMailchimpEmail(value as string);
        }

        // Remove values of subitems when parent value changes
        const formQuestion = recursiveFind(formQuestions, name);
        if (formQuestion) {
            if (formQuestion.showSubItemsWhen) {
                if (formQuestion.showSubItemsWhen.id === formQuestion.id) {
                    const parentValue = clone.form_values[formQuestion.showSubItemsWhen.id] ?? null;
                    if (parentValue !== formQuestion.showSubItemsWhen.value) {
                        if (formQuestion.subItems) {
                            formQuestion.subItems.forEach((subItem) => {
                                delete clone.form_values[subItem.id];
                            });
                        }
                    }
                }
            } else {
                if (Array.isArray(clone.form_values[name])) {
                    const valueArray = clone.form_values[name] as string[];
                    if (valueArray.length == 0) {
                        delete clone.form_values[name];
                    }
                } else if (value === null || value === 'null' || value === '' || typeof value === 'undefined') {
                    delete clone.form_values[name];
                }
            }
        }
        
        clone.action_log.push({
            timestamp: new Date().toISOString(),
            action_name: 'change_field',
            element_id: name,
            extra: {
                newVal: value
            }
        });

        const uniqueId = clone.uniqueId;
        if (uniqueId) {
            try {
                fetch('/api/quote', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        uniqueId,
                        log_object: clone
                    })
                });
            } catch (error) {
                console.error('something went wrong while autosaving...');
            }
        }

        setLocalStorageObject(clone, 'changeCallBack');
    }

    type CustomError = {
        path: string,
        message: string,
        expose: string
    }
    function handleChanges() {
        const form_values = localStorageObject.form_values;
        const validationErrors: CustomError[] = QuoteFormSchema.validate(form_values) as any;

        if (form_values.email && form_values.email !== null && form_values.email !== '') {
            setMailchimpEmail(form_values.email as string);
        }
        
        const cloneErrors = validationErrors.map((error) => {
            return {
                path: error.path,
                message: error.message ?? '',
                expose: error.message
            }
        });

        // console.log('Errors:', cloneErrors);
    
        setErrors(cloneErrors);
    }

    useEffect(() => {
        setRenderQuestions(recursiveRender(formQuestions));

        const progressResult = recursiveCalculateProgress(formQuestions);        
        setProgress((progressResult.answered / progressResult.total) * 100);

        if (!clickedSubmit) {
            setLoading(false);
        }
    }, [errors]);

    type RecursiveProgress = {
        total: number,
        answered: number,
        fieldIds: string[]
    }
    const recursiveCalculateProgress = (questions: Array<QuoteFormQuestion>, fieldIds: string[] = []): RecursiveProgress => {
        let total = 0;
        let answered = 0;

        questions.forEach((question) => {
            if (question.ignoreInProgress) {
            } else {
                total++;
                fieldIds.push(question.id);
                if (formValues[question.id] !== undefined && formValues[question.id] !== null && formValues[question.id] !== 'null' && formValues[question.id] !== '') {

                    if (Array.isArray(formValues[question.id])) {
                        if (formValues[question.id] !== null) {
                            const value = formValues[question.id] as string[];
                            if (value.length > 0) {
                                answered++;
                            }
                        }
                    } else {
                        answered++;
                    }
                }
            }

            if (question.showSubItemsWhen && formValues[question.showSubItemsWhen.id] !== question.showSubItemsWhen.value) {
            } else {
                if (question.subItems && question.subItems !== null && question.subItems.length > 0) {
                    const subResult = recursiveCalculateProgress(question.subItems, fieldIds);
                    total = total + subResult.total;
                    answered = answered + subResult.answered;
                }
            }
        });

        return {
            total,
            answered,
            fieldIds
        }
    }

    const [termError, setTermError] = useState("");
    const [termAccepted, setTermAccepted] = useState(false);
    const handleTermsCheckbox = (e: any) => {
        if (e.target.checked) {
            setTermAccepted(true);
            setTermError("");
        } else {
            setTermAccepted(false);
            setTermError("errors.accept_terms");
        }
    }

    const pushConversion = () => {
        if ((window as any).gtag !== undefined) {
            const callback = () => {
                // console.log('Conversion callback');
            }

            (window as any).gtag('event', 'SuccessfullQuoteSubmit', {
                send_to: 'G-MQ0CBKR7NL',
                event_callback: callback
            });
        }
    }

    useEffect(() => {
        const handleCustomEvent = (e: any) => {
            pushConversion();
        }
        const handletriggerQuoteFormModal = (e: any) => {
            if (e.detail) {
                if (e.detail.from_id) {
                    handleOpen(e.detail.from_id);
                    return;
                }
            }

            handleOpen('external_trigger_button');
            return;
        }

        window.addEventListener('customEvent', handleCustomEvent);
        window.addEventListener('triggerQuoteFormModal', handletriggerQuoteFormModal);

        return () => {
            window.removeEventListener('customEvent', handleCustomEvent);
            window.removeEventListener('triggerQuoteFormModal', handletriggerQuoteFormModal);
        }
    }, []);

    const submitQuoteForm = () => {
        setClickedSubmit(true);
        handleChanges();

        if (!termAccepted) {
            setTermError("errors.accept_terms");
            return;
        }
        
        if (errors.length === 0) {
            setLoading(true);
            
            const clone = JSON.parse(JSON.stringify(localStorageObject));
            clone.action_log.push({
                timestamp: new Date().toISOString(),
                action_name: 'submit_form',
                element_id: 'submit_button',
                extra: {
                    form_valid: true
                }
            });

            sendQuoteFormEmail(clone, t).then((response: boolean) => {
                if (response) {
                    
                    pushConversion();

                    clone.isSubmitted = true;
                    setLocalStorageObject(clone, 'sendQuoteFormEmail');
                    setLoading(false);
                } else {
                    console.error('Error sending email');
                    setBigError(t('errors.email_error'));
                    setLoading(false);
                }
            }).catch((error: any) => {
                console.error(error);
                setBigError(t('errors.email_error'));
                setLoading(false);
            });
        } else {
            writeToLog('submit_form', 'submit_button', `form_invalid (${errors.length} errors)`);
        }
    }

    const [mailchimpError, setMailchimpError] = useState("");
    const [mailchimpEmail, setMailchimpEmail] = useState(localStorageObject.form_values.email as string ?? "");

    const handleMailchimpEmail = (name: string, value: FormValue) => {
        setMailchimpEmail(value as string);
    }

    const submitMailchimpForm = () => {
        setLoading(true);
        const clone = JSON.parse(JSON.stringify(localStorageObject));
        clone.isSubmittedMailchimp = true;

        let emailToUse = mailchimpEmail ?? "";
        if (!mailchimpEmail) {
            emailToUse = clone.form_values.email ?? "";
        }
        
        submitMailchimp(mailchimpEmail ?? "", clone.form_values.name, clone.form_values.phone).then(() => {
            clone.action_log.push({
                timestamp: new Date().toISOString(),
                action_name: 'submit_mailchimp_form',
                element_id: 'submit_button',
                extra: {
                    email: clone.form_values.email ?? "",
                    isSuccessful: true
                }
            });
            setLocalStorageObject(clone, 'submitMailchimpForm');
            setLoading(false);
        }).catch((error: any) => {
            console.error(error);
            clone.isSubmittedMailchimp = false;
            clone.action_log.push({
                timestamp: new Date().toISOString(),
                action_name: 'submit_mailchimp_form_error',
                element_id: 'submit_button',
                extra: {
                    email: clone.form_values.email ?? "",
                    isSuccessful: false,
                    error: error
                }
            });
            setMailchimpError("errors.mailchimp_error");
            setBigError(t('errors.mailchimp_error'));
            setLoading(false);
        });
    }


    const renderOpenButton = (device: 'mobile' | 'desktop'): ReactNode => {
        if (localStorageObject.isSubmitted) {

            if (device === 'mobile') {
                return (
                    <>
                        <ButtonOne variant="primary" type="button" className="relative overflow-hidden" onclick={() => handleOpen('submitted_reopen_button')} marginClassName="m-0">
                            {t("submitted_reopen_button")}
                            <div className={`absolute bottom-0 left-0 h-1 bg-primary-yellow-400 transition-all duration-300 ${progress < 100 ? 'rounded-r-full' : ''}`} style={{ width: `${progress}%` }}></div>
                        </ButtonOne>
                    </>
                )
            } else {
                return (
                    <>
                        <div rel="nofollow" onClick={() => handleOpen('submitted_reopen_button')} className="p-3 bg-white rounded-r-lg z-0 cursor-pointer relative hidden md:block">
                            <div className={`absolute inset-0 w-1 bg-primary-yellow-400 transition-all duration-300 ${progress < 100 ? 'rounded-b-full' : ''}`} style={{ height: `${progress}%` }}></div>
                            <Typography
                                className="font-bold text-primary-gray-950"
                                sx={{
                                    writingMode: 'vertical-rl',
                                    textOrientation: 'mixed',
                                }}
                            >
                                {`${t("submitted_reopen_button")}`}
                            </Typography>
                        </div>
                    </>
                )
            }
        } else if (progress > 0) {
            
            if (device === 'mobile') {
                return (
                    <>
                        <ButtonOne variant="primary" type="button" className="relative overflow-hidden text-base" onclick={() => handleOpen('reopen_button')}>
                            {t("reopen_button")}
                            <div className={`absolute bottom-0 left-0 h-1 bg-primary-yellow-400 transition-all duration-300 ${progress < 100 ? 'rounded-r-full' : ''}`} style={{ width: `${progress}%` }}></div>
                        </ButtonOne>
                    </>
                )
            } else {
                return (
                    <>
                        <div rel="nofollow" onClick={() => handleOpen('reopen_button')} className="p-3 bg-white rounded-r-lg z-0 cursor-pointer relative hidden md:block">
                            <div className={`absolute inset-0 w-1 bg-primary-yellow-400 transition-all duration-300 ${progress < 100 ? 'rounded-b-full' : ''}`} style={{height: `${progress}%`}}></div>
                            <Typography
                                className="font-bold text-primary-gray-950"
                                sx={{
                                    writingMode: 'vertical-rl',
                                    textOrientation: 'mixed',
                                }}
                            >
                                {`${t("reopen_button")}`}
                            </Typography>
                        </div>
                    </>
                )
            }
        } else {
            if (device === 'mobile') {
                return (
                    <>
                        <ButtonOne variant="secondary" type="button" marginClassName="m-0" onclick={() => handleOpen('trigger_button')}>
                            {global_t("shared.quote")}
                        </ButtonOne>
                    </>
                )
            } else {
                return (
                    <>
                        <div rel="nofollow" onClick={() => handleOpen('trigger_button')} className="p-3 bg-primary-yellow-400 rounded-r-lg z-0 cursor-pointer hidden md:block">
                            <Typography
                                className="font-bold text-primary-gray-950"
                                sx={{
                                    writingMode: 'vertical-rl',
                                    textOrientation: 'mixed',
                                }}
                            >
                                {global_t("shared.quote")}
                            </Typography>
                        </div>
                    </>
                )
            }
        }
    }

    const recursiveRender = (questions: Array<QuoteFormQuestion>, depth = 0, ): ReactNode => {
        const newDepth = depth + 1;
        const form_values = localStorageObject.form_values;

        return (
            questions.map((question, index) => {
                const number = index + 1;

                let subItemsHtml: ReactNode | null = null;
                const enableWrap = question.wrapSubItems ? true : false;
                if (question.subItems && question.subItems !== null && question.subItems.length > 0) {
                    subItemsHtml = recursiveRender(question.subItems, newDepth)
                }

                let questionError: CustomError | null = null;
                questionError = errors.find((error) => error.path === question.id) ?? null;
                if (!clickedSubmit) {
                    questionError = null;
                }

                return (
                    <div id={question.id} className={`${question.topWrapperClassname ?? 'w-full'}`} key={index}>
                        <div className="flex flex-col gap-1">
                            <div className="flex flex-col gap-1 z-1">
                                <div className="flex gap-3">
                                    {question.question && (
                                        depth == 0 ? (
                                            <HeadingText variant="h5">{`${number}. ${t(question.question)}`}</HeadingText>
                                        ) : (
                                            <HeadingText variant="h6">{`${t(question.question)}`}</HeadingText>
                                        )
                                    )}

                                    {question.tooltip && (
                                        <div className="custom-tooltip flex items-center gap-2 cursor-pointer">
                                            <div className="bg-transparent border-2 border-white rounded-full w-6 h-6 flex justify-center items-center relative">
                                                {depth == 0 ? (
                                                    <HeadingText variant="h5">?</HeadingText>
                                                ) : (
                                                    <HeadingText variant="h6">?</HeadingText>
                                                )}
                                                <div className="custom-tooltip-child bg-primary-gray-950 p-3 rounded-lg absolute -right-28 md:left-0 top-full w-screen max-w-350px">
                                                    <Typography variant="body2" className="font-normal text-white">{t(question.tooltip.content)}</Typography>
                                                </div>
                                            </div>
                                            {question.tooltip.text && (
                                                <div className="h-6 flex items-center">
                                                    <Typography variant="body1" className="normal-case text-white">{t(question.tooltip.text)}</Typography>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                
                                {question.description && (
                                    <div className="flex gap-3">
                                        <Typography variant="body1" className="font-normal text-gray-300">{t(question.description)}</Typography>
                                    </div>
                                )}
                            </div>


                            {/* <p>Field: {question.id} { question.type }</p> */}
                            <div className="flex gap-3 w-full pr-2"> {/* THE ACTUAL FIELDS  */}

                                {question.type === 'dropdown' && (
                                    <div className="w-full md:w-1/2 pr-2">
                                        <Select
                                            className={`${question.classname ? question.classname: 'w-full'}`}
                                            params={params}
                                            translation={{
                                                namespace: 'shared.components.QuoteFormModal'
                                            }}
                                            isMultiple={question.isMultiple ?? false}
                                            selectedValue={form_values[question.id] as string | string[] ?? "null"}
                                            options={question.options ?? []}
                                            id={question.id}
                                            name={question.id}
                                            changeCallBack={changeCallBack}
                                        />
                                    </div>
                                )}

                                {question.type === 'buttons' && (
                                    <Buttons
                                        className=""
                                        params={params}
                                        translation={{
                                            namespace: 'shared.components.QuoteFormModal'
                                        }}
                                        isMultiple={question.isMultiple ?? false}
                                        selectedValue={form_values[question.id] as string | string[] ?? "null"}
                                        options={question.options ?? []}
                                        id={question.id}
                                        name={question.id}
                                        changeCallBack={changeCallBack}
                                    />
                                )}

                                {question.type === 'checkboxes' && (
                                    <Checkboxes
                                        className="w-full"
                                        params={params}
                                        translation={{
                                            namespace: 'shared.components.QuoteFormModal'
                                        }}
                                        selectedValue={form_values[question.id] as string[] ?? "null"}
                                        options={question.options ?? []}
                                        id={question.id}
                                        name={question.id}
                                        changeCallBack={changeCallBack}
                                    />
                                )}

                                {question.type === 'range' && (
                                    <Range
                                        className="w-full"
                                        params={params}
                                        translation={{
                                            namespace: 'shared.components.QuoteFormModal'
                                        }}
                                        value={form_values[question.id] as number ?? 0}
                                        id={question.id}
                                        name={question.id}
                                        min={question.min ?? 0}
                                        max={question.max ?? 0}
                                        changeCallBack={changeCallBack}
                                    />
                                )}

                                {question.type === 'textarea' && (
                                    <>
                                        <Textarea
                                            className="w-full"
                                            params={params}
                                            translation={{
                                                namespace: 'shared.components.QuoteFormModal'
                                            }}
                                            value={form_values[question.id] as string ?? ''}
                                            id={question.id}
                                            name={question.id}
                                            changeCallBack={changeCallBack}
                                            placeholder={question.placeholder ?? ""}
                                        />
                                    </>
                                )}

                                {question.type === 'text' && (
                                    <Input
                                        className={question.classname}
                                        params={params}
                                        translation={{
                                            namespace: 'shared.components.QuoteFormModal'
                                        }}
                                        value={form_values[question.id] as string ?? ""}
                                        id={question.id}
                                        name={question.id}
                                        type="text"
                                        changeCallBack={changeCallBack}
                                        placeholder={question.placeholder ?? ""}
                                    />
                                )}

                                {question.type === 'number' && (
                                    <Input
                                        className={question.classname}
                                        params={params}
                                        translation={{
                                            namespace: 'shared.components.QuoteFormModal'
                                        }}
                                        value={form_values[question.id] as number ?? 0}
                                        id={question.id}
                                        name={question.id}
                                        type="number"
                                        changeCallBack={changeCallBack}
                                        placeholder={question.placeholder ?? ""}
                                    />
                                )}

                            </div>

                            {question.type !== "subitems" && (
                                showErrors && (
                                    <Typography variant="body1" className={`${questionError ? 'opacity-100' : 'opacity-0'} normal-case font-bold text-yellow-500 select-none pointer-events-none mt-1`}>
                                        {questionError ? (
                                            t(`errors.${questionError.message}`) ?? ''
                                        ) : 'Validated'}
                                    </Typography>
                                )
                            )}
                        </div>

                        

                        {subItemsHtml && question.showSubItemsWhen && (
                            form_values[question.showSubItemsWhen.id] === question.showSubItemsWhen.value && (
                                <div className="w-full ">
                                    <div className={`w-full flex gap-y-3 ${enableWrap ? 'flex-row flex-wrap' : 'flex-col'}`}>
                                        {subItemsHtml}
                                    </div>
                                </div>
                            )
                        )}

                        {subItemsHtml && !question.showSubItemsWhen && (
                            <div className="w-full ">
                                <div className={`w-full flex gap-y-3 ${enableWrap ? 'flex-row flex-wrap' : 'flex-col'}`}>
                                    {subItemsHtml}
                                </div>
                            </div>
                        )}
                    </div>
                )
            })
        )
    }

    const gradientClasses = 'bg-gradient-to-br from-green-500 via-primary-gray-950 via-50% to-transparent to-85%';

    return (
        <>
            <div className={`hidden ${gradientClasses}`}></div>

            {renderOpenButton('mobile')}

            {/* <div className="fixed left-0 top-1/2 -translate-y-1/2 z-50">
                <div className="flex flex-col gap-2">

                    {renderOpenButton('desktop')}
                    
                </div>
            </div> */}

            <div className={`quote-modal fixed left-0 top-0 w-full h-full ${open ? 'quote-modal-open' : 'hidden'}`} style={{
                zIndex: 99999999,
            }}>
                <div className="relative w-full h-full">
                    <div className="absolute top-0 left-0 w-full h-full z-0 bg-primary-gray-950 bg-opacity-50" onClick={() => handleClose('backdrop')} />

                    {selectedABVariant === 'B' && (
                        <div className="absolute top-0 left-0 w-full h-full z-0 bg-primary-gray-950" />
                    )}


                    {selectedABVariant === 'B' && (
                        <>
                            <div className={`absolute inset-0 h-full w-full py-5 px-20 z-10 rounded-xl flex justify-center items-center select-none transition-opacity duration-300 ${loading && !bigError ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                                <CircularProgress className="w-16 h-16 text-white " />
                            </div>
                            
                            <div className={`absolute inset-0 h-full w-full py-5 px-20 z-10 rounded-xl flex flex-col gap-3 justify-center items-center select-none transition-opacity duration-300 ${bigError ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                                <HeadingText variant="h5">{bigError}</HeadingText>
                                <HeadingText variant="h6">{t('big_error.sub_text')}</HeadingText>

                                <div>
                                    <ButtonOne variant="primary" type="link" rel="nofollow" link={'?openQuoteModal=true'} text={t('big_error.reload_page')} hoverEffect={true} marginClassName="m-0" />
                                </div>
                                
                            </div>
                        </>
                    )}


                    {/* <div className={`absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 ${localStorageObject.isSubmitted ? 'h-auto' : 'h-9/10'} ${selectedABVariant === 'B' ? 'w-full z-1' : 'w-9/10 md:w-4/5 lg:w-1/2 z-1'}`}> */}
                    <div className={`absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 ${localStorageObject.isSubmitted ? 'h-auto' : 'h-9/10'} w-9/10 md:w-4/5 lg:w-1/2 z-1`}>

                        <div className={`bg-primary-gray-950 w-full h-full rounded-xl ${open ? 'animate-bounce-in' : 'animate-bounce-out'}`} style={{
                            boxShadow: `${selectedABVariant === 'B' ? '' : '1.459px 2.918px 21.888px 0px rgba(0, 0, 0, 0.10)'}`,
                        }}>
                            <div className={`relative w-full h-full`}>
                                
                                {selectedABVariant !== 'B' && (
                                    <>
                                        <div className={`py-5 px-20 z-10 w-full h-full absolute inset-0 rounded-xl flex justify-center items-center select-none transition-opacity duration-300 ${loading && !bigError ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                                            <CircularProgress className="w-16 h-16 text-white " />
                                        </div>
                                        
                                        <div className={`py-5 px-20 z-10 w-full h-full absolute inset-0 rounded-xl flex flex-col gap-3 justify-center items-center select-none transition-opacity duration-300 ${bigError ? 'opacity-100' : 'pointer-events-none opacity-0'}`}>
                                            <HeadingText variant="h5">{bigError}</HeadingText>
                                            <HeadingText variant="h6">{t('big_error.sub_text')}</HeadingText>

                                            <div>
                                                <ButtonOne variant="primary" type="link" rel="nofollow" link={'?openQuoteModal=true'} text={t('big_error.reload_page')} hoverEffect={true} marginClassName="m-0" />
                                            </div>
                                            
                                        </div>
                                    </>
                                )}

                                {(selectedABVariant !== 'B') && (
                                    <div className="absolute top-3 right-3 w-10 h-10 rounded-full cursor-pointer flex justify-center items-center" onClick={() => handleClose('close_icon')}>
                                        <CloseIcon className="font-bold" />
                                    </div>
                                )}
                                
                                <div className="h-full w-full flex flex-col">
                                    <div id="modal-header" className={`w-full text-center pb-5 ${selectedABVariant === 'B' ? 'pt-0': 'pt-10'}`}>

                                        {localStorageObject.isSubmitted ? (
                                            <HeadingText variant="h4">{t("requested_quote")}</HeadingText>
                                        ): (
                                            <HeadingText variant="h4">{t("request_quote")}</HeadingText>
                                        )}

                                        <Typography variant="body1" className="font-bold text-white mx-2">{t("modal_header_note")}</Typography>

                                        {localStorageObject.isSubmitted && (
                                            <HeadingText variant="h5">100% {t('completed')}</HeadingText>
                                        )}

                                        <div className={`mt-5 w-full ${selectedABVariant === 'B' ? 'h-2': 'h-2'} bg-primary-gray-950 flex`}>
                                            <div
                                                style={{
                                                    width: `${progress}%`
                                                }}
                                                className={`h-full bg-primary-yellow-400 ${ selectedABVariant === 'B' ? progress < 100 ? 'rounded-l-md': 'rounded-r-md rounded-l-md' : progress < 100 ? 'rounded-r-full' : ''} transition-all duration-300`}
                                            ></div>
                                        </div>
                                    </div>

                                    <div ref={modalBodyRef} id="modal-body" className={`w-auto ${localStorageObject.isSubmitted ? 'h-auto' : 'h-9/10'} overflow-y-auto overflow-x-visible quote-modal-scrollbar pt-5 pb-14 ${selectedABVariant === 'B' ? 'pr-1' : 'mx-5 px-5'} flex flex-col gap-4 relative`}>
                                        <div ref={modalBodyInnerRef} className={`h-full flex flex-col gap-1 w-full`}>
                                            <div className="w-full flex justify-end">
                                                {(!localStorageObject.isSubmitted && !localStorageObject.isSubmittedMailchimp && progress > 0) ? (
                                                    <Typography onClick={() => resetAll()} variant="body1" className="text-white cursor-pointer w-fit whitespace-nowrap mb-0 pb-0 underline">
                                                        <span className="mr-1"><RestartAltIcon /></span>
                                                        {t("reset")}
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1" className="opacity-0 w-fit whitespace-nowrap mb-0 pb-0">{t("reset")}</Typography>
                                                )}
                                            </div>
                                            
                                            {localStorageObject.isSubmitted ? (
                                                <>
                                                    <div className="flex h-full">
                                                        <div className="justify-center items-center hidden md:flex w-2/5 ">
                                                            {localStorageObject.isSubmittedMailchimp ? (
                                                                <Image src="TeamMembers/avatar-heart_rteqiq" width={200} height={200} className="h-auto" alt="Quote Form Submitted" />
                                                            ): (
                                                                <Image src="TeamMembers/avatar-mail_j7ai7m" width={200} height={200} className="h-auto" alt="Quote Form Submitted" />
                                                            )}
                                                        </div>
                                                        <div className="flex flex-1 items-center">
                                                            <div className="flex flex-col gap-3 flex-1">
                                                                <HeadingText variant="h5">{t('submitted_form.heading')}</HeadingText>
                                                                <Typography variant="body1" className="font-bold text-white mb-4">{t("submitted_form.paragraph")}</Typography>

                                                                <HeadingText variant="h6">{t('submitted_form.mailchimp_heading')}</HeadingText>
                                                                <Typography variant="body1" className="font-bold text-white mb-4">{t("submitted_form.mailchimp_paragraph")}</Typography>

                                                                {localStorageObject.isSubmittedMailchimp ? (
                                                                    <>
                                                                        <Typography variant="body1" className="font-extrabold text-white">{t("submitted_form.success_message")}</Typography>
                                                                        {selectedABVariant == 'B' && (
                                                                            <NPM_ButtonOne className="ml-0" type='button' onclick={() => handleClose('completed-close-button')} variant="primary" text={t("close_button")} hoverEffect={true} />
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="flex gap-2">
                                                                            <input
                                                                                className="w-full py-2 px-5 rounded-xl border-none inline-flex text-gray-950 ring-0 ring-transparent shadow-inner text-base font-bold"
                                                                                value={mailchimpEmail}
                                                                                id="email"
                                                                                name="email"
                                                                                type="email"
                                                                                onBlur={(e) => handleMailchimpEmail('email', e.target.value)}
                                                                                onChange={(e) => handleMailchimpEmail('email', e.target.value)}
                                                                                placeholder={"submitted_form.email_placeholder"}
                                                                            />
                                                                            <NPM_ButtonOne type='button' onclick={() => submitMailchimpForm()} variant="primary" text={t("submitted_form.submit_button")} hoverEffect={true} />
                                                                                
                                                                        </div>
                                                                        <Typography variant="body1" className={`${mailchimpError ? 'opacity-100' : 'opacity-0'} normal-case font-bold text-yellow-500 select-none pointer-events-none pl-2`}>
                                                                            {mailchimpError ? (
                                                                                t(`errors.mailchimp_error`) ?? ''
                                                                            ) : 'Validated'}
                                                                        </Typography>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            ): (
                                                renderQuestions ?? ''
                                            )}
                                        </div>
                                    </div>

                                    {!localStorageObject.isSubmitted && (
                                        // <div id="modal-footer" className={`flex flex-col gap-4 justify-center items-center pt-5 px-10 ${selectedABVariant === 'B' ? 'pb-0 w-9/10 md:w-4/5 lg:w-1/2 mx-auto': 'pb-10 w-full'}`}>
                                        <div id="modal-footer" className={`flex flex-col gap-4 justify-center items-center pt-5 w-full ${selectedABVariant === 'B' ? 'pb-0 pr-1': 'pb-10 px-10'}`}>

                                            <div className="w-full">
                                                <label className="w-full">
                                                    <input onChange={(e) => handleTermsCheckbox(e)} type="checkbox" className="mr-2" />
                                                    
                                                    <span dangerouslySetInnerHTML={{
                                                        __html: convertMarkdownLinks(t('accept_terms'), params.locale)
                                                    }}></span>
                                                </label>

                                                {showErrors && (
                                                    termError ? (
                                                        <Typography variant="body1" className={`${termError ? 'opacity-100' : 'opacity-0'} normal-case font-bold text-yellow-500 select-none pointer-events-none pl-2`}>
                                                            {t(`errors.accept_terms`) ?? ''}
                                                        </Typography>
                                                    ) : ''
                                                )}
                                            </div>

                                            <NPM_ButtonOne id="QuoteFormSubmit" type='button' onclick={submitQuoteForm} variant="primary" text={t("submit_button")} hoverEffect={true} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
