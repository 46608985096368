"use client";

import React from 'react';

import { CldImage } from 'next-cloudinary';

type Props = {
    loading?: 'lazy' | 'eager',
    src: string,
    alt: string,
    width?: number,
    height?: number,
    multiply?: boolean,
    fill?: boolean,
    className?: string,
    resposiveSizes?: { maxWidth?: number, size: number }[],
} & React.HTMLProps<HTMLImageElement>;

export default function Image({ loading = 'lazy', src = '', alt = '', width, resposiveSizes, height, multiply = false, fill, style, className = '', onClick, onMouseEnter, onMouseLeave }: Props) {
    return (
        <CldImage
            loading={loading}
            src={src}
            alt={alt}
            {...(width && { width: multiply ? width * 2 : width })}
            {...(height && { height: multiply ? height * 2 : height })}
            fill={fill}
            sizes={resposiveSizes && resposiveSizes.map((size) => `${typeof size.maxWidth !== 'undefined' ? `(max-width: ${size.maxWidth}px) ` : ''}${size.size}px`).join(', ')}
            className={className}
            style={style}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        />
    );
}
