import { CompanySliderImage, HomeCoreValue, HomeProduct } from "@/lib/types/shared/page.types";
import { Locales } from "@/lib/navigation";
import { getTranslations } from "next-intl/server";
import { route } from "@/lib/navigationFunctions";

export const homeCoreValues: HomeCoreValue[] = [
    {
        title: "innovative_design",
        text: "our_designs_are_not_only_visually_appealing_but_also_innovative_tailored_to_your_unique_brand_and_needs",
    },
    {
        title: "advanced_development",
        text: "we_build_with_the_latest_technologies_from_simple_websites_to_complex_applications",
    },
    {
        title: "strategic_marketing",
        text: "our_seo_and_digital_strategies_strengthen_your_online_presence_and_brand_visibility",
    },
    {
        title: "reliable_hosting",
        text: "fast_secure_hosting_solutions_for_optimal_website_performance",
    },
    {
        title: "targeted_maintenance",
        text: "our_maintenance_ensures_that_your_website_or_application_is_always_uptodate_secure_and_performing_optimally",
    },
    {
        title: "customeroriented_service",
        text: "personal_service_and_longterm_support_for_your_digital_success",
    },
];

export const homeCompanySliderCldImages: CompanySliderImage[] = [
    {
        size: 'normal',
        image: "CompanyLogos/actoniq_alf9nq",
        alt: "Act On IQ",
    },
    {
        size: 'normal',
        image: "CompanyLogos/siemens_zjlqpj",
        alt: "Siemens",
    },
    {
        size: 'normal',
        image: "CompanyLogos/billezz_jjruma",
        alt: "BillEzz",
    },
    {
        size: 'big',
        image: "CompanyLogos/aceg_xe7hyt",
        alt: "Aceg",
    },
    {
        size: 'big',
        image: "CompanyLogos/westpole_d4lxex",
        alt: "Westpole",
    },
    {
        size: 'big',
        image: "CompanyLogos/swoove_fnueok",
        alt: "Swoove",
    },
    {
        size: 'big',
        image: "CompanyLogos/digitrust_hd2ysf",
        alt: "Digitrust",
    },
    {
        size: 'big',
        image: "CompanyLogos/coolstransport_ihdimx",
        alt: "Coolstransport",
    },
    {
        size: 'normal',
        image: "CompanyLogos/decourant_iwqaua",
        alt: "De Courant",
    },
    {
        size: 'normal',
        image: "CompanyLogos/serogym_jtg1s9",
        alt: "Serogym",
    },
    {
        size: 'normal',
        image: "CompanyLogos/kegelsturnhout_fasxxv",
        alt: "Kegels Turnhout",
    },
    {
        size: 'normal',
        image: "CompanyLogos/vleesvanmaris_wyg5ro",
        alt: "Vlees van Maris",
    },
    {
        size: 'big',
        image: "CompanyLogos/brlregistratie_m2fvj4",
        alt: "BRL Registratie",
    },
    {
        size: 'normal',
        image: "CompanyLogos/reparatiedienstmpm_n4tzbv",
        alt: "Reparatiedienst MPM",
    },
    {
        size: 'big',
        image: "CompanyLogos/morso-antwerp_ikea1d",
        alt: "Morso Antwerp",
    },
    {
        size: 'normal',
        image: "CompanyLogos/mrjuridisch_spy3sy",
        alt: "Met recht juridisch",
    },
    {
        size: 'normal',
        image: "CompanyLogos/mmbeautyensense_cuoixr",
        alt: "MM Beauty en Sense",
    },
    {
        size: 'normal',
        image: "CompanyLogos/laluka_ke2v18",
        alt: "La Luka",
    },
    {
        size: 'big',
        image: "CompanyLogos/sjegers_ijaf8m",
        alt: "S'Jegers",
    },
    {
        size: 'normal',
        image: "CompanyLogos/frrealestate_wmfwdi",
        alt: "FR Real Estate",
    },
    {
        size: 'normal',
        image: "CompanyLogos/avestafinance_lvfufr",
        alt: "Avesta Finance",
    },
    {
        size: 'normal',
        image: "CompanyLogos/xanthiahairbeauty_dehm7c",
        alt: "Xanthia Hair & Beauty",
    },
    {
        size: 'normal',
        image: "CompanyLogos/ear-ear_swrh17",
        alt: "Ear Ear",
    },
];

export const homeWebsiteImages = [
    "Projects/earear_j8clr1",
    "Projects/fr-real-estate_wmrclq",
    "Projects/swoove_ezs9iz",
    "Projects/billezz_fknqqr",
    "Projects/brinks_k4pgjt",
    "Projects/actoniq_fvyfqc",
];


const getHomeConstants = async (locale: Locales | 'nl') => {
    let t = (variable: string) => {
        return variable;
    }

    if (locale) {
        t = await getTranslations({ locale: locale, namespace: 'pages.home' });
    }

    const services = [
        {
            title: t('section_two.items.0.title'),
            content: t('section_two.items.0.content'),
            link: route('/website-creation', locale),
        },
        {
            title: t('section_two.items.1.title'),
            content: t('section_two.items.1.content'),
            link: route('/development', locale),
        },
        {
            title: t('section_two.items.2.title'),
            content: t('section_two.items.2.content'),
        },
        {
            title: t('section_two.items.3.title'),
            content: t('section_two.items.3.content'),
            link: route('/marketing-transformation', locale),
        }
    ];

    return {
        services
    };
};

export default getHomeConstants;