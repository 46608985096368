'use client';

import React, { useEffect } from 'react';
import { gsap } from 'gsap';

export default function CursorFollower() {
    useEffect(() => {
        const cursor = document.getElementById("custom-cursor");
        const links = document.querySelectorAll("a");
        const buttons = document.querySelectorAll("button");
        
        let isTouchScrolling = false;
    
        const onMouseMove = (e: PointerEvent) => {
            if (e.pointerType !== "mouse") return; // Ignore touch events
    
            const { clientX, clientY } = e;
            gsap.to(cursor, {
                x: clientX,
                y: clientY,
            });
    
            if (cursor) {
                cursor.style.display = "flex";
            }
        };
    
        const onMouseEnterLink = (e: PointerEvent) => {
            if (e.pointerType !== "mouse") return; // Ignore touch
    
            gsap.to(cursor, { scale: 4 });
        };
    
        const onMouseLeaveLink = (e: PointerEvent) => {
            if (e.pointerType === "mouse") {
                gsap.to(cursor, { scale: 1 });
            }
        };
    
        // Detect touch scrolling and hide cursor
        const onTouchStart = () => {
            isTouchScrolling = false;
            if (cursor) cursor.style.display = "none"; // Hide cursor
        };
    
        const onTouchMove = () => {
            isTouchScrolling = true;
        };
    
        const onTouchEnd = () => {
            setTimeout(() => (isTouchScrolling = false), 100);
        };
    
        document.addEventListener("pointermove", onMouseMove);
        document.addEventListener("touchstart", onTouchStart);
        document.addEventListener("touchmove", onTouchMove, { passive: true });
        document.addEventListener("touchend", onTouchEnd);
    
        links.forEach((link) => {
            link.addEventListener("pointerenter", onMouseEnterLink);
            link.addEventListener("pointerleave", onMouseLeaveLink);
        });
    
        buttons.forEach((button) => {
            button.addEventListener("pointerenter", onMouseEnterLink);
            button.addEventListener("pointerleave", onMouseLeaveLink);
        });
    
        return () => {
            document.removeEventListener("pointermove", onMouseMove);
            document.removeEventListener("touchstart", onTouchStart);
            document.removeEventListener("touchmove", onTouchMove);
            document.removeEventListener("touchend", onTouchEnd);
    
            links.forEach((link) => {
                link.removeEventListener("pointerenter", onMouseEnterLink);
                link.removeEventListener("pointerleave", onMouseLeaveLink);
            });
    
            buttons.forEach((button) => {
                button.removeEventListener("pointerenter", onMouseEnterLink);
                button.removeEventListener("pointerleave", onMouseLeaveLink);
            });
        };
    }, []);
    

    return (
        <div id='custom-cursor' className='custom-cursor' />
    );
}
