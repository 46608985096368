import { ComponentProps, Params, QuestionProps, Themes } from '@/lib/types/shared/page.types';
import { convertMarkdownBolds, convertMarkdownLinks } from '@/lib/utils';
import { Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import Head from '@/components/shared/Head';
import React, { useState } from 'react';
import { ButtonNew } from '.';
import { route } from '@/lib/navigationFunctions';

type Props = ComponentProps & {
    theme?: Themes;
    questions: Array<QuestionProps>;
    addStructedData?: boolean;
    addFullFAQButton?: boolean;
    params: Params;
};

export default function Questions({ theme = 'dark', questions, addStructedData = false, addFullFAQButton = false, params }: Props) {
    // const t = useTranslations('pages.home.faq');
    const t = useTranslations();

    const [active, setActive] = useState(1);

    const handleClick = (e: any, id: number) => {
        if (id === active) {
            setActive(0);
            return;
        }

        if (active !== 0) {
            setActive(0);
    
            setTimeout(() => {
                setActive(id);
            }, 300);
        } else {
            setActive(id);
        }
    };

    const getFAQSchema = (faqs: Array<{ name: string, text: any }>) => {
		const allFAQsSchema = () => {
			let returnString = '';

			faqs.map((item, index) => {
				returnString += `
                    {
                        "@type": "Question",
                        "name": "${item.name}",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "${item.text}"
						}
                    }${index < faqs.length - 1 ? ',' : ''}
                `;
			});

			return returnString;
		};

		const schema = `
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": {
					${allFAQsSchema()}
                }
            }
        `;

		return schema;
	};

    return (
        <>
            {addStructedData && <Head tag="script" type='application/ld+json' content={getFAQSchema(questions)} />}

            <div className='md:w-2/3 h-full inset-0 z-10 transition-all duration-300'>
                <div className='h-full relative'>
                    <div className="max-w-2/3 relative flex flex-col">
                        <div className="flex flex-col gap-3">
                            {questions.map((question, index) => (
                                <React.Fragment key={index}>
                                    <div>
                                        {/* <h3 className={`question ${active === index + 1 ? 'active' : ''} mb-2`} onClick={(e) => handleClick(e, index + 1)}>{question.title}</h3> */}
                                        <Typography className={`question ${active === index + 1 ? 'active' : ''} text-2xl font-semibold my-2 ${theme === 'light' ? 'text-primary-gray-950' : 'text-white'}`} variant="body1" onClick={(e) => handleClick(e, index + 1)}>
                                            {question.name}
                                        </Typography>

                                        <div className={`answer ${active === index + 1 ? 'max-h-full scale-y-100' : 'max-h-0 scale-y-0'} my-2`}>
                                            {question.convertTextToLink ? (
                                                <p className={`${theme === 'light' ? 'text-primary-gray-950' : 'text-white'}`} dangerouslySetInnerHTML={{
                                                    __html: question.text
                                                }} />
                                            ) : (
                                                <p className={`${theme === 'light' ? 'text-primary-gray-950' : 'text-white'}`}>
                                                    {question.text}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    {index !== questions.length - 1 && <hr className={`${theme === 'light' ? 'border-primary-gray-950' : 'border-white'}`} />}
                                </React.Fragment>
                            ))}
                        </div>

                        {addFullFAQButton && (
                            <div className='mx-auto mt-4'>
                                <ButtonNew text={t('shared.questions.button')} link={route("/website-creation#faq", params.locale)} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
