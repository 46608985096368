'use client'

import { ActionLog, LocalStorageObject, QuoteFormQuestion } from "@/lib/types/shared/form.types";
import { EmailProps } from "@/lib/api/NodeEmailApi";

export const formQuestions: Array<QuoteFormQuestion> = [
    { // website_type dropdown
        order: 1,
        id: "website_type",
        question: "questions.what_type_of_website.label",
        type: "dropdown",
        classname: "",
        isMultiple: false,
        translateLabelInEmail: true,
        translateValueInEmail: true,
        options: [
            {
                value: "business-card",
                label: "questions.what_type_of_website.options.business-card.label"
            },
            {
                value: "interactive",
                label: "questions.what_type_of_website.options.interactive.label"
            },
            {
                value: "e-commerce",
                label: "questions.what_type_of_website.options.e-commerce.label"
            },
            {
                value: "custom",
                label: "questions.what_type_of_website.options.custom.label"
            },
        ]
    },

    // { // self_customizing buttons
    //     order: 2,
    //     id: "self_customizing",
    //     question: "questions.self_customizing.label",
    //     type: "buttons",
    //     buttonsClassname: "whitespace-nowrap",
    //     translateLabelInEmail: true,
    //     translateValueInEmail: true,
    //     tooltip: {
    //         text: "questions.self_customizing.tooltip.text",
    //         content: "questions.self_customizing.tooltip.content"
    //     },
    //     options: [
    //         {
    //             value: "yes",
    //             label: "questions.self_customizing.options.yes.label"
    //         },
    //         {
    //             value: "no",
    //             label: "questions.self_customizing.options.no.label"
    //         },
    //     ]
    // },

    // { // self_content_delivery buttons
    //     order: 3,
    //     id: "self_content_delivery",
    //     question: "questions.self_arrange_content.label",
    //     type: "buttons",
    //     translateLabelInEmail: true,
    //     translateValueInEmail: true,
    //     options: [
    //         {
    //             value: "yes",
    //             label: "questions.self_arrange_content.options.yes.label"
    //         },
    //         {
    //             value: "no",
    //             label: "questions.self_arrange_content.options.no.label"
    //         },
    //     ],

    //     subItems: [
    //         {
    //             order: 1,
    //             id: "needed_content",
    //             question: "questions.self_arrange_content.subItems.what_content_is_needed.label",
    //             type: "checkboxes",
    //             translateLabelInEmail: true,
    //             translateValueInEmail: true,
    //             options: [
    //                 {
    //                     value: "images",
    //                     label: "questions.self_arrange_content.subItems.what_content_is_needed.options.images.label"
    //                 },
    //                 {
    //                     value: "texts",
    //                     label: "questions.self_arrange_content.subItems.what_content_is_needed.options.texts.label"
    //                 },
    //                 {
    //                     value: "logo",
    //                     label: "questions.self_arrange_content.subItems.what_content_is_needed.options.logo.label"
    //                 },
    //                 {
    //                     value: "corporate_identity",
    //                     label: "questions.self_arrange_content.subItems.what_content_is_needed.options.corporate_identity.label",
    //                     tooltip: {
    //                         text: "",
    //                         content: "questions.self_arrange_content.subItems.what_content_is_needed.options.corporate_identity.tooltip.content"
    //                     },
    //                 },
    //             ]
    //         },
    //     ],
    //     showSubItemsWhen: {
    //         id: "self_content_delivery",
    //         value: "no"
    //     }
    // },

    { // self_arrange_designs buttons
        order: 4,
        id: "self_arrange_designs",
        question: "questions.self_arrange_designs.label",
        type: "buttons",
        translateLabelInEmail: true,
        translateValueInEmail: true,
        options: [
            {
                value: "yes",
                label: "questions.self_arrange_designs.options.yes.label"
            },
            {
                value: "no",
                label: "questions.self_arrange_designs.options.no.label"
            },
        ]
    },

    { // pages_count range
        order: 5,
        id: "page_count",
        question: "questions.pages_count.label",
        type: "range",
        translateLabelInEmail: true,
        translateValueInEmail: false,
        max: 40,
        min: 1,
        description: "questions.pages_count.description",
        tooltip: {
            text: "questions.pages_count.tooltip.text",
            content: "questions.pages_count.tooltip.content"
        },
    },

    { // integrations textarea
        order: 6,
        id: "integrations",
        question: "questions.integrations.label",
        type: "textarea",
        placeholder: "questions.integrations.label",
        translateLabelInEmail: true,
        translateValueInEmail: false,
    },
    { // budget text
        order: 6,
        id: "budget",
        question: "questions.budget.label",
        type: "text",
        classname: "w-full",
        translateLabelInEmail: true,
        translateValueInEmail: false,
        placeholder: "questions.budget.label",
    },

    // { // integrations_and_budget subitems
    //     order: 6,
    //     id: "integrations_and_budget",
    //     question: "questions.extra_clearance_information.label",
    //     type: "subitems",
    //     translateLabelInEmail: true,
    //     translateValueInEmail: true,
    //     ignoreInProgress: true,
    //     subItems: [
    //         // { // integrations textarea
    //         //     order: 1,
    //         //     id: "integrations",
    //         //     question: "questions.extra_clearance_information.subItems.integrations.label",
    //         //     type: "textarea",
    //         //     placeholder: "questions.extra_clearance_information.subItems.integrations.label",
    //         //     translateLabelInEmail: true,
    //         //     translateValueInEmail: false,
    //         // },
    //         { // budget text
    //             order: 2,
    //             id: "budget",
    //             question: "questions.extra_clearance_information.subItems.budget.label",
    //             type: "text",
    //             classname: "w-full",
    //             translateLabelInEmail: true,
    //             translateValueInEmail: false,
    //             placeholder: "questions.extra_clearance_information.subItems.budget.label",
    //             tooltip: {
    //                 text: "questions.extra_clearance_information.subItems.budget.tooltip.text",
    //                 content: "questions.extra_clearance_information.subItems.budget.tooltip.content"
    //             },
    //         },
    //     ],
    // },

    { // company_info subitems
        order: 7,
        id: "company_info",
        question: "questions.company_info.label",
        type: "subitems",
        translateLabelInEmail: true,
        translateValueInEmail: true,
        ignoreInProgress: true,
        subItems: [
            // { // company_name text
            //     order: 1,
            //     id: "company_name",
            //     question: "",
            //     type: "text",
            //     topWrapperClassname: "w-full md:w-1/2",
            //     translateLabelInEmail: true,
            //     translateValueInEmail: false,
            //     placeholder: "questions.company_info.subItems.company_name.label",
            // },
            // { // name text
            //     order: 2,
            //     id: "name",
            //     question: "",
            //     type: "text",
            //     topWrapperClassname: "w-full md:w-1/2",
            //     translateLabelInEmail: true,
            //     translateValueInEmail: false,
            //     placeholder: "questions.company_info.subItems.name.label",
            // },
            { // phone text
                order: 3,
                id: "phone",
                question: "",
                type: "text",
                topWrapperClassname: "w-full md:w-1/2",
                translateLabelInEmail: true,
                translateValueInEmail: false,
                placeholder: "questions.company_info.subItems.phone.label",
            },
            { // email text
                order: 4,
                id: "email",
                question: "",
                type: "text",
                topWrapperClassname: "w-full md:w-1/2",
                translateLabelInEmail: true,
                translateValueInEmail: false,
                placeholder: "questions.company_info.subItems.email.label",
            },
            // { // street_and_number text
            //     order: 5,
            //     id: "street_and_number",
            //     question: "",
            //     type: "text",
            //     topWrapperClassname: "w-full md:w-1/2",
            //     translateLabelInEmail: true,
            //     translateValueInEmail: false,
            //     placeholder: "questions.company_info.subItems.street_and_number.label",
            // },
            // { // city text
            //     order: 6,
            //     id: "city",
            //     question: "",
            //     type: "text",
            //     topWrapperClassname: "w-full md:w-1/2",
            //     translateLabelInEmail: true,
            //     translateValueInEmail: false,
            //     placeholder: "questions.company_info.subItems.city.label",
            // },
        ],
        wrapSubItems: true,
    },
];

export const sendQuoteFormEmail = async (localStorageObject: LocalStorageObject, translatFunction: any): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const clone: LocalStorageObject = JSON.parse(JSON.stringify(localStorageObject));
        if (!clone.form_values.email) {
            reject('No email address provided');
            return;
        }
        // setTimeout(() => {
        //     // resolve(true);
        //     // return;
        //     reject(false);
        //     return;
        // }, 3000);
        // return;
        
        const html = replaceTranslationTags(createTemplate(clone), translatFunction);
        const emailConfig: EmailProps = {
            to: `${clone.form_values.email}`,
            bcc: [],
            subject: 'Offerte Formulier',
            html: html
        };


        const adminHtml = replaceTranslationTags(createAdminTemplate(clone), translatFunction);
        const adminEmailConfig: EmailProps = {
            to: `gerrit@webbeukers.nl`,
            bcc: [],
            subject: 'Offerte Formulier Logs',
            html: adminHtml
        };

        fetch('/api/mailer', {
            method: 'POST',
            headers: {
                'Custom-WB-Token': process.env.NEXT_APP_API_BEARER ?? '',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emailConfig)
        }).then(data => {
            // SEND ADMIN EMAIL
            fetch('/api/mailer', {
                method: 'POST',
                headers: {
                    'Custom-WB-Token': process.env.NEXT_APP_API_BEARER ?? '',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(adminEmailConfig)
            }).then(data => {
                resolve(true);
            }).catch(error => {
                console.error(error);
                reject(false);
            });
            // END SEND ADMIN EMAIL
        }).catch(error => {
            console.error(error);
            reject(false);
        });
    });
}



export const recursiveFind = (formQuestions: QuoteFormQuestion[], key: string, parent: QuoteFormQuestion | null = null): QuoteFormQuestion | null => {
    for (const question of formQuestions) {
        if (question.id === key) {
            if (parent) question.parent = parent;
            return question;
        }

        if (question.subItems) {
            const found = recursiveFind(question.subItems, key, question);
            if (found) {
                if(parent) found.parent = parent;
                return found;
            }
        }
    }

    return null;
}

const createTemplateRow = (label: string, value: string, valuePrefix = '', valueSuffix = '') => {
    return `
    <tr>
        <!--[if !mso]><!-->
        <td class=t54
            style="overflow:hidden;width:800px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t54 style="overflow:hidden;width:800px;"><![endif]-->
            <div class=t60
                    style="display:inline-table;width:100%;text-align:left;vertical-align:middle;">
                <!--[if mso]>
                <table role=presentation cellpadding=0 cellspacing=0 align=left valign=middle><tr><td width=230 valign=middle><![endif]-->
                <!--[if mso]>
                </td><td width=230 valign=middle><![endif]-->
                <div class=t60
                        style="display:inline-table;text-align:initial;vertical-align:inherit;width:100%;max-width:800px;margin-bottom:5px;">
                    <table role=presentation
                            width=100%
                            cellpadding=0
                            cellspacing=0
                            class=t85>
                        <tr>
                            <td class=t86
                                style="overflow:hidden;">
                                <table role=presentation
                                        width=100%
                                        cellpadding=0
                                        cellspacing=0>
                                    <tr>
                                        <td>
                                            <table class=t91
                                                    role=presentation
                                                    cellpadding=0
                                                    cellspacing=0
                                                    align=center>
                                                <tr>
                                                    <!--[if !mso]><!-->
                                                    <td class=t92
                                                        style="width:600px;">
                                                        <!--<![endif]-->
                                                        <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
                                                        <p class=t98
                                                            style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">
                                                            ${label ?? ''}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table class=t101
                                                    role=presentation
                                                    cellpadding=0
                                                    cellspacing=0
                                                    align=center>
                                                <tr>
                                                    <!--[if !mso]><!-->
                                                    <td class=t102
                                                        style="width:600px;">
                                                        <!--<![endif]-->
                                                        <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
                                                        <p class=t108
                                                            style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">
                                                            ${valuePrefix}${value ?? ''}${valueSuffix}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
                <!--[if mso]>
                </td>
                </tr></table>
                <![endif]-->
            </div>
        </td>
    </tr>
    `;
}
export const createTemplate = (localStorageObject: LocalStorageObject): string => {
    let rows = '';
    let name = 'Iemand'

    const formValuesArray = Object.entries(localStorageObject.form_values);
    formValuesArray.sort((a, b) => {
        const questionA = recursiveFind(formQuestions, a[0]);
        const questionB = recursiveFind(formQuestions, b[0]);
        if (!questionA || !questionB) return 0;
        if (questionA?.parent) {
            
            if(questionB?.parent) {
                return questionA.parent.order - questionB.parent.order;
            } else {
                return questionA.parent.order - questionB.order;
            }
        } else if (questionB?.parent) {
            return questionA.order - questionB.parent.order;
        } else {
            return questionA.order - questionB.order;
        }
    });

    formValuesArray.forEach(([key, value]) => {
        const formQuestion = recursiveFind(formQuestions, key);
        if (!formQuestion) {
            return;
        }

        const translateLabel = formQuestion.translateLabelInEmail;
        const translateValue = formQuestion.translateValueInEmail;

        if (key === 'name') {
            if (value !== '' && value !== null && value !== undefined) {
                name = value as string;
            }
        }

        let newKey = key;
        if (translateLabel) {
            if (formQuestion.question) {
                newKey = `[T]${formQuestion.question}[/T]`;
            } else if (formQuestion.placeholder) {
                newKey = `[T]${formQuestion.placeholder}[/T]`;
            }
        }

        if (formQuestion.parent) {
            if (formQuestion.parent.showSubItemsWhen) {
                if (formQuestion.parent.showSubItemsWhen.id === formQuestion.parent.id) {
                    const parentValue = localStorageObject.form_values[formQuestion.parent.showSubItemsWhen.id] ?? null;
                    if(parentValue !== formQuestion.parent.showSubItemsWhen.value) {
                        return;
                    }
                }
            }
        }

        if (Array.isArray(value)) {
            let newValues: string[] = [];
            for (const val of value) {
                if (val !== '' && val !== null && val !== undefined) {

                    let newValue = val as string;
                    if (translateValue) {
                        const option = formQuestion.options?.find(option => option.value === val);
                        if (option) {
                            newValue = `[T]${option.label}[/T]`;
                        }
                    }
                    newValues.push(newValue);
                }
            }

            rows += createTemplateRow(newKey, newValues.join(', '));
        } else {
            if (value !== '' && value !== null && value !== undefined) {

                let newValue = value as string;
                if (translateValue) {
                    const option = formQuestion.options?.find(option => option.value === value);
                    if (option) {
                        newValue = `[T]${option.label}[/T]`;
                    }
                }

                rows += createTemplateRow(newKey, newValue as string);
            }
        }
    });

    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
        <head>
            <meta name=x-apple-disable-message-reformatting>
            <meta http-equiv=X-UA-Compatible>
            <meta charset=utf-8>
            <meta name=viewport content=target-densitydpi=device-dpi>
            <meta content=true name=HandheldFriendly>
            <meta content=width=device-width name=viewport>
            <style type="text/css">
                table {
                    border-collapse: separate;
                    table-layout: fixed;
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt
                }

                table td {
                    border-collapse: collapse
                }

                .ExternalClass {
                    width: 100%
                }

                .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                    line-height: 100%
                }

                * {
                    line-height: inherit;
                    text-size-adjust: 100%;
                    -ms-text-size-adjust: 100%;
                    -moz-text-size-adjust: 100%;
                    -o-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale
                }

                html {
                    -webkit-text-size-adjust: none !important
                }

                img+div {
                    display: none;
                    display: none !important
                }

                img {
                    Margin: 0;
                    padding: 0;
                    -ms-interpolation-mode: bicubic
                }

                h1,
                h2,
                h3,
                p,
                a {
                    line-height: 1;
                    overflow-wrap: normal;
                    white-space: normal;
                    word-break: break-word
                }

                a {
                    text-decoration: none
                }

                h1,
                h2,
                h3,
                p {
                    min-width: 100% !important;
                    width: 100% !important;
                    max-width: 100% !important;
                    display: inline-block !important;
                    border: 0;
                    padding: 0;
                    margin: 0
                }

                a[x-apple-data-detectors] {
                    color: inherit !important;
                    text-decoration: none !important;
                    font-size: inherit !important;
                    font-family: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important
                }

                a[href^="mailto"],
                a[href^="tel"],
                a[href^="sms"] {
                    color: inherit;
                    text-decoration: none
                }

                @media (min-width: 481px) {
                    .hd {
                        display: none !important
                    }
                }

                @media (max-width: 480px) {
                    .hm {
                        display: none !important
                    }
                }

                [style*="Inter"] {
                    font-family: 'Inter', BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif !important;
                }

                [style*="Inter Tight"] {
                    font-family: 'Inter Tight', BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif !important;
                }

                @media only screen and (min-width: 481px) {
                    .t9 {
                        padding-left: 40px !important;
                        padding-right: 40px !important
                    }

                    .t11 {
                        padding-left: 40px !important;
                        padding-right: 40px !important;
                        width: 540px !important
                    }

                    .t31 {
                        line-height: 41px !important;
                        font-size: 39px !important;
                        mso-text-raise: 1px !important
                    }

                    .t64,
                    .t83 {
                        width: 50% !important
                    }
                }
            </style>
            <!--[if !mso]><!-->
            <link href="https://fonts.googleapis.com/css2?family=Inter+Tight:wght@500;600;800&family=Inter:wght@400&display=swap"
                rel="stylesheet" type="text/css">
            <!--<![endif]-->
            <!--[if mso]>
                <style type="text/css">
                td.t11,td.t9{padding-left:40px !important;padding-right:40px !important}h1.t31{line-height:41px !important;font-size:39px !important;mso-text-raise:1px !important}div.t64,div.t83{width:50% !important}
                </style>
            <![endif]-->
            <!--[if mso]>
                <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
                </xml>
                <![endif]-->
        </head>

        <body class=t0 style="min-width:100%;Margin:0px;padding:0px;background-color:#ffffff;">
            <div class=t1 style="background-color:#ffffff;">
                <table role=presentation width=100% cellpadding=0 cellspacing=0 border=0 align=center>
                    <tr>
                        <td class=t177 style="font-size:0;line-height:0;mso-line-height-rule:exactly;" valign=top align=center>
                            <!--[if mso]>
                    <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false">
                    <v:fill color=#ffffff />
                    </v:background>
                    <![endif]-->
                            <table role=presentation width=100% cellpadding=0 cellspacing=0 border=0 align=center>
                                <tr>
                                    <td>
                                        <table class=t10 role=presentation cellpadding=0 cellspacing=0 align=center>
                                            <tr>
                                                <!--[if !mso]><!-->
                                                <td class=t11
                                                    style="background-color:#FFFFFF;overflow:hidden;width:560px;padding:50px 30px 50px 30px;">
                                                    <!--<![endif]-->
                                                    <!--[if mso]><td class=t11 style="background-color:#FFFFFF;overflow:hidden;width:620px;padding:50px 30px 50px 30px;"><![endif]-->
                                                    <table role=presentation width=100% cellpadding=0 cellspacing=0>
                                                        <tr>
                                                            <td>
                                                                <table class=t155 role=presentation cellpadding=0 cellspacing=0
                                                                    align=center>
                                                                    <tr>
                                                                        <!--[if !mso]><!-->
                                                                        <td class=t156
                                                                            style="background-color:#FFFFFF;overflow:hidden;width:540px;padding:40px 40px 40px 40px;border-radius:10px 10px 10px 10px;">
                                                                            <!--<![endif]-->
                                                                            <!--[if mso]><td class=t156 style="background-color:#FFFFFF;overflow:hidden;width:620px;padding:40px 40px 40px 40px;border-radius:10px 10px 10px 10px;"><![endif]-->
                                                                            <table role=presentation width=100% cellpadding=0
                                                                                cellspacing=0>
                                                                                <tr>
                                                                                    <td>
                                                                                        <table class=t159 role=presentation
                                                                                            cellpadding=0 cellspacing=0
                                                                                            align=center>
                                                                                            <tr>
                                                                                                <!--[if !mso]><!-->
                                                                                                <td class=t160
                                                                                                    style="width:55px;">
                                                                                                    <!--<![endif]-->
                                                                                                    <!--[if mso]><td class=t160 style="width:55px;"><![endif]-->
                                                                                                    <div style="font-size:0px;">
                                                                                                        <img loading="lazy"
                                                                                                            class=t166
                                                                                                            style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;"
                                                                                                            width=55
                                                                                                            height=37.5
                                                                                                            src=https://res.cloudinary.com/dxd5axwvg/image/upload/v1715866103/logo-small_cuwx5q.webp />
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class=t13
                                                                    style="mso-line-height-rule:exactly;mso-line-height-alt:5px;line-height:5px;font-size:1px;display:block;">
                                                                    &nbsp;</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table class=t24 role=presentation cellpadding=0 cellspacing=0
                                                                    align=left>
                                                                    <tr>
                                                                        <!--[if !mso]><!-->
                                                                        <td class=t25 style="width:800px;">
                                                                            <!--<![endif]-->
                                                                            <!--[if mso]><td class=t25 style="width:800px;"><![endif]-->
                                                                            <h1 class=t31
                                                                                style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:38px;font-weight:800;font-style:normal;font-size:28px;text-decoration:none;text-transform:none;direction:ltr;color:#222222;text-align:center;mso-line-height-rule:exactly;mso-text-raise:3px;">
                                                                                [T]email.subject[/T]</h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class=t23
                                                                    style="mso-line-height-rule:exactly;mso-line-height-alt:20px;line-height:20px;font-size:1px;display:block;">
                                                                    &nbsp;</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table class=t34 role=presentation cellpadding=0 cellspacing=0
                                                                    align=left>
                                                                    <tr>
                                                                        <!--[if !mso]><!-->
                                                                        <td class=t35 style="width:800px;">
                                                                            <!--<![endif]-->
                                                                            <!--[if mso]><td class=t35 style="width:800px;"><![endif]-->
                                                                            <p class=t41
                                                                            style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:28px;font-weight:500;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#777777;text-align:center;mso-line-height-rule:exactly;mso-text-raise:3px;">
                                                                                [T]email.intro[/T]</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class=t33
                                                                    style="mso-line-height-rule:exactly;mso-line-height-alt:20px;line-height:20px;font-size:1px;display:block;">
                                                                    &nbsp;</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table class=t49 role=presentation cellpadding=0 cellspacing=0
                                                                    align=center>
                                                                    <tr>
                                                                        <!--[if !mso]><!-->
                                                                        <td class=t50
                                                                            style="background-color:#F6F6F6;overflow:hidden;width:520px;padding:30px 40px 30px 40px;border-radius:12px 12px 12px 12px;">
                                                                            <!--<![endif]-->
                                                                            <!--[if mso]><td class=t50 style="background-color:#F6F6F6;overflow:hidden;width:600px;padding:30px 40px 30px 40px;border-radius:12px 12px 12px 12px;"><![endif]-->
                                                                            <table role=presentation width=100% cellpadding=0
                                                                                cellspacing=0>
                                                                                <tr>
                                                                                    <td>
                                                                                        <table class=t53 role=presentation
                                                                                            cellpadding=0 cellspacing=0
                                                                                            align=center>
                                                                                                                                                                                                
                                                                                            ${rows ?? ''}

                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <table class=t130 role=presentation cellpadding=0
                                                                                cellspacing=0 align=center>
                                                                                <tr>
                                                                                    <!--[if !mso]><!-->
                                                                                    <td class=t131 style="width:600px;">
                                                                                        <!--<![endif]-->
                                                                                        <!--[if mso]><td class=t131 style="width:600px;"><![endif]-->
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class=t167
                                style="mso-line-height-rule:exactly;mso-line-height-alt:20px;line-height:20px;font-size:1px;display:block;">
                                &nbsp;</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table class=t169 role=presentation cellpadding=0 cellspacing=0 align=center>
                                <tr>
                                    <!--[if !mso]><!-->
                                    <td class=t170 style="width:500px;">
                                        <!--<![endif]-->
                                        <!--[if mso]><td class=t170 style="width:500px;"><![endif]-->
                                        <p class=t176
                                        style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:16px;font-weight:400;font-style:normal;font-size:11px;text-decoration:none;text-transform:none;direction:ltr;color:#777777;text-align:center;mso-line-height-rule:exactly;mso-text-raise:2px;">
                                            Copyright © ${new Date().getFullYear()} Webbeukers</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </body>

    </html>
`;
}


const createAdminTemplateRow = (label: string, value: string, valuePrefix = '', valueSuffix = '') => {
    return `
        <tr>
            <td>
                <div class=t33
                    style="mso-line-height-rule:exactly;mso-line-height-alt:20px;line-height:20px;font-size:1px;display:block;">
                    &nbsp;</div>
            </td>
        </tr>
        <tr>
            <td>
                <table class=t49 role=presentation cellpadding=0 cellspacing=0
                    align=center>
                    <tr>
                        <!--[if !mso]><!-->
                        <td class=t50
                            style="background-color:#F6F6F6;overflow:hidden;width:520px;padding:30px 40px 30px 40px;border-radius:12px 12px 12px 12px;">
                            <!--<![endif]-->
                            <!--[if mso]><td class=t50 style="background-color:#F6F6F6;overflow:hidden;width:600px;padding:30px 40px 30px 40px;border-radius:12px 12px 12px 12px;"><![endif]-->
                            <table role=presentation width=100% cellpadding=0
                                cellspacing=0>
                                <tr>
                                    <td>
                                        <table class=t53 role=presentation
                                            cellpadding=0 cellspacing=0
                                            align=center>
                                                                                                                                                
                                            <tr>
                                                <!--[if !mso]><!-->
                                                <td class=t54
                                                    style="overflow:hidden;width:800px;">
                                                    <!--<![endif]-->
                                                    <!--[if mso]><td class=t54 style="overflow:hidden;width:800px;"><![endif]-->
                                                    <div class=t60
                                                            style="display:inline-table;width:100%;text-align:left;vertical-align:middle;">
                                                        <!--[if mso]>
                                                        <table role=presentation cellpadding=0 cellspacing=0 align=left valign=middle><tr><td width=230 valign=middle><![endif]-->
                                                        <!--[if mso]>
                                                        </td><td width=230 valign=middle><![endif]-->
                                                        <div class=t60
                                                                style="display:inline-table;text-align:initial;vertical-align:inherit;width:100%;max-width:800px;margin-bottom:5px;">
                                                            <table role=presentation
                                                                    width=100%
                                                                    cellpadding=0
                                                                    cellspacing=0
                                                                    class=t85>
                                                                <tr>
                                                                    <td class=t86
                                                                        style="overflow:hidden;">
                                                                        <table role=presentation
                                                                                width=100%
                                                                                cellpadding=0
                                                                                cellspacing=0>
                                                                            <tr>
                                                                                <td>
                                                                                    <table class=t91
                                                                                            role=presentation
                                                                                            cellpadding=0
                                                                                            cellspacing=0
                                                                                            align=center>
                                                                                        <tr>
                                                                                            <!--[if !mso]><!-->
                                                                                            <td class=t92
                                                                                                style="width:600px;">
                                                                                                <!--<![endif]-->
                                                                                                <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
                                                                                                <h2 class=t98
                                                                                                    style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#ffffff;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">
                                                                                                    ${label ?? ''}
                                                                                                </h2>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <table class=t101
                                                                                            role=presentation
                                                                                            cellpadding=0
                                                                                            cellspacing=0
                                                                                            align=center>
                                                                                        <tr>
                                                                                            <!--[if !mso]><!-->
                                                                                            <td class=t102
                                                                                                style="width:600px;">
                                                                                                <!--<![endif]-->
                                                                                                <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
                                                                                                <div class=t108
                                                                                                    style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">
                                                                                                    ${valuePrefix}${value ?? ''}${valueSuffix}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                        <!--[if mso]>
                                                        </td>
                                                        </tr></table>
                                                        <![endif]-->
                                                    </div>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table class=t130 role=presentation cellpadding=0
                                cellspacing=0 align=center>
                                <tr>
                                    <!--[if !mso]><!-->
                                    <td class=t131 style="width:600px;">
                                        <!--<![endif]-->
                                        <!--[if mso]><td class=t131 style="width:600px;"><![endif]-->
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    `;
}
export const createAdminTemplate = (localStorageObject: LocalStorageObject): string => {
    let rows = '';

    if (localStorageObject.selectedABVariant) {
        rows += createAdminTemplateRow('AB Test Variant', localStorageObject.selectedABVariant);
    }

    if (localStorageObject.action_log.length > 0) {
        
        localStorageObject.action_log.forEach(action => {
            const date = new Date(action.timestamp);

            const dateString = `${date.toLocaleDateString('nl-NL', {})} ${date.toLocaleTimeString('nl-NL', {})}`;
            const name = action.action_name;
            let extraInfo = '';
            if (action.extra) {
                extraInfo = JSON.stringify(action.extra);
            }

            const value = `
                <p>Element id: ${action.element_id}</p>
                <p>Extra info: </p>
                <div style="white-space: pre-wrap;">
                    ${extraInfo}
                </div>
            `;

            rows += createAdminTemplateRow(`[T]actions.${name}[/T] - ${dateString}`, value);
        });
    }

    return `
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
            <head>
                <meta name=x-apple-disable-message-reformatting>
                <meta http-equiv=X-UA-Compatible>
                <meta charset=utf-8>
                <meta name=viewport content=target-densitydpi=device-dpi>
                <meta content=true name=HandheldFriendly>
                <meta content=width=device-width name=viewport>
                <style type="text/css">
                    table {
                        border-collapse: separate;
                        table-layout: fixed;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt
                    }

                    table td {
                        border-collapse: collapse
                    }

                    .ExternalClass {
                        width: 100%
                    }

                    .ExternalClass,
                    .ExternalClass p,
                    .ExternalClass span,
                    .ExternalClass font,
                    .ExternalClass td,
                    .ExternalClass div {
                        line-height: 100%
                    }

                    * {
                        line-height: inherit;
                        text-size-adjust: 100%;
                        -ms-text-size-adjust: 100%;
                        -moz-text-size-adjust: 100%;
                        -o-text-size-adjust: 100%;
                        -webkit-text-size-adjust: 100%;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale
                    }

                    html {
                        -webkit-text-size-adjust: none !important
                    }

                    img+div {
                        display: none;
                        display: none !important
                    }

                    img {
                        Margin: 0;
                        padding: 0;
                        -ms-interpolation-mode: bicubic
                    }

                    h1,
                    h2,
                    h3,
                    p,
                    a {
                        line-height: 1;
                        overflow-wrap: normal;
                        white-space: normal;
                        word-break: break-word
                    }

                    a {
                        text-decoration: none
                    }

                    h1,
                    h2,
                    h3,
                    p {
                        min-width: 100% !important;
                        width: 100% !important;
                        max-width: 100% !important;
                        display: inline-block !important;
                        border: 0;
                        padding: 0;
                        margin: 0
                    }

                    a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: none !important;
                        font-size: inherit !important;
                        font-family: inherit !important;
                        font-weight: inherit !important;
                        line-height: inherit !important
                    }

                    a[href^="mailto"],
                    a[href^="tel"],
                    a[href^="sms"] {
                        color: inherit;
                        text-decoration: none
                    }

                    @media (min-width: 481px) {
                        .hd {
                            display: none !important
                        }
                    }

                    @media (max-width: 480px) {
                        .hm {
                            display: none !important
                        }
                    }

                    [style*="Inter"] {
                        font-family: 'Inter', BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif !important;
                    }

                    [style*="Inter Tight"] {
                        font-family: 'Inter Tight', BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif !important;
                    }

                    @media only screen and (min-width: 481px) {
                        .t9 {
                            padding-left: 40px !important;
                            padding-right: 40px !important
                        }

                        .t11 {
                            padding-left: 40px !important;
                            padding-right: 40px !important;
                            width: 540px !important
                        }

                        .t31 {
                            line-height: 41px !important;
                            font-size: 39px !important;
                            mso-text-raise: 1px !important
                        }

                        .t64,
                        .t83 {
                            width: 50% !important
                        }
                    }
                </style>
                <!--[if !mso]><!-->
                <link href="https://fonts.googleapis.com/css2?family=Inter+Tight:wght@500;600;800&family=Inter:wght@400&display=swap"
                    rel="stylesheet" type="text/css">
                <!--<![endif]-->
                <!--[if mso]>
                    <style type="text/css">
                    td.t11,td.t9{padding-left:40px !important;padding-right:40px !important}h1.t31{line-height:41px !important;font-size:39px !important;mso-text-raise:1px !important}div.t64,div.t83{width:50% !important}
                    </style>
                <![endif]-->
                <!--[if mso]>
                    <xml>
                    <o:OfficeDocumentSettings>
                    <o:AllowPNG/>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                    </o:OfficeDocumentSettings>
                    </xml>
                    <![endif]-->
            </head>

            <body class=t0 style="min-width:100%;Margin:0px;padding:0px;background-color:#ffffff;">
                <div class=t1 style="background-color:#ffffff;">
                    <table role=presentation width=100% cellpadding=0 cellspacing=0 border=0 align=center>
                        <tr>
                            <td class=t177 style="font-size:0;line-height:0;mso-line-height-rule:exactly;" valign=top align=center>
                                <!--[if mso]>
                        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false">
                        <v:fill color=#ffffff />
                        </v:background>
                        <![endif]-->
                                <table role=presentation width=100% cellpadding=0 cellspacing=0 border=0 align=center>
                                    <tr>
                                        <td>
                                            <table class=t10 role=presentation cellpadding=0 cellspacing=0 align=center>
                                                <tr>
                                                    <!--[if !mso]><!-->
                                                    <td class=t11
                                                        style="background-color:#FFFFFF;overflow:hidden;width:560px;padding:50px 30px 50px 30px;">
                                                        <!--<![endif]-->
                                                        <!--[if mso]><td class=t11 style="background-color:#FFFFFF;overflow:hidden;width:620px;padding:50px 30px 50px 30px;"><![endif]-->
                                                        <table role=presentation width=100% cellpadding=0 cellspacing=0>
                                                            <tr>
                                                                <td>
                                                                    <table class=t155 role=presentation cellpadding=0 cellspacing=0
                                                                        align=center>
                                                                        <tr>
                                                                            <!--[if !mso]><!-->
                                                                            <td class=t156
                                                                                style="background-color:#FFFFFF;overflow:hidden;width:540px;padding:40px 40px 40px 40px;border-radius:10px 10px 10px 10px;">
                                                                                <!--<![endif]-->
                                                                                <!--[if mso]><td class=t156 style="background-color:#FFFFFF;overflow:hidden;width:620px;padding:40px 40px 40px 40px;border-radius:10px 10px 10px 10px;"><![endif]-->
                                                                                <table role=presentation width=100% cellpadding=0
                                                                                    cellspacing=0>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <table class=t159 role=presentation
                                                                                                cellpadding=0 cellspacing=0
                                                                                                align=center>
                                                                                                <tr>
                                                                                                    <!--[if !mso]><!-->
                                                                                                    <td class=t160
                                                                                                        style="width:55px;">
                                                                                                        <!--<![endif]-->
                                                                                                        <!--[if mso]><td class=t160 style="width:55px;"><![endif]-->
                                                                                                        <div style="font-size:0px;">
                                                                                                            <img loading="lazy"
                                                                                                                class=t166
                                                                                                                style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;"
                                                                                                                width=55
                                                                                                                height=37.5
                                                                                                                src=https://res.cloudinary.com/dxd5axwvg/image/upload/v1715866103/logo-small_cuwx5q.webp />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div class=t13
                                                                        style="mso-line-height-rule:exactly;mso-line-height-alt:5px;line-height:5px;font-size:1px;display:block;">
                                                                        &nbsp;</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table class=t24 role=presentation cellpadding=0 cellspacing=0
                                                                        align=left>
                                                                        <tr>
                                                                            <!--[if !mso]><!-->
                                                                            <td class=t25 style="width:800px;">
                                                                                <!--<![endif]-->
                                                                                <!--[if mso]><td class=t25 style="width:800px;"><![endif]-->
                                                                                <h1 class=t31
                                                                                    style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:38px;font-weight:800;font-style:normal;font-size:28px;text-decoration:none;text-transform:none;direction:ltr;color:#222222;text-align:center;mso-line-height-rule:exactly;mso-text-raise:3px;">
                                                                                    [T]email.admin_heading[/T]</h1>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div class=t23
                                                                        style="mso-line-height-rule:exactly;mso-line-height-alt:20px;line-height:20px;font-size:1px;display:block;">
                                                                        &nbsp;</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table class=t34 role=presentation cellpadding=0 cellspacing=0
                                                                        align=left>
                                                                        <tr>
                                                                            <!--[if !mso]><!-->
                                                                            <td class=t35 style="width:800px;">
                                                                                <!--<![endif]-->
                                                                                <!--[if mso]><td class=t35 style="width:800px;"><![endif]-->
                                                                                <p class=t41
                                                                                style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:28px;font-weight:500;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#777777;text-align:center;mso-line-height-rule:exactly;mso-text-raise:3px;">
                                                                                    [T]email.admin_intro[/T]</p>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>

                                                            ${rows ?? ''}

                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class=t167
                                    style="mso-line-height-rule:exactly;mso-line-height-alt:20px;line-height:20px;font-size:1px;display:block;">
                                    &nbsp;</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table class=t169 role=presentation cellpadding=0 cellspacing=0 align=center>
                                    <tr>
                                        <!--[if !mso]><!-->
                                        <td class=t170 style="width:500px;">
                                            <!--<![endif]-->
                                            <!--[if mso]><td class=t170 style="width:500px;"><![endif]-->
                                            <p class=t176
                                            style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:16px;font-weight:400;font-style:normal;font-size:11px;text-decoration:none;text-transform:none;direction:ltr;color:#777777;text-align:center;mso-line-height-rule:exactly;mso-text-raise:2px;">
                                                Copyright © ${new Date().getFullYear()} Webbeukers</p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </body>

        </html>
    `;
}

export const replaceTranslationTags = (html: string, translatFunction: any) => {
    // RegExp to find the tags [T] and [/T], including the content in between.
    const regex = /\[T](.*?)\[\/T]/g;

    // Replace the found tags and their content with the result of the function `t`.
    return html.replace(regex, (match, key) => {
        return translatFunction(key);
    });
}

export const dispatchQuoteModalOpen = (from_id = 'external_trigger_button') => {
    if (typeof window === 'undefined') return;
    
    const customEvent = new CustomEvent('triggerQuoteFormModal', {
        detail: {
            from_id: from_id
        }
    });
    
    if (window) {
        window.dispatchEvent(customEvent);
    }
}

export default sendQuoteFormEmail;