'use client'
import React, { useEffect, useRef, useState } from 'react';

import { Container, Typography } from '@mui/material';
import { ComponentProps, DefaultPageProps, Themes } from '@/lib/types/shared/page.types';
import { Image, ArrowWithVerticalText, ButtonNew } from '@/components/shared';
import Link from 'next/link';
import { useTranslations } from 'next-intl';
import { route } from '@/lib/navigationFunctions';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';

type Props = {
    id?: string,
    sliderItems: {
        image: {
            src: string,
            alt: string,
            width: number,
            height: number,
        }
        logo: {
            src: string,
            alt: string,
            width: number,
            height: number,
        },
        title: string,
        text: string,
    }[],
    mobileSliderImages?: {
        image: {
            src: string,
            alt: string,
            width: number,
            height: number,
        }
    }[],
    nextSectionId?: string,
    customBannerText?: string,
    addMoreCasesButton?: boolean,
    children?: React.ReactNode,
    theme?: Themes
} & DefaultPageProps & ComponentProps;

export default function SliderWithText({ id = '', sliderItems, mobileSliderImages, nextSectionId = '', customBannerText, addMoreCasesButton = false, theme = 'light', children, className = '', params }: Props) {
    const t = useTranslations('shared.slider_with_text');
    const t_global = useTranslations();

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const [intervalPaused, setIntervalPaused] = useState<boolean>(false);
    const [active, setActive] = useState(0);

    function handleInterval() {
        setActive(getNextIndex(active));
    }

    function getNextIndex(index: number) {
        if (index + 1 < sliderItems.length) {
            return index + 1;
        } else {
            return 0;
        }
    }

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(handleInterval, 3000);

        if (intervalPaused) {
            pauseInterval();
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [active]);

    const pauseInterval = () => {
        if (intervalRef.current) {
            setIntervalPaused(true);
            clearInterval(intervalRef.current);
        }
    };

    const resumeInterval = () => {
        if (intervalRef.current) {
            setIntervalPaused(false);
            intervalRef.current = setInterval(handleInterval, 3000);
        }
    };

    function needsProgress(index: number) {
        // return index === getNextIndex(active);
        return index === active;
    }

    const handlePrev = () => {
        if (active === 0) {
            setActive(sliderItems.length - 1);
        } else {
            setActive(active - 1);
        }
    };

    const handleNext = () => {
        if (active === sliderItems.length - 1) {
            setActive(0);
        } else {
            setActive(active + 1);
        }
    };

    return (
        <>
            <div id={id} className='px-5 scroll-mt-32'>
                {/* Visible on desktop */}
                <Container className={`hidden md:block relative ${className}`}>
                    <div className='absolute top-0 right-0 w-full md:w-4/5 h-full bg-primary-gray-950 z-0 flex items-end rounded-lg'>
                        <Image
                            src='Icons/Warranty_Circle_nadb5k'
                            alt='3 Months Warranty'
                            width={619}
                            height={619}
                            className='absolute -top-28 -left-20 animate-rotate size-60'
                        />

                        <div className='px-10 py-12  flex items-center gap-x-4'>
                            <Typography variant="body1" className='select-none text-white flex items-center gap-x-2 cursor-pointer' onClick={handlePrev}>
                                <Image
                                    src='Icons/Chevron_Left_Primary_dwpmqb'
                                    alt='Chevron Left Primary'
                                    width={16}
                                    height={16}
                                />

                                {t('previous')}
                            </Typography>

                            <div className={`select-none relative z-1 w-10 h-10 flex md:hidden justify-center items-center rounded-lg cursor-pointer bg-primary-yellow-400`}>
                                <Typography variant="body1" className='text-white font-semibold'>
                                    {active + 1}
                                </Typography>
                            </div>

                            <div className='hidden md:flex items-center relative'>
                                <div style={{
                                    transform: `translateX(${active * 2.5}rem)`
                                }} className='absolute top-0 w-10 h-10 bg-primary-yellow-400 rounded-lg z-0 transition-all duration-300' />

                                {Array.from({ length: sliderItems.length }, (_, index) => (
                                    <div key={index} className={`select-none relative z-1 w-10 h-10 flex justify-center items-center rounded-lg cursor-pointer`} onClick={() => setActive(index)}>
                                        <Typography variant="body1" className='text-white font-semibold'>
                                            {index + 1}
                                        </Typography>
                                    </div>
                                ))}
                            </div>

                            <Typography variant="body1" className='select-none text-white flex items-center gap-x-2 cursor-pointer' onClick={handleNext}>
                                {t('next')}

                                <Image
                                    src='Icons/Chevron_Right_Primary_g0q2jr'
                                    alt='Chevron Right Primary'
                                    width={16}
                                    height={16}
                                />
                            </Typography>
                        </div>
                    </div>

                    {nextSectionId && (
                        <div className='absolute top-0 right-0 w-fit h-full z-10 flex justify-end items-end'>
                            <ArrowWithVerticalText text={customBannerText ? customBannerText : t('banner_text')} link={`#${nextSectionId}`} theme={theme} params={params} />
                        </div>
                    )}

                    <div className="pt-16 pb-32">
                        <div className='w-full overflow-x-hidden overflow-y-visible'>
                            <div className="flex" style={{
                                width: `${sliderItems.length * 100}%`
                            }}>
                                {sliderItems.map((item, index) => (
                                    <div key={index} className="w-full grid grid-cols-1 md:grid-cols-3 gap-x-16 gap-y-8 md:pr-32 min-h-400px transition-all duration-300" style={{
                                        transform: `translateX(${active * -100}%)`
                                    }}>
                                        <div className="md:col-span-2 h-fit">
                                            <Image
                                                src={item.image.src}
                                                alt={item.image.alt}
                                                width={item.image.width}
                                                height={item.image.height}
                                                className='w-full aspect-video block object-cover object-left'
                                            />
                                        </div>

                                        <div className="flex flex-col gap-4">
                                            <Image
                                                src={item.logo.src}
                                                alt={item.logo.alt}
                                                width={item.logo.width}
                                                height={item.logo.height}
                                                className='w-fit h-fit object-contain'
                                            />

                                            <div>
                                                <MoveFadeInAnimation direction='right'>
                                                    <Typography variant="h4" className={`${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'} capitalize font-semibold mt-4`}>
                                                        {item.title}
                                                    </Typography>
                                                </MoveFadeInAnimation>

                                                <Typography variant="body1" className={`${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'}`}>
                                                    {item.text}
                                                </Typography>
                                            </div>

                                            {addMoreCasesButton && (
                                                <ButtonNew text={t_global('shared.view_more_cases')} link={route('/cases', params.locale)} theme='dark' className='mt-auto mb-4' />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            {/* Visible on mobile */}
            <div data-interval-paused={intervalPaused} className='block md:hidden slider-with-text'>
                <Image
                    key={active}
                    src={mobileSliderImages ? mobileSliderImages[active].image.src : sliderItems[active].image.src}
                    alt={mobileSliderImages ? mobileSliderImages[active].image.alt : sliderItems[active].image.alt}
                    width={mobileSliderImages ? mobileSliderImages[active].image.width : sliderItems[active].image.width}
                    height={mobileSliderImages ? mobileSliderImages[active].image.height : sliderItems[active].image.height}
                    className='mx-auto block object-cover object-left animate-fade-in'
                    onMouseEnter={pauseInterval}
                    onMouseLeave={resumeInterval}
                />

                <div className='w-full'>
                    <div className='w-full px-10 py-12 flex justify-between items-center gap-x-4'>
                        <Typography variant="body1" className='select-none text-primary-gray-950 flex items-center gap-x-2 cursor-pointer' onClick={handlePrev}>
                            <Image
                                src='Icons/Chevron_Left_Primary_dwpmqb'
                                alt='Chevron Left Primary'
                                width={16}
                                height={16}
                            />

                            {t('previous')}
                        </Typography>

                        <div className='flex items-center relative'>
                            <div data-animate-progress={needsProgress(active)} data-item-active={active === active} className={`select-none relative z-1 w-10 h-10 rounded-lg cursor-pointer bg-primary-gray-950 overflow-hidden`}>
                                <Typography variant="body1" className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white font-semibold mix-blend-difference'>
                                    {active + 1}
                                </Typography>

                                <div key={active} data-item-progress className='origin-left h-full bg-white'></div>
                            </div>
                        </div>

                        <Typography variant="body1" className='select-none text-primary-gray-950 flex items-center gap-x-2 cursor-pointer' onClick={handleNext}>
                            {t('next')}

                            <Image
                                src='Icons/Chevron_Right_Primary_g0q2jr'
                                alt='Chevron Right Primary'
                                width={16}
                                height={16}
                            />
                        </Typography>
                    </div>
                </div>

                <div className='mb-16 px-5'>
                    <Container key={active}>
                        <Typography variant="h2" className={`${theme === 'dark' ? 'text-white' : 'text-primary-gray-950'} capitalize font-semibold mt-4 animate-fade-in`}>
                            {sliderItems[active].title}
                        </Typography>

                        <Typography variant="body1" className={`${theme === 'dark' ? 'text-white' : 'text-primary-gray-950'} animate-fade-in`}>
                            {sliderItems[active].text}
                        </Typography>

                        <Link href={route('/contact', params.locale)} className="flex items-center group w-fit mt-4">
                            <div className="size-20 relative">
                                <div className="size-20 border-2 border-primary-yellow-400 rounded-full group-hover:scale-5 transition-all duration-300" />

                                <div className="size-2 bg-primary-yellow-400 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-300 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                            </div>

                            <Typography variant="body1" className="cursor-pointer text-primary-gray-950 group-hover:text-primary-yellow-400 transition-all duration-300 bg-white py-3 -ml-6 relative z-10">{t_global('contact_us')}</Typography>
                        </Link>
                    </Container>
                </div>
            </div>
        </>
    );
}