'use client';

import { DefaultPageProps, ComponentProps, Themes } from '@/lib/types/shared/page.types';
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import Image from '@/components/shared/Image';
import { Typography } from '@mui/material';

type Props = {
    text: string;
    link: string;
    theme: Themes;
    className?: string;
} & DefaultPageProps & ComponentProps;

export default function ArrowWithVerticalText({ text, link, theme, className = '', params }: Props) {
    const arrowRef = useRef<HTMLImageElement>(null);

    const [windowWidth, setWindowWidth] = useState(0);
    
    const setArrowAnimation = () => {
        arrowRef.current?.classList.add('animate-arrow-down');
    };

    const removeArrowAnimation = () => {
        arrowRef.current?.classList.remove('animate-arrow-down');
    };

    useEffect(() => {
        const handleResize = () => {
            if (arrowRef.current) {
                if (window.innerWidth < 768) {
                    setArrowAnimation();
                } else {
                    removeArrowAnimation();
                }

                setWindowWidth(window.innerWidth);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Link href={link} className={`w-fit h-fit hidden md:flex flex-col px-2 py-6 mr-4 ${className}`} onMouseEnter={windowWidth < 768 ? () => { } : () => setArrowAnimation()} onMouseLeave={windowWidth < 768 ? () => { } : () => removeArrowAnimation()}>
            <Typography variant="h3" className={`text-3xl ${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'} font-semibold m-2 capitalize text-nowrap whitespace-nowrap`} style={{
                writingMode: 'vertical-rl',
                transform: 'scale(-1)'
            }}>
                {text}
            </Typography>

            <div ref={arrowRef} className='ml-auto -mt-full'>
                <Image
                    src={`${theme === 'dark' ? 'Icons/long-arrow-down_zznbil' : 'Icons/long-arrow-down-white_yx6ayy'}`}
                    alt='long-arrow-down'
                    width={16}
                    height={101}
                />
            </div>
        </Link>
    );
}
