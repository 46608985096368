'use client';

import { ComponentProps, DefaultPageProps, Themes } from '@/lib/types/shared/page.types';
import { Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@/components/shared';

type Props = {
    items: {
        title: string,
        text: string,
        icon?: {
            src: string,
            alt: string,
            width: number,
            height: number,
        },
        link: string
    }[],
    animationDuration?: number,
    theme?: Themes
} & ComponentProps & DefaultPageProps;

export default function InfoBlocksSlider({ items, animationDuration = 3000, theme = 'light', className = '', params }: Props) {
    const t = useTranslations();

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const [intervalPaused, setIntervalPaused] = useState<boolean>(false);
    const [active, setActive] = useState<number>(0);

    function handleInterval() {
        setActive(getNextIndex(active));
    }

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(handleInterval, animationDuration);

        if (intervalPaused) {
            pauseInterval();
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [active]);

    const pauseInterval = () => {
        if (intervalRef.current) {
            setIntervalPaused(true);
            clearInterval(intervalRef.current);
        }
    };

    const resumeInterval = () => {
        if (intervalRef.current) {
            setIntervalPaused(false);
            intervalRef.current = setInterval(handleInterval, animationDuration);
        }
    };

    function needsProgress(index: number) {
        return index === active;
    }

    function getNextIndex(index: number) {
        if (index + 1 < items.length) {
            return index + 1;
        } else {
            return 0;
        }
    }

    return (
        <>
            <div data-interval-paused={intervalPaused} className={`info-blocks w-fit flex gap-3 cursor-default ${className}`} onMouseEnter={pauseInterval} onMouseLeave={resumeInterval}>
                {items.map((item, index) => (
                    <div key={index} onClick={() => setActive(index)} data-animate-progress={needsProgress(index)} data-item-active={index === active} className='w-70 flex flex-col gap-y-2 px-4 py-3 bg-gradient-to-r from-white-t-10 to-transparent backdrop-blur-2xl relative rounded-lg transition-all duration-300' style={{
                        transform: active !== 0 ? `translateX(calc(-${active * 100}% - ${active * 0.75}rem))` : 'translateX(0)'
                    }}>
                        <div data-item-progress style={{
                            animationDuration: `${animationDuration}ms!important`
                        }} className="bg-primary-gray-950 absolute top-0 left-0 z-1 h-full bg-gradient-to-r from-white-t-10 to-transparent backdrop-blur-2xl transition-all duration-300 rounded-lg"></div>
                        
                        <div className='relative z-10'>
                            <div className='flex gap-x-3 justify-between'>
                                <Typography variant='h4' className={`${theme == 'dark' ? 'text-primary-gray-950' : 'text-white'} capitalize font-semibold text-nowrap whitespace-nowrap sm:text-2xl`}>
                                    {item.title}
                                </Typography>

                                {item.icon && (
                                    <div className='bg-primary-gray-950 h-fit rounded-lg p-1 flex justify-center items-center'>
                                        <Image
                                            src={item.icon.src}
                                            alt={item.icon.alt}
                                            width={item.icon.width}
                                            height={item.icon.height}
                                            className='w-16 h-auto object-contain'
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

                {/* Add duplicates to make it look like an infinite slider */}
                {items.map((item, index) => (
                    <div key={index} onClick={() => setActive(index)} data-animate-progress={needsProgress(index)} data-item-active={index === active} className='w-70 flex flex-col gap-y-2 px-4 py-3 bg-gradient-to-r from-white-t-10 to-transparent backdrop-blur-2xl relative rounded-lg transition-all duration-300' style={{
                        transform: active !== 0 ? `translateX(calc(-${active * 100}% - ${active * 0.75}rem))` : 'translateX(0)'
                    }}>
                        <div data-item-progress style={{
                            animationDuration: `${animationDuration}ms!important`
                        }} className="bg-primary-gray-950 absolute top-0 left-0 z-1 h-full bg-gradient-to-r from-white-t-10 to-transparent backdrop-blur-2xl transition-all duration-300 rounded-lg"></div>
                        
                        <div className='relative z-10'>
                            <div className='flex gap-x-3 justify-between'>
                                <Typography variant='h4' className={`${theme == 'dark' ? 'text-primary-gray-950' : 'text-white'} capitalize font-semibold text-nowrap whitespace-nowrap sm:text-2xl`}>
                                    {item.title}
                                </Typography>

                                {item.icon && (
                                    <div className='bg-primary-gray-950 h-fit rounded-lg p-1 flex justify-center items-center'>
                                        <Image
                                            src={item.icon.src}
                                            alt={item.icon.alt}
                                            width={item.icon.width}
                                            height={item.icon.height}
                                            className='w-16 h-auto object-contain'
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
