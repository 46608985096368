import React from 'react';

import { Params, DefaultPageProps, Themes } from "@/lib/types/shared/page.types";
import ImageCarousel from "@/components/shared/ImageCarousel";

import { Container, Typography } from '@mui/material';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import HeadingText from '@/components/shared/HeadingText';

type Image = {
    src: string;
    alt: string;
    className?: string;
}

interface Props extends DefaultPageProps {
    params: Params;
    title: string;
    text: string;
    images: Image[];
    className?: string;
    sliderPosition: string;
    theme?: Themes;
}

export default function SliderBlock({ title, text, images, className, sliderPosition, theme = 'dark' }: Props) {
    return (
        <Container className={`flex items-center flex-col-reverse ${sliderPosition === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'} gap-5 md:gap-0`}>
            <div className="w-full md:w-2/3">
                <ImageCarousel
                    images={images}
                    activeImageWidth='w-full'
                    className={className}
                />
            </div>
            <div className="w-full md:w-1/3">
                <MoveFadeInAnimation delay={0} direction="right" distance="xl" amount={0.5}>
                    <HeadingText variant="h3" className={`text-start mb-4 ${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`}>{title} </HeadingText>
                </MoveFadeInAnimation>
                <MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
                    <Typography className={`text-start normal-case max-w-screen-md ${theme === 'light' ? 'text-white' : 'text-primary-gray-950'}`} variant='body1'>
                        {text}
                    </Typography>
                </MoveFadeInAnimation>
            </div>
        </Container>
    );
}