import React, { useRef } from "react";

import Image from "@/components/shared/Image";

type Props = {
    images?: Array<string>
};

const imagesHTML = ({ images }: { images?: Array<string> }) => {
  return (
    <div className="gap-20 flex justify-between scroll-to-right-slow">
      {images !== undefined && images !== null &&
        images.map((image, index) => (
          <div className="m-auto max-w-dvw-1/2 md:max-w-dvw-2/3 aspect-video w-650px" key={index}>
            <Image
              loading="eager"
              src={image}
              alt="Website banner with logo's of websites we've made"
              width={600}
              height={400}
              multiply={true}
              className="object-cover w-full h-full rounded-xl"
            />
          </div>
        ))
      }
    </div>
  )
};

const WebsiteBanner = ({ images }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
	
	const pauseAnimation = () => {
		if (ref.current !== null && ref.current !== undefined) {
			ref.current.childNodes.forEach((child: any) => {
				child.style.animationPlayState = 'paused';
			});
		}
	};
	
	const playAnimation = () => {
		if (ref.current !== null && ref.current !== undefined) {
			ref.current.childNodes.forEach((child: any) => {
				child.style.animationPlayState = 'running';
			});
		}
	};

  return (
    <div className="max-w-full mx-auto relative">
      {/* <div className="absolute top-1/2 left-0 -translate-y-1/2 bg-primary-gray-950-to-transparent-right w-20 h-full z-10" /> */}

      <div ref={ref} className="w-full overflow-hidden flex gap-20" onMouseEnter={pauseAnimation} onMouseLeave={playAnimation}>
        {imagesHTML({ images })}
        {imagesHTML({ images })}
        {imagesHTML({ images })}
      </div>

      {/* <div className="absolute top-1/2 right-0 -translate-y-1/2 bg-primary-gray-950-to-transparent-left w-20 h-full z-10" /> */}
    </div>
  );
};

export default WebsiteBanner;