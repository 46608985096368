import client from "@/lib/api/ApiClient";

export const submitMailchimp = (Email: string, Name: string, Phone: string, Tag = 'WebBeukers') => client.post("/mailchimp/subscribe", {
    Name,
    Email,
    Phone,
    Tag
}, { headers: { 'wb-email-token': process.env.NEXT_API_HEADER_TOKEN ?? ''} });

const mailchimpApi = {
    submitMailchimp
};

export default mailchimpApi;