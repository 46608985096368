'use client';

import { ComponentProps, DefaultPageProps, Themes } from '@/lib/types/shared/page.types';
import { Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Image } from '@/components/shared';

type Props = {
    items: {
        title: string,
        text: string,
        icon?: {
            src: string,
            alt: string,
            width: number,
            height: number,
        },
        link: string
    }[],
    hideOnMobile?: boolean,
    alignment?: 'horizontal' | 'vertical',
    animationDuration?: number,
    theme?: Themes
} & ComponentProps & DefaultPageProps;

export default function InfoBlocks({ items, hideOnMobile = false, alignment = 'horizontal', animationDuration = 3000, theme = 'light', className = '', params }: Props) {
    const t = useTranslations();

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const [intervalPaused, setIntervalPaused] = useState<boolean>(false);
    const [active, setActive] = useState<number>(0);

    function handleInterval() {
        setActive(getNextIndex(active));
    }

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(handleInterval, animationDuration);

        if (intervalPaused) {
            pauseInterval();
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [active]);

    const pauseInterval = () => {
        if (intervalRef.current) {
            setIntervalPaused(true);
            clearInterval(intervalRef.current);
        }
    };

    const resumeInterval = () => {
        if (intervalRef.current) {
            setIntervalPaused(false);
            intervalRef.current = setInterval(handleInterval, animationDuration);
        }
    };

    function needsProgress(index: number) {
        return index === getNextIndex(active);
    }

    function getNextIndex(index: number) {
        if (index + 1 < items.length) {
            return index + 1;
        } else {
            return 0;
        }
    }

    return (
        <>
            <div data-interval-paused={intervalPaused} className={`info-blocks -mt-16 w-fit ${hideOnMobile ? 'hidden md:flex' : 'flex'} ${alignment == 'vertical' ? 'flex-col' : 'flex-row'} gap-3 cursor-default ${className}`} onMouseEnter={pauseInterval} onMouseLeave={resumeInterval}>
                {items.map((item, index) => (
                    <div key={index} onClick={() => setActive(index)} data-animate-progress={needsProgress(index)} data-item-active={index === active} className='flex flex-col gap-y-2 w-full px-4 py-3 bg-gradient-to-r from-white-t-10 to-transparent backdrop-blur-2xl relative rounded-lg'>
                        <div data-item-progress style={{
                            animationDuration: `${animationDuration}ms!important`
                        }} className="absolute top-0 left-0 z-1 h-full bg-gradient-to-r from-white-t-10 to-transparent backdrop-blur-2xl transition-all duration-300 rounded-lg"></div>

                        <div className='relative z-10'>
                            <div className='flex gap-x-2 justify-between'>
                                <Typography variant='h5' component='h2' className={`${theme == 'dark' ? 'text-primary-gray-950' : 'text-white'} capitalize font-semibold`}>
                                    {item.title}
                                </Typography>

                                {item.icon && (
                                    <div className='bg-primary-gray-950 h-fit rounded-lg p-1 flex justify-center items-center'>
                                        <Image
                                            src={item.icon.src}
                                            alt={item.icon.alt}
                                            width={item.icon.width}
                                            height={item.icon.height}
                                            className='w-16 h-auto object-contain'
                                        />
                                    </div>
                                )}
                            </div>

                            <div data-item-text className={`overflow-hidden transition-all duration-1000`}>
                                <Typography variant='body1' className={`${theme == 'dark' ? 'text-primary-gray-950' : 'text-white'} mt-1`}>
                                    {item.text}
                                </Typography>

                                {item.link && (
                                    <Link href={item.link} className={`w-fit group ${theme == 'dark' ? 'text-primary-gray-950' : 'text-white'} font-semibold mt-2 flex items-center gap-x-2`}>
                                        {t('view')}

                                        <Image
                                            src='Icons/arrow-primary_ghdyod'
                                            alt='arrow'
                                            width={16}
                                            height={16}
                                            className='-rotate-45 group-hover:rotate-0 transition-all duration-300'
                                        />
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
