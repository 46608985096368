'use client';

import { FC, useRef, HTMLAttributes } from 'react';
import { Grid, Typography, Link } from '@mui/material';

import Image from '@/components/shared/Image';
import HeadingText from '@/components/shared/HeadingText';
import { Themes } from '@/lib/types/shared/page.types';

export interface ArrowCardProps extends HTMLAttributes<HTMLButtonElement | HTMLLinkElement | HTMLAnchorElement> {
    height?: string,
    type?: string,
    icon?: string,
    title?: string,
    text?: string,
    arrow?: boolean,
    link?: string,
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
    xl?: number,
    padding?: string,
    className?: string,
    titleClassName?: string,
    lineClamp?: number,
    alt?: string,
    theme?: Themes;
    textColor?: string;
}

const ArrowCard: FC<ArrowCardProps> = ({ theme = 'dark', textColor = 'text-white', height, type, icon, title, text, arrow = true, link, xs, sm, md, lg, xl, padding, className, titleClassName, lineClamp, alt = "" }: ArrowCardProps) => {
    const arrowRef = useRef<HTMLImageElement>(null);

    const setArrowAnimation = () => {
        if (arrow) {
            arrowRef.current?.classList.add('animate-arrow');
        }
    };

    const removeArrowAnimation = () => {
        if (arrow) {
            arrowRef.current?.classList.remove('animate-arrow');
        }
    };

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={`${padding ? padding : 'p-3'} ${height == 'full' ? 'h-auto' : 'h-full'} ${className}`}>
            {link && link !== "" ?
                <Link href={link} className={'no-underline'} onMouseEnter={() => setArrowAnimation()} onMouseLeave={() => removeArrowAnimation()}>
                    <div className={`${theme === 'transparent' ? 'bg-transparent' : 'product-card-gradient'} flex flex-col py-5 px-8 rounded-2xl h-full`}>
                        {type === 'inside' &&
                            <HeadingText variant='h4' component='h2' className={`mb-2 ${textColor}`}>{title}</HeadingText>
                        }

                        <Typography variant='body1' className={`${textColor} ${lineClamp ? `line-clamp-${lineClamp}` : ''}`}>
                            {text}
                        </Typography>

                        {arrow &&
                            <div ref={arrowRef} className='ml-auto mt-auto mb-3 h-auto'>
                                <Image
                                    loading='lazy'
                                    src="Icons/arrow-primary_ghdyod"
                                    alt="Arrow icon"
                                    width={22}
                                    height={24}
                                    className='block'
                                />
                            </div>
                        }
                    </div>
                </Link>
            :
                <div className={`${theme === 'transparent' ? 'bg-transparent' : 'bg-gradient-to-r from-primary-gray-800 to-transparent'} flex flex-col py-5 px-8 rounded-2xl h-full`} onMouseEnter={() => setArrowAnimation()} onMouseLeave={() => removeArrowAnimation()}>
                    {type === 'inside' &&
                        <HeadingText variant='h4' component='h2' className={`mb-2 ${textColor} ${titleClassName}`}>{title}</HeadingText>
                    }

                    <Typography variant='body1' className={`${textColor} ${lineClamp ? `line-clamp-${lineClamp}` : ''}`}>
                        {text}
                    </Typography>

                    {arrow &&
                        <div ref={arrowRef} className='ml-auto mt-auto mb-3 h-auto'>
                            <Image
                                loading='lazy'
                                src="Icons/arrow-primary_ghdyod"
                                alt="Arrow icon"
                                width={22}
                                height={24}
                                className='block'
                            />
                        </div>
                    }
                </div>
            }
        </Grid>
    );
};

export default ArrowCard;