'use client'

import { route } from '@/lib/navigationFunctions';
import { ComponentProps, Params } from '@/lib/types/shared/page.types';
import { useTranslations } from 'next-intl';
import { Link } from '@/lib/navigation';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useRef, useEffect } from 'react';
import { Container } from '@mui/material';
import { ButtonOne, Image } from '@/components/shared';

type Props = {
    params: Params;
};

export default function MobileNavDropdown({ params }: Props) {
    const t = useTranslations();

    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    function handleClick() {
        setIsOpen(!isOpen);
    }

    function handleOutsideClick(event: MouseEvent) {
        if (
            !wrapperRef.current?.contains(event.target as Node) && 
            !buttonRef.current?.contains(event.target as Node) && 
            !dropdownRef.current?.contains(event.target as Node)) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, [
        wrapperRef,
        buttonRef,
        dropdownRef,
        isOpen,
    ]);

    return (
      <>
        <div className='flex items-center gap-2'>
            <div className='flex items-center gap-2'>
                <div
                    ref={wrapperRef}
                    onClick={handleClick}
                    className="c-secondary c-h-secondary cursor-pointer"
                >
                    <div ref={buttonRef} className='w-fit h-fit pointer-events-none'>
                        <ButtonOne 
                            text={''} 
                            type='button' 
                            variant='border-transparent' 
                            paddingX='small' 
                            hoverEffect={true}>

                            {isOpen ? (
                                <CloseIcon className='font-bold' />
                            ) : (
                                <MenuIcon className='font-bold' />
                            )}
                        </ButtonOne>
                    </div>
                        
                    {isOpen && (
                        <>
                            <div 
                                ref={dropdownRef}
                                className={`${isOpen ? 'block' : 'hidden'} absolute origin-top-right w-full -z-1 h-screen-mobile-min-26 rounded-b-lg top-14 left-0 right-0 py-5 bg-primary-gray-950 overflow-y-auto`}
                            >
                                <Container className='flex flex-col gap-y-4 pt-4'>
                                    <Link className='text-2xl font-semibold border-b border-white' href={"/"}>{t('home')}</Link>
                                    <Link className='text-2xl font-semibold border-b border-white ml-1/10' href={"/website-creation"}>{t('websites')}</Link>
                                    <Link className='text-2xl font-semibold border-b border-white ml-1/10' href={"/development"}>{t('development')}</Link>
                                    <Link className='text-2xl font-semibold border-b border-white ml-1/10' href={"/optimizations"}>{t('optimizations')}</Link>
                                    <Link className='text-2xl font-semibold border-b border-white ml-1/10' href={"/maintenance"}>{t('maintenance')}</Link>
                                    <Link className='text-2xl font-semibold border-b border-white ml-1/10' href={"/marketing-transformation"}>{t('marketing')}</Link>
                                    <Link className='text-2xl font-semibold border-b border-white' href={"/cases"}>{t('cases')}</Link>
                                    <Link className='text-2xl font-semibold border-b border-white' href={"/about"}>{t('about_us')}</Link>
                                    <Link className='text-2xl font-semibold border-b border-white' href={"/contact"}>{t('contact')}</Link>
                                    {/* <Link href={"/concept"}>{t('faq')}</Link> */}
                                </Container>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
      </>
    )
}
