'use client';

import React from "react";

import { Container, Typography } from "@mui/material";
import { MoveFadeInAnimation } from "@/lib/hooks/use-animations";

import { convertMarkdownLinks } from "@/lib/utils";
import { ComponentProps, DefaultPageProps, Themes } from "@/lib/types/shared/page.types";

import HeadingText, { DefaultHeadingClasses } from "@/components/shared/HeadingText";
import { Image, ButtonNew } from "@/components/shared";

type Props = {
    sectionId?: string,
    title? : string | Array<string>,
    convertTitle?: boolean,
    text? : string | Array<string>,
    convertTextToLink?: boolean,
    buttons? : Array<any>,
    ul? : Array<any>,
    theme?: Themes,
    children? : React.ReactNode
} & DefaultPageProps & ComponentProps;

const NormalSection = ({ sectionId = '', title, titleVariant = "h3", convertTitle = false, text, convertTextToLink = false, buttons, ul, className = '', children, theme = 'light', params }: Props) => {
    return (
        <section id={sectionId ?? ''} className={`py-16 scroll-mt-16 ${className}`}>
            <Container>
                {title &&
                    typeof title === 'object' ?
                        title.map((title, index) => (
                            <MoveFadeInAnimation key={index} direction="right" distance="xl" amount={0.5}>
                                <HeadingText className={`mb-4 ${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'}`} variant={titleVariant}>
                                    {convertTitle ?
                                        <span className={`${DefaultHeadingClasses} mb-4`} dangerouslySetInnerHTML={{
                                            __html: convertMarkdownLinks(title, params.locale)
                                        }}></span>
                                    : title}
                                </HeadingText>
                            </MoveFadeInAnimation>
                        ))
                    :
                        <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                            <HeadingText className={`mb-4 ${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'}`} variant={titleVariant}>
                                {convertTitle ?
                                    <span className={`${DefaultHeadingClasses} mb-4`} dangerouslySetInnerHTML={{
                                        __html: convertMarkdownLinks(title ?? '', params.locale)
                                    }}></span>
                                : title}
                            </HeadingText>
                        </MoveFadeInAnimation>
                }
                {text ?
                    typeof text === 'object' ? (
                        text.map((singleText, index) => (
                            <MoveFadeInAnimation key={index} delay={200 * index} direction="right" distance="xl" amount={0.5}>
                                <Typography className={`text-start normal-case max-w-screen-md mb-5 ${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'}`} variant='body1'>
                                    {convertTextToLink ?
                                        <span className={`${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'} text-start normal-case max-w-screen-md`} dangerouslySetInnerHTML={{
                                            __html: convertMarkdownLinks(singleText, params.locale)
                                        }}></span>
                                    : singleText}
                                </Typography>
                            </MoveFadeInAnimation>
                        ))
                    ) : (
                        <Typography className={`text-start normal-case max-w-screen-md ${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'}`} variant='body1'>
                            {convertTextToLink ?
                                <span className={`${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'} text-start normal-case max-w-screen-md`} dangerouslySetInnerHTML={{
                                    __html: convertMarkdownLinks(text, params.locale)
                                }}></span>
                            : text}
                        </Typography>
                    )
                : null}
                {buttons !== undefined &&
                    <div className="mt-5 flex flex-wrap gap-4">
                        {buttons.map((button, index) => (
                            <MoveFadeInAnimation key={index} delay={400 * index} direction="right" distance="xl" amount={0.5}>
                                <ButtonNew key={index} text={button.text} link={button.link} />
                            </MoveFadeInAnimation>
                        ))}
                    </div>
                }

                {ul &&
                    <div className='flex w-full lg:w-2/3'>
                        <ul className="list-disc list-inside mt-5 max-w-17/20">
                            {ul.map((item, index) => (
                                <li key={index} className="flex gap-3 my-2">
                                    {!item.icon || item.icon === '' ? '' :
                                        <Image
                                            width={item.iconSize ? item.iconSize : 20}
                                            height={item.iconSize ? item.iconSize : 20}
                                            loading="lazy"
                                            className={`${item.iconSize ? '' : 'w-5'} h-fit mt-1`}
                                            src={item.icon}
                                            alt={item.alt ?? 'List icon'}
                                        />
                                    }
                                    <Typography className={`text-start normal-case max-w-screen-md ${theme === 'dark' ? 'text-primary-gray-950' : 'text-white'}`} variant='body1'>
                                        <span className="font-bold">{item.title}</span> {item.text}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </div>
                }

                {children &&
                    <div className={`${!title && !text ? '' : 'mt-5'}`}>
                        {children}
                    </div>
                }
            </Container>
        </section>
    )
};

export default NormalSection;