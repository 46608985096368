export const dispatchPackagesModalOpen = (from_id = '') => {
    if (typeof window === 'undefined') return;

    if (!from_id) return;
    
    const customEvent = new CustomEvent('triggerPackagesModal', {
        detail: {
            from_id: from_id
        }
    });
    
    if (window) {
        window.dispatchEvent(customEvent);
    }
}
