'use client';

import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import { Params } from '@/lib/types/shared/page.types';
import { convertMarkdownLinks } from '@/lib/utils';
import { Typography } from '@mui/material';
import React from 'react'
import HeadingText from '@/components/shared/HeadingText';

type Props = {
	params: Params;
	title: string;
	titleColor?: string;
	text: string;
	textColor?: string;
	titleVariant?: any;
	textVariant?: any;
	convertTextToLink?: boolean;
}

export default function GridTextItem({ params, title, titleColor = 'text-white', text, textColor = 'text-white', titleVariant = "h3", textVariant = 'body1', convertTextToLink = false }: Props) {
	return (
		<>
			<div>
				<HeadingText className={`mb-2 ${titleColor}`} variant={titleVariant}>
					{title}
				</HeadingText>

				<Typography className={`text-start normal-case max-w-screen-md ${textColor}`} variant={textVariant}>
					{convertTextToLink ?
						<span className={`text-start normal-case max-w-screen-md ${textColor}`} dangerouslySetInnerHTML={{
							__html: convertMarkdownLinks(text!, params.locale)
						}}></span>
					: text}
				</Typography>
			</div>
		</>
	)
}
