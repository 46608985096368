'use client';

import React, { useRef, useState, useEffect } from "react";

import { pricingTabs } from "@/lib/constants/websites";

import { Container, Grid, Typography } from "@mui/material";
import Item from '@mui/material/Grid';

import { NPM_ButtonOne, NPM_TabButtons, NPM_QuotePopup } from "@/components/shared";
import { useTranslations } from 'next-intl';
import { MoveFadeInAnimation } from "@/lib/hooks/use-animations";
import QuoteForm from "@/components/shared/forms/QuoteForm";

import { Params } from "@/lib/types/shared/page.types";
import Image from "@/components/shared/Image";
import { dispatchQuoteModalOpen } from "@/lib/helpers/forms/QuoteFormHelper";

import HeadingText from "@/components/shared/HeadingText";

type Props = {
    params: Params;
  };
const PricingSection = ({params}:Props) => {
    const t = useTranslations();

    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(0);

    const [activeValue, setActiveValue] = useState(0);

    const [name, setName] = useState('');
    const [type, setType] = useState('');

    const ref = useRef<HTMLDivElement>(null);

    const toggleQuotePopup = () => {
        setOpen(!open);
    };

    const setValues = (name: string, type: string) => {
        setName(name);
        setType(type);

        toggleQuotePopup();
    };

    const isEven = (n: number) => {
        return n % 2 === 0;
    };

    useEffect(() => {
        if (open) {
            document.querySelector("html")!.style.overflow = 'hidden';
        } else {
            document.querySelector("html")!.style.overflow = 'auto';
        }
    }, [open])
    

    useEffect(() => {
        ref.current?.addEventListener('animationend', () => {
            if (ref.current !== null && ref.current !== undefined) {
                ref.current?.removeEventListener('animationend', () => {
                    if (ref.current !== null && ref.current !== undefined) {
                        updateActive();
                    }
                });

                updateActive();
            }
        });
    }, [active]);

    const updateActive = () => {
        if (active + 1 >= pricingTabs.length) {
            setActive(0);
            setActiveValue(0);
            return;
        }

        setActive(active + 1);
        setActiveValue(0);
    };

    const pauseAnimation = (index: number) => {
        if (index === active) {
            if (ref.current !== null && ref.current !== undefined) {
                ref.current.style.animationPlayState = 'paused';
            }
        }
    };

    const playAnimation = (index: number) => {
        if (index === active) {
            if (ref.current !== null && ref.current !== undefined) {
                ref.current.style.animationPlayState = 'running';
            }
        }
    };

    return (
        <section className="py-16">
            <Container>
                {open && (
                    <NPM_QuotePopup
                        open={open}
                        onclick={toggleQuotePopup}
                        title={t("request_a_quote")}
                        text={t("we_will_contact_you_within_2_working_days_or_provide_you_with_a_quote")}
                        form={<QuoteForm name={name} type={type} params={params} />}
                    />
                )}

                <Grid container>
                    <Item item xs={12} xl={12} className="flex gap-2">
                        <div className="flex flex-col mx-auto gap-2 w-full md:w-fit max-w-md">
                            <div className="flex justify-between flex-wrap">
                                {pricingTabs.map((tab, index) => (
                                    <MoveFadeInAnimation key={index} delay={300 * index} direction="right" distance="xl" amount={0.5}>
                                        <div className="w-fit m-auto rounded-xl relative overflow-hidden">
                                            <NPM_ButtonOne type="button" variant={index === active ? 'primary' : 'transparent'} hoverEffect={false} text={t(tab.name)} onclick={updateActive} />

                                            {index === active &&
                                                <div className="absolute progress-animation bottom-0 bg-primary-yellow-400 h-5px" ref={index === active ? ref : null} />
                                            }
                                        </div>
                                    </MoveFadeInAnimation>
                                ))}
                            </div>
                            <div className="w-full mx-auto block md:hidden" data-active-btn={activeValue} onMouseOver={() => pauseAnimation(active)} onMouseOut={() => playAnimation(active)}>
                                <NPM_TabButtons variant="primary" count={pricingTabs[active].values.length}>
                                    {pricingTabs[active].values.map((value, index) => (
                                        <NPM_ButtonOne key={index} type="button" variant={index === activeValue ? 'primary' : 'transparent'} hoverEffect={false} text={t(value.shortLabel)} onclick={() => setActiveValue(index)} className="sm:w-1/3 line-clamp-1 px-3 capitalize" />
                                    ))}
                                </NPM_TabButtons>
                            </div>
                        </div>

                    </Item>
                    <Item item xs={12} xl={12}>
                        <Grid container columns={4} className="mt-5 rounded-2xl overflow-hidden" onMouseOver={() => pauseAnimation(active)} onMouseOut={() => playAnimation(active)} sx={{
                            // background: "linear-gradient(70deg, rgba(255, 255, 255, 0.10) 24.42%, rgba(255, 255, 255, 0.00) 102.47%)",
                            // boxShadow: "1.5px 3px 22px 0px rgba(0, 0, 0, 0.10)",
                            // backdropFilter: "blur(25px)"
                        }}>
                            <Grid item xs={4} md={2} lg={1} sx={{
                                // background: "linear-gradient(70deg, rgba(255, 255, 255, 0.10) 24.42%, rgba(255, 255, 255, 0.00) 102.47%)",
                                // boxShadow: "1.5px 3px 22px 0px rgba(0, 0, 0, 0.10)",
                                // backdropFilter: "blur(25px)"
                            }} className="pb-10">
                                <HeadingText variant="h4" className="text-center my-8"> &nbsp; </HeadingText>

                                {pricingTabs[active].lines.map((line, index) => (
                                    <div key={index} className="w-full flex justify-between border-b border-[#ffffff50] px-4">
                                        <Typography variant="body1">
                                            {t(line.name)}
                                        </Typography>

                                        <Typography variant="body1" className="block md:hidden">
                                            {line.values[activeValue] === false ?
                                                "-"
                                            : line.values[activeValue] === true ?
                                                <Image
                                                    width={20}
                                                    height={20}
                                                    loading="lazy"
                                                    src="Icons/checkmark_mqztby"
                                                    className="w-20px h-auto my-2px"
                                                    alt="Checkmark"
                                                />
                                            : (
                                                (line.values[activeValue] as string).includes('[UNTRANSLATE]') ? (line.values[activeValue] as string).replace('[UNTRANSLATE]', '') : t(line.values[activeValue])
                                            )}
                                        </Typography>
                                    </div>
                                ))}

                                {/* <div className="flex md:hidden flex-col items-center">
                                    <NPM_ButtonOne type="button" variant="primary" onclick={() => dispatchQuoteModalOpen(pricingTabs[active].values[activeValue].id)} className="my-5">
                                        {t("request_a_quote")}
                                    </NPM_ButtonOne>
                                </div> */}
                            </Grid>

                            {/* Only visible on desktop */}
                            {pricingTabs[active].values.map((value, index) => (
                                <Grid key={index} item xs={2} md={2} lg={1} sx={!isEven(index) ? {
                                    // background: "linear-gradient(70deg, rgba(255, 255, 255, 0.10) 24.42%, rgba(255, 255, 255, 0.00) 102.47%)",
                                    // boxShadow: "1.5px 3px 22px 0px rgba(0, 0, 0, 0.10)",
                                    // backdropFilter: "blur(25px)"
                                } : null} className="hidden md:block pb-10">
                                    <HeadingText variant="h4" className="text-center my-8"> {t(value.label)} </HeadingText>

                                    {pricingTabs[active].lines.map((line, lineIndex) => (
                                        <Typography key={lineIndex} variant="body1" className="w-full flex border-b border-[#ffffff50] px-10">
                                            {line.values[index] === false ?
                                                "-"
                                            : line.values[index] === true ?
                                                <Image
                                                    loading="lazy"
                                                    src="Icons/checkmark_mqztby"
                                                    alt="Checkmark"
                                                    width={20}
                                                    height={20}
                                                    className="w-20px h-auto my-2px"
                                                />
                                            : (
                                                (line.values[index] as string).includes('[UNTRANSLATE]') ? (line.values[index] as string).replace('[UNTRANSLATE]', '') : t(line.values[index])
                                            )}
                                        </Typography>
                                    ))}

                                    {/* <div className="flex flex-col items-center">
                                        <NPM_ButtonOne type="button" variant="primary" onclick={() => dispatchQuoteModalOpen(t(value.id))} className="my-5">
                                            {t("request_a_quote")}
                                        </NPM_ButtonOne>
                                    </div> */}
                                </Grid>  
                            ))}
                        </Grid>
                    </Item>
                </Grid>
            </Container>
        </section>
    )
};

export default PricingSection;