'use client';

import { Params } from '@/lib/types/shared/page.types';
import React, { useEffect, useState } from 'react';
import Image from '../Image';
import { Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import CombinedContactFormNew from '../forms/CombinedContactFormNew';

type Props = {
    params: Params;
}

const PackagesModal = ({ params }: Props) => {
    const t = useTranslations();

    const [open, setOpen] = useState(false);
    const [fromId, setFromId] = useState('');

    const handleOpen = (from_id = '') => {
        setFromId(from_id);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handletriggerPackagesModal = (e: any) => {
        if (e.detail) {
            if (e.detail.from_id) {
                handleOpen(e.detail.from_id);
                return;
            }
        }

        handleOpen('');
        return;
    }

    useEffect(() => {
        window.addEventListener('triggerPackagesModal', handletriggerPackagesModal);

        return () => {
            window.removeEventListener('triggerPackagesModal', handletriggerPackagesModal);
        }
    }, []);

    return (
        <div id='packages-modal' className={`fixed top-0 left-0 w-full h-screen-mobile z-50 flex justify-center items-center ${open ? 'block' : 'hidden'}`} onClick={handleClose}>
            <div className={`w-screen-4/5 h-fit max-h-screen-4/5 overflow-y-auto package-modal-scrollbar bg-primary-gray-950 pt-12 px-12 py-4 rounded-lg relative`} onClick={(e) => e.stopPropagation()}>
                <Image
                    src='Icons/Close_hjrcnb'
                    alt='Close Packages Modal'
                    width={16}
                    height={16}
                    className='absolute top-6 right-6 cursor-pointer'
                    onClick={handleClose}
                />

                {fromId ? (
                    <Typography variant='h3' className='text-white capitalize font-bold mx-6'>
                        {t(`shared.packages.title_with_package`, { package: t(`shared.packages.${fromId}`) })}
                    </Typography>
                ) : (
                    <Typography variant='h3' className='text-white capitalize font-bold mx-6'>
                        {t("shared.packages.title")}
                    </Typography>
                )}

                {fromId && (
                    <CombinedContactFormNew title={'title'} text={'text'} onlyForm={true} buttonVariant='secondary' extraContent={{
                        from_id: fromId,
                        package: t(`shared.packages.${fromId}`)
                    }} onButtonClicked={handleClose} theme='light' buttonTheme='dark' />
                )}
            </div>
        </div>
    );
}

export default PackagesModal;