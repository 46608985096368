'use client';

import { Params } from '@/lib/types/shared/page.types';
import { replaceShorttag } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import React, { useEffect, useState } from 'react';

const ShorttagText = ({ text, className = '', params }: { text: string, className?: string, params: Params }) => {
    const [replacedText, setReplacedText] = useState(text);
    const t = useTranslations();

    useEffect(() => {
        const replaceShorttagText = async () => {
            const newText = await replaceShorttag(text, t);
            
            setReplacedText(newText);
        };

        replaceShorttagText();
    }, [text]);

    return (
        <p
            className={className}
            dangerouslySetInnerHTML={{
                __html: replacedText
            }}
        />
    );
}

export default ShorttagText;